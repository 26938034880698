import { Grid, Link, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { useConfirm } from 'material-ui-confirm';
import { LinkItem } from '../../../routes/ops/components/types/types';
import { SmallLinkBall } from './Balls';

export const LinkRow = ({
  link,
  onEditLink,
  onRemoveLink,
  onInfoLink,
}: {
  link: LinkItem;
  onEditLink: (link: LinkItem) => void;
  onRemoveLink: (linkId: number, bulletpointId: string) => void;
  onInfoLink: (link: LinkItem) => void;
}) => {
  const confirm = useConfirm();

  return (
    <li>
      <Grid container direction="row" justify-content="flex-start" spacing={1}>
        <Grid item xs={6}>
          <Typography variant="caption" style={{ textAlign: 'left' }}>
            <Link href={link.link} target="_blank">
              {link.title}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={6} max-height="15px" justify-self="end">
          <>
            {link.owner && (
              <>
                <SmallLinkBall
                  style={{ margin: '0px', marginLeft: '10px', float: 'right' }}
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    confirm({ description: 'This action is permanent!' })
                      .then(() => {
                        onRemoveLink(link.linkId, link.bulletpointId);
                      })
                      .catch(() => {});
                  }}
                >
                  <DeleteIcon style={{ fontSize: '14px' }} />
                </SmallLinkBall>
                <SmallLinkBall
                  style={{ margin: '0px', marginLeft: '10px', float: 'right' }}
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onEditLink(link);
                  }}
                >
                  <EditIcon style={{ fontSize: '14px' }} />
                </SmallLinkBall>
              </>
            )}
            <SmallLinkBall
              style={{ margin: '0px', marginLeft: '10px', float: 'right' }}
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onInfoLink(link);
              }}
            >
              <InfoIcon style={{ fontSize: '14px' }} />
            </SmallLinkBall>
          </>
        </Grid>
      </Grid>
    </li>
  );
};
