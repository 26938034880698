/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import * as React from 'react';
import { queryCache, useMutation } from 'react-query';
import styled from 'styled-components';
import * as adminApi from '../../api/adminapi';
import { User } from './User';

const menuItems = ['GEORGIA', 'No ops'];

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 24px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 16px;
      padding: 16px;
    }
  }
`;

export const UserDialog = ({
  open,
  onClose,
  user,
}: {
  open: boolean;
  onClose: () => void;
  user: User | undefined;
}) => {
  const { ops, admin } = { ...user };
  const [selectedOps, setSelectedOps] = React.useState<string>(ops ?? '');
  const [selectedAdmin, setSelectedAdmin] = React.useState<boolean>(
    admin ?? false
  );
  React.useEffect(() => {
    setSelectedOps(ops ?? '');
  }, [ops]);
  React.useEffect(() => {
    setSelectedAdmin(admin ?? false);
  }, [admin]);
  const [saveSelectedOps] = useMutation(adminApi.updateOps, {
    onSuccess: () => {
      queryCache.invalidateQueries('users');
    },
  });

  const [saveSelectedAdmin] = useMutation(adminApi.updateAdmin, {
    onSuccess: () => {
      queryCache.invalidateQueries('users');
    },
  });

  return (
    <StyledDialog
      open={open}
      // @ts-ignore
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ minWidth: '800px', maxWidth: '1600px' }}
    >
      <DialogTitle color="primary">Edit user {user?.username}</DialogTitle>
      <DialogContent style={{ minWidth: '300px' }}>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={selectedAdmin}
                  onChange={() => setSelectedAdmin(!selectedAdmin)}
                  name="adminSelector"
                />
              }
              label="Admin"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ minWidth: '300px' }}>
              <InputLabel>Ops:</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                id="ops-select"
                value={selectedOps}
                input={<Input />}
                onChange={(evt: any) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  setSelectedOps(evt.target.value);
                }}
              >
                {menuItems.map((m) => (
                  <MenuItem key={m} value={m}>
                    {m}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close window
        </Button>
        <Button
          onClick={async () => {
            await saveSelectedAdmin({ userId: user?.id, admin: selectedAdmin });
            await saveSelectedOps({ userId: user?.id, ops: selectedOps });
            onClose();
          }}
          color="primary"
        >
          Save changes and close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
