import jwtDecode from 'jwt-decode';
import { LS_KEY_TOKEN } from '../constants';
import { UserSessionType } from '../../routes/login/types';
import { RoleType } from '../../common/types/roleType';

export const setToken = (token: string) =>
  localStorage.setItem(LS_KEY_TOKEN, token);

export const removeToken = () => localStorage.removeItem(LS_KEY_TOKEN);

export const getToken = () => localStorage.getItem(LS_KEY_TOKEN);

export const getParsedToken = (): UserSessionType | null => {
  const token = getToken();
  return token && token !== 'undefined' ? jwtDecode(token) : null;
};

export const isLoggedIn = () => !!getToken();

const getUserInfo = (): UserSessionType | undefined => {
  const token = getToken();
  const decodedToken = token && jwtDecode(token);
  if (decodedToken && decodedToken !== '') {
    return decodedToken as UserSessionType;
  }

  return undefined;
};

export const getRoles = () => {
  const userInfo = getUserInfo();
  return userInfo && userInfo.roles;
};

export const hasRole = (role: RoleType) => getRoles()?.includes(role);
