import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { StickyHeader } from '../my-plan/MyPlanPage';
import { UserTable } from './UserTable';

export const AdminPage = () => (
  <>
    <StickyHeader container>
      <Typography variant="h4">Admin panel</Typography>
      <Divider />
    </StickyHeader>
    <Grid container>
      <UserTable />
    </Grid>
  </>
);
