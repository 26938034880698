import React from 'react';
import { Button, Link } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet';
import { PageContainer } from '../../../common/components/PageContainer';
import { PageTitle } from '../../../common/components/page/PageTitle';
import { ProgressIndicator } from '../../../common/components/ProgressIndicator';
import { GenericTextField } from '../../../common/components/form/input/GenericTextField';
import { validateFormValues } from '../validator';

const StyledForm = styled.form`
  margin: 0 auto;
  margin-bottom: 2em;
`;

const StyledButton = styled(Button)`
  margin-top: 2em;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RegisterPage = ({ register }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    register({
      username: values.username,
      password: values.password,
      firstname: values.firstname,
      lastname: values.lastname,
    });
  };

  const intl = useIntl();

  return (
    <>
      <Helmet>
        <meta name="description" content="" />
        <title>
          {intl.formatMessage({ id: 'application.title' })} -{' '}
          {intl.formatMessage({ id: 'registerPage.table.title' })}
        </title>
      </Helmet>
      <PageContainer>
        <PageTitle title="registerPage.table.title" />

        <Form
          onSubmit={onSubmit}
          validate={validateFormValues}
          render={({ handleSubmit, submitting }) => (
            <StyledForm onSubmit={handleSubmit} noValidate>
              {submitting && <ProgressIndicator />}
              <GenericTextField
                fieldName="username"
                fieldValue=""
                fieldTitle={intl.formatMessage({
                  id: 'registerPage.table.username',
                })}
                autoFocus={true}
                fieldRequired={true}
                helperText={intl.formatMessage({
                  id: 'registerPage.form.username',
                })}
              />
              <br />
              <GenericTextField
                fieldName="password"
                fieldType="password"
                fieldValue=""
                fieldTitle={intl.formatMessage({
                  id: 'registerPage.table.password',
                })}
                fieldRequired={true}
              />
              <br />
              <GenericTextField
                fieldName="firstname"
                fieldValue=""
                fieldTitle={intl.formatMessage({
                  id: 'registerPage.table.firstname',
                })}
                fieldRequired={true}
              />
              <br />
              <GenericTextField
                fieldName="lastname"
                fieldValue=""
                fieldTitle={intl.formatMessage({
                  id: 'registerPage.table.lastname',
                })}
                fieldRequired={true}
              />
              <br />

              <StyledButton variant="contained" color="primary" type="submit">
                {intl.formatMessage({ id: 'form.submit' })}
              </StyledButton>
            </StyledForm>
          )}
        />

        <Link href="/login">
          {intl.formatMessage({ id: 'registerPage.link.signin' })}
        </Link>
      </PageContainer>
    </>
  );
};
