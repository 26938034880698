import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { RegisterPage } from './components/RegisterPage';
import * as a from './actions';

const mapStateToProps = createStructuredSelector({});

const actionCreators = {
  register: a.register,
};

export const RegisterPageContainer = compose(
  connect(mapStateToProps, actionCreators),
  withRouter
)(RegisterPage);
