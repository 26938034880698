import { doPost } from '../utils/http';
import { LOGIN_API_URL, HEADER_AUTHORIZATION } from '../routes/constants';
import { Log } from '../utils/debug';

export type LoginRequestType = {
  username: string;
  password: string;
};

export const login = async (data: LoginRequestType): Promise<string> => {
  const response = await doPost({
    url: LOGIN_API_URL,
    payload: data,
  });

  const authHeader = response.headers.get(HEADER_AUTHORIZATION || '') || '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response.headers.forEach((k: any, v: any) =>
    Log.trace(`${k}:${v}`, 'loginapi.ts')
  );
  return authHeader;
};
