import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
// @ts-ignore
import logo from './logo.png';

const CardLink = ({
  title,
  disabled,
  to,
}: {
  title: React.ReactNode;
  disabled?: boolean;
  to: string;
}) => (
  <Grid
    item
    xs={12}
    sm={6}
    md={6}
    lg={6}
    style={disabled ? { opacity: '0.4' } : {}}
  >
    <NavLink to={disabled ? 'curriculum' : to}>
      <Card style={{ display: 'flex', padding: '25px' }}>
        <CardActionArea>
          <CardContent>
            <img
              alt="Polar Partners logo"
              style={{ maxWidth: '100%', height: 'auto' }}
              src={logo}
            />
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </NavLink>
  </Grid>
);

export const CurriculumPage = () => (
  <Grid container spacing={2} justify="center" style={{ marginTop: '16px' }}>
    <CardLink title="Subjects" to="/curriculum/subjects/select/1" />
    <CardLink disabled={true} title="Pedagogy" to="/curriculum/pedagogy" />
    <CardLink disabled={true} title="Assessment" to="/curriculum/assessment" />
    <CardLink disabled={true} title="Support" to="/curriculum/support" />
  </Grid>
);
