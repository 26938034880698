import { FieldDataType } from '../common/components/table/enums';
import { TableHeaderType } from '../common/components/table/types';
import { IIndexable } from './interfaces';

export const sortArrayByProperty = <T extends IIndexable>(
  array: Array<T>,
  headerField: TableHeaderType,
  fieldType: FieldDataType,
  descending: boolean
): Array<T> => {
  const sortedArray = array.slice().sort((a: T, b: T) => {
    const { property, valueExtractor } = headerField;

    const aValue = (valueExtractor && valueExtractor(a)) || a[property];
    const bValue = (valueExtractor && valueExtractor(b)) || b[property];

    switch (fieldType) {
      case FieldDataType.NUMBER:
        return Number(aValue) - Number(bValue);
      case FieldDataType.DATE: {
        return aValue - bValue;
      }
      default: {
        const nonNullA = aValue || '';
        const nonNullB = bValue || '';

        return nonNullA.localeCompare(nonNullB, 'en', { sensitivity: 'base' });
      }
    }
  });

  return descending ? sortedArray.reverse() : sortedArray;
};
