import {
  ContentProgressBulletpointStats,
  ContentProgressContentStats,
  ContentProgressObjectiveStats,
} from '../../../routes/my-plan/types';
import { Objective } from '../../../routes/ops/components/types/types';
import {
  isBulletpointCompleted,
  isContentCompleted,
  isObjectiveCompleted,
} from '../../../utils/stathelpers';

export const StatStyleCompletedColor = 'LightGreen';
export const StatStylePlannedColor = 'Yellow';
const StatStyleCompleted = { backgroundColor: StatStyleCompletedColor };
const StatStylePlanned = { backgroundColor: StatStylePlannedColor };
const StatStyleNone = { backgroundColor: 'white' };

export const resolveBulletpointStyle = (
  bulletpointStats?: ContentProgressBulletpointStats
) => {
  const bulletpointStatus = isBulletpointCompleted(bulletpointStats);
  if (bulletpointStatus !== null) {
    return bulletpointStatus ? StatStyleCompleted : StatStylePlanned;
  }
  return StatStyleNone;
};

export const resolveContentStyle = (
  contentBulletpointAmount: number,
  contentStats?: ContentProgressContentStats
) => {
  const contentStatus = isContentCompleted(
    contentBulletpointAmount,
    contentStats
  );
  if (contentStatus !== null) {
    return contentStatus ? StatStyleCompleted : StatStylePlanned;
  }
  return StatStyleNone;
};

export const resolveObjectiveStyle = (
  objective: Objective,
  objectiveStats?: ContentProgressObjectiveStats
) => {
  const objectiveStatus = isObjectiveCompleted(objective, objectiveStats);
  if (objectiveStatus !== null) {
    return objectiveStatus ? StatStyleCompleted : StatStylePlanned;
  }
  return StatStyleNone;
};
