import {
  Badge,
  Button,
  Card,
  CardActions,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Group } from '../../features/app-base/Group';
import { InviteStudentsDialog } from './InviteStudentsDialog';
import { DeleteStudentDialog } from './DeleteStudentDialog';

type Props = {
  group: Group;
};

type StudentInvitationBadgeProps = {
  group: Group;
  student: string;
};

const StudentInvitationBadge = ({
  student,
  group,
}: StudentInvitationBadgeProps) => {
  const invitedBadge = !group?.existingStudentEmails?.includes(student);

  return (
    <div
      style={{
        display: 'inline-block',
        border: '1px solid',
        borderRadius: '10px',
        padding: '2px',
        float: 'right',
        backgroundColor: invitedBadge ? 'lightgray' : 'lightgreen',
      }}
    >
      <Typography variant="caption">
        {invitedBadge ? 'Invited' : 'Accepted'}
      </Typography>
    </div>
  );
};

export const GroupItem = ({ group }: Props) => {
  const [inviteStudentsOpen, setInviteStudentsOpen] = React.useState(false);
  const [deleteStudentDialogOpen, setDeleteStudentDialogOpen] =
    React.useState(false);
  const [deleteStudent, setDeleteStudent] = React.useState('');

  return (
    <Grid item key={group.groupId} xs={12} sm={6} md={4} lg={3}>
      <InviteStudentsDialog
        open={inviteStudentsOpen}
        group={group}
        onClose={() => setInviteStudentsOpen(false)}
      />
      <DeleteStudentDialog
        open={deleteStudentDialogOpen}
        group={group}
        onClose={() => setDeleteStudentDialogOpen(false)}
        student={deleteStudent}
      />
      <Card variant="outlined" style={{ padding: '8px' }}>
        <Typography variant="h4">{group.name}</Typography>
        <Divider style={{ marginBottom: '8px' }} />
        {group.students?.length > 0 ? (
          <>
            <Typography variant="h6">Students</Typography>
            <ul style={{ marginTop: '6px', marginBottom: '0px' }}>
              {group.students?.map((student) => (
                <li key={student}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography variant="body1">{student}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <StudentInvitationBadge student={student} group={group} />
                    </Grid>
                    <Grid item xs={1}>
                      <DeleteIcon
                        style={{
                          fontSize: '28px',
                          color: 'red',
                          verticalAlign: 'middle',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setDeleteStudent(student);
                          setDeleteStudentDialogOpen(true);
                        }}
                      />
                    </Grid>
                  </Grid>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <Typography variant="h6">No students in group</Typography>
        )}
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setInviteStudentsOpen(true)}
          >
            Invite students
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
