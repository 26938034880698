import { takeEvery } from 'redux-saga/effects';
import * as t from './actionTypes';
import { Action } from '../../common/types/types';
import { register } from '../../api/registerapi';
import { Log } from '../../utils/debug';

export function* orchestrateStartRegister(action: Action) {
  yield register(action.payload)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      // TODO: Process successful registration
      Log.trace(response, 'orchestrations.ts');
    })
    .catch((error) => {
      // TODO: Process failed registration
      Log.trace(error, 'orchestrations.ts');
    });
}

export const spawnOrchestrations = [
  takeEvery(t.REGISTER_START, orchestrateStartRegister),
];
