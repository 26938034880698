import * as React from 'react';
import styled from 'styled-components';
import { Grid, Avatar, Chip, useTheme, useMediaQuery } from '@material-ui/core';

const SelectableChipBase = styled(Chip)<{ selected: boolean }>`
  & .MuiAvatar-root {
    color: white;
    background-color: #e7b000;
  }
  ${(props) =>
    props.selected
      ? `border: 1px solid ${props.theme.palette.primary.main};`
      : ''}
`;

export const GradeSelectChip = ({
  selected,
  subjectCount,
  grade,
  ...rest
}: React.ComponentProps<typeof Chip> & {
  selected: boolean;
  subjectCount?: number;
  grade: number;
}) => {
  const xs = useMediaQuery(useTheme().breakpoints.down('xs'));
  return (
    <Grid item>
      <SelectableChipBase
        {...rest}
        variant={selected ? 'default' : 'outlined'}
        color={selected ? 'primary' : undefined}
        // @ts-ignore
        avatar={subjectCount ? <Avatar>{subjectCount}</Avatar> : undefined}
        selected={selected}
        label={xs ? grade : `Grade ${grade}`}
      />
    </Grid>
  );
};
