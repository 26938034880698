import { Grid } from '@material-ui/core';
import React from 'react';
import { useQuery } from 'react-query';
import { Route } from 'react-router-dom';
import { getCachedOps } from '../../api/opsCacher';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { useFavoriteSubjects } from '../../features/favorites/hooks/useFavoriteSubjects';
import { useSelectedGroup } from '../../hooks/useSelectedGroup';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { StickyHeader } from '../my-plan/MyPlanPage';
import { getSubjectsByGrade } from '../ops/utils/getSubjectsByGrade';
import { parseSubjectList } from '../ops/utils/utils';
import { SubjectProgressContainer } from './SubjectProgressContainer';
import { TransversalProgressContainer } from './TransversalProgressContainer';

export const ProgressPage = () => {
  const group = useSelectedGroup();
  const { favoriteSubjects } = useFavoriteSubjects(group?.groupId);
  const { data: ops } = useQuery('ops', () => getCachedOps(), QUERY_CONFIG);

  if (!ops) {
    return <div>Loading ops</div>;
  }

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: true }, favoriteSubjects)
  );

  return (
    <>
      <StickyHeader container>
        <Breadcrumbs currentPage="Progress" previousPages={[]} />
      </StickyHeader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubjectProgressContainer
            groupId={group?.groupId}
            subjects={subjects}
          />
        </Grid>
        <Grid item xs={12}>
          <TransversalProgressContainer
            groupId={group?.groupId}
            subjects={subjects}
          />
        </Grid>
      </Grid>
    </>
  );
};
