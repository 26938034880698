/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { SubjectItem, GradeItem } from '../types/types';

type Props = {
  subjects: Array<SubjectItem>;
  favoriteSubjects: Array<string>;
  onClickSubject: Function;
  onClickFavoriteSubject: Function;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  primaryListItemText: {
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  secondaryListItemText: {
    fontSize: '0.7em',
    fontWeight: 'bold',
  },
  primaryListItemIcon: {},
  secondaryListItemIcon: {},
}));

export const SubjectList = ({
  subjects,
  favoriteSubjects,
  onClickSubject,
  onClickFavoriteSubject,
}: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const [openItems, setOpenItems] = React.useState<Array<string>>([]);

  const handleSubjectSelectClick = (subject: SubjectItem) => {
    const itemPosition = openItems.indexOf(subject.code);
    itemPosition === -1
      ? openItems.push(subject.code)
      : openItems.splice(itemPosition, 1);

    setOpenItems(openItems);
    onClickSubject({
      subject,
    });
  };

  const handleFavoriteSubjectClick = (
    subject: SubjectItem,
    grade?: GradeItem
  ) => {
    onClickFavoriteSubject(subject, grade?.grade || 0);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {intl.formatMessage({ id: 'opsPage.ops.subjects' })}
        </ListSubheader>
      }
    >
      {subjects.map((subject: SubjectItem) => {
        const open = openItems.includes(subject.code);
        return (
          <React.Fragment key={subject.code}>
            <ListItem button onClick={() => handleSubjectSelectClick(subject)}>
              <ListItemIcon>
                {favoriteSubjects?.includes(subject.code) ? (
                  <Star
                    className={classes.primaryListItemIcon}
                    onClick={() => handleFavoriteSubjectClick(subject)}
                  />
                ) : (
                  <StarBorder
                    className={classes.primaryListItemIcon}
                    onClick={() => handleFavoriteSubjectClick(subject)}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={subject.title}
                classes={{ primary: classes.primaryListItemText }}
              />
            </ListItem>

            {open ? <ExpandLess /> : <ExpandMore />}
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subject?.grades?.map((grade: GradeItem) => (
                  <ListItem
                    button
                    key={grade.grade}
                    className={classes.nested}
                    onClick={() => onClickSubject(subject, grade.grade)}
                  >
                    <ListItemIcon>
                      {favoriteSubjects?.includes(
                        `${subject.code}-${grade.grade}`
                      ) ? (
                        <Star
                          className={classes.primaryListItemIcon}
                          // eslint-disable-next-line max-len
                          onClick={() =>
                            handleFavoriteSubjectClick(subject, grade)
                          }
                        />
                      ) : (
                        <StarBorder
                          className={classes.primaryListItemIcon}
                          // eslint-disable-next-line max-len
                          onClick={() =>
                            handleFavoriteSubjectClick(subject, grade)
                          }
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      secondary={`Grade ${grade.grade}`}
                      classes={{ secondary: classes.secondaryListItemText }}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        );
      })}
    </List>
  );
};
