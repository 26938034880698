import { Grid } from '@material-ui/core';
import * as React from 'react';
import { useQuery } from 'react-query';
import { ObjectiveCard } from '../../features/curriculum/components/ObjectiveCard';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import {
  ContentProgressBulletpointStats,
  ContentProgressObjectiveStats,
} from '../my-plan/types';
import {
  SubjectItem,
  TransversalItem,
  TransversalItemWithInfo,
} from '../ops/components/types/types';
import * as contentProgressApi from '../../api/contentprogressapi';

interface Props {
  groupId: string | undefined;
  transversals: TransversalItemWithInfo[];
  bulletpointStats: ContentProgressBulletpointStats[];
  subjects: SubjectItem[];
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const fetchContentProgressStats = (key: string, obj: any) =>
  contentProgressApi.getStats(obj.groupId, obj.gradeId, obj.subjectId);

const findObjective = (
  code: string,
  stats: ContentProgressObjectiveStats[]
): ContentProgressObjectiveStats | undefined => {
  const found = stats.find((s) => s.objectiveId === code);
  return found
    ? {
        ...found,
        contents: found.contents.filter((c) => c.contentId.startsWith('T')),
      }
    : undefined;
};

export const TransversalProgressCardBody = ({
  groupId,
  transversals,
  bulletpointStats,
  subjects,
}: Props) => {
  const subjectCode = transversals[0].subject.code;
  const { grade } = transversals[0].grade;

  const contentProgressStats = useQuery({
    queryKey: [
      'contentprogressapistats',
      { gradeId: grade, subjectId: subjectCode, groupId },
    ],
    queryFn: fetchContentProgressStats,
    config: { ...QUERY_CONFIG, enabled: !!groupId },
  });

  if (!contentProgressStats.data) {
    return <div>Loading</div>;
  }

  // eslint-disable-next-line max-len
  const contentProgressObjectiveStats: ContentProgressObjectiveStats[] =
    contentProgressStats?.data?.length ? contentProgressStats.data : [];

  return (
    <Grid container spacing={2} style={{ height: '100%', padding: '2px' }}>
      {transversals.map((t) => (
        <ObjectiveCard
          gradeId={grade.toString()}
          key={t.objective.code}
          objective={{
            ...t.objective,
            contents: undefined,
            transversals: [t as TransversalItem],
          }}
          subjectCode={subjectCode}
          oneSize={true}
          onSkillClick={() => {}}
          onSkillClickIconVisible={false}
          expandedCard={true}
          contentProgressObjectiveStats={findObjective(
            t.objective.code,
            contentProgressObjectiveStats
          )}
        />
      ))}
    </Grid>
  );
};
