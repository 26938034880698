export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const OAUTH_LOGIN = `${LOGIN_URL}`;
export const OAUTH_LOGOUT = `${API_BASE_URL}/logout`;

export const USERS_API_URL = `${API_BASE_URL}/v3/users`;
export const FAVORITES_API_URL = `${API_BASE_URL}/v3/favorites`;
export const OPS_API_URL = (id: string) =>
  `https://curriculum-ops-${ENVIRONMENT}.s3-eu-west-1.amazonaws.com/${id}.json`;
export const LINKS_API_URL = `${API_BASE_URL}/v3/links`;

export const ADMIN_API_URL = `${API_BASE_URL}/v3/admin`;

export const LOGIN_API_URL = `${API_BASE_URL}/v2/login`;
export const REGISTER_API_URL = `${API_BASE_URL}/v2/register/initialize`;
export const ACTIVATION_API_URL = `${API_BASE_URL}/v2/register/finalize`;
export const CONTENT_PROGRESS_API_URL = `${API_BASE_URL}/v3/progress`;
export const GROUP_API_URL = `${API_BASE_URL}/v3/groups`;
export const STUDENT_API_URL = `${API_BASE_URL}/v3/students`;

export const HEADER_AUTHORIZATION = process.env.REACT_APP_AUTH_HEADER_NAME;
