/* eslint-disable react/jsx-key */
import { Card, Divider, Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import { SubjectItem } from '../ops/components/types/types';
import { SubjectProgress } from './SubjectProgress';

type Props = {
  groupId: string | undefined;
  subjects: SubjectItem[];
};

export const SubjectProgressContainer = ({ groupId, subjects }: Props) => (
  <Card variant="outlined" style={{ padding: '25px' }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Subjects</Typography>
        <Divider />
      </Grid>
      {subjects.map((subject) =>
        subject.grades?.map((grade) => (
          <SubjectProgress
            key={subject.code}
            groupId={groupId}
            grade={grade.grade.toString()}
            subject={subject}
          />
        ))
      )}
    </Grid>
  </Card>
);
