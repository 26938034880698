import React from 'react';
import { Grid } from '@material-ui/core';
import { SubjectItem } from '../../../ops/components/types/types';
import { SubjectGridItem } from '../../../../features/curriculum/components/SubjectGridItem';

type Props = {
  grade: number;
  subjects: SubjectItem[];
  favoriteSubjects: string[] | undefined;
};

export const SelectObjective = ({
  grade,
  subjects,
  favoriteSubjects,
}: Props) => (
  <Grid container spacing={2}>
    {subjects.map((subject: SubjectItem) => (
      <SubjectGridItem
        key={subject.code}
        href={`/curriculum/subjects/read/${grade}/objectives/${subject.code}`}
        favorite={favoriteSubjects?.includes(`${subject.code}-${grade}`)}
        title={subject.abbreviation}
        subject={subject.title}
        grade={grade}
      />
    ))}
  </Grid>
);
