import * as React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { isToday, parseISO } from 'date-fns';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { queryCache, useMutation } from 'react-query';
import DeleteIcon from '@material-ui/icons/Delete';
import { ContentProgress } from '../../routes/my-plan/types';
import { SubjectItem } from '../../routes/ops/components/types/types';
import { parseNumber } from '../../utils/string';
import { CalendarLinkDialog } from '../curriculum/components/CalendarLinkDialog';
import { SmallLinkBall } from '../curriculum/components/Balls';
import { AddLinkDialog } from '../curriculum/components/AddLinkDialog';
import * as linksApi from '../../api/linksapi';
import { DeleteContentProgressDialog } from './DeleteContentProgressDialog';

const findDesc = (cp: ContentProgress, subjects: SubjectItem[]): string => {
  if (!subjects) return '';
  // @ts-ignore
  const { contents, transversals } = subjects
    .find((s) => s.code === cp.subjectId)!!
    .grades.find((g) => g.grade === parseNumber(cp.gradeId))!!
    .objectives.find((o) => o.code === cp.objectiveId);
  const combined = [...contents, ...transversals];
  const content = combined.find((c) => cp.contentId === c.code);
  return content.bulletpoints[cp.bulletpointId];
};

const LINKS_API_KEY = 'links-api';

const newItemStyle = {
  color: 'green',
};

export const CalendarBulletpointRow = ({
  contentProgress,
  subjects,
  showLinks,
  showDelete,
}: {
  contentProgress: ContentProgress;
  subjects: SubjectItem[];
  showLinks: boolean;
  showDelete: boolean;
}) => {
  const [linksDialogOpen, setLinksDialogOpen] = React.useState(false);
  const [addLinkOpen, setAddLinkOpen] = React.useState(false);
  const [deleteContentProgressDialogOpen, setDeleteContentProgressDialogOpen] =
    React.useState(false);
  const [createLink] = useMutation(linksApi.createAndAttachLink, {
    onSuccess: () => {
      queryCache.invalidateQueries(`${LINKS_API_KEY}`);
      queryCache.invalidateQueries('contentprogressapi');
    },
  });

  const bulletpointDescription = {
    code: contentProgress.bulletpointId,
    description: findDesc(contentProgress, subjects),
  };

  return (
    <>
      <AddLinkDialog
        open={addLinkOpen}
        onClose={() => setAddLinkOpen(false)}
        description={bulletpointDescription}
        onAddLink={(
          title: string,
          link: string,
          description: string,
          bulletpointId: string | null,
          publicLink: boolean
        ) => {
          createLink({
            contentId: `${contentProgress.subjectId}-${contentProgress.objectiveId}-${contentProgress.contentId}`,
            linkId: 0,
            description,
            link,
            title,
            bulletpointId,
            type: 'WEB',
            private: !publicLink,
            enabled: true,
          });
        }}
      />
      <DeleteContentProgressDialog
        open={deleteContentProgressDialogOpen}
        onClose={() => setDeleteContentProgressDialogOpen(false)}
        contentProgress={contentProgress}
        desc={bulletpointDescription.description}
      />
      <CalendarLinkDialog
        open={linksDialogOpen}
        onAddLink={() => setAddLinkOpen(true)}
        onClose={() => {
          setLinksDialogOpen(false);
        }}
        availableLinks={contentProgress.availableLinks}
        selectedLinks={contentProgress.selectedLinks}
        contentProgressId={contentProgress.contentProgressId}
        bulletpointDesc={findDesc(contentProgress, subjects)}
      />
      <Grid container direction="row" spacing={1}>
        <Grid item xs={2}>
          <SmallLinkBall
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setLinksDialogOpen(true);
            }}
          >
            {contentProgress.availableLinks.length > 0 ? (
              <LinkIcon fontSize="small" />
            ) : (
              <LinkOffIcon fontSize="small" />
            )}
          </SmallLinkBall>
        </Grid>
        <Grid item xs={10}>
          <li style={{ padding: '0 0 0 0' }}>
            <Typography
              variant="caption"
              style={
                isToday(parseISO(contentProgress.updatedAt)) ? newItemStyle : {}
              }
            >
              {subjects && findDesc(contentProgress, subjects)}
            </Typography>
            {showDelete && (
              <DeleteIcon
                style={{
                  fontSize: '14px',
                  color: 'red',
                  verticalAlign: 'middle',
                  marginLeft: '8px',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDeleteContentProgressDialogOpen(true);
                }}
              />
            )}
            {showLinks && (
              <ul style={{ padding: '0 0 0 16px', margin: '0px' }}>
                {contentProgress.selectedLinks.map((selectedLink) => (
                  <li key={selectedLink.linkId + selectedLink.bulletpointId}>
                    <Typography variant="caption">
                      <Link href={selectedLink.link} target="_blank">
                        {selectedLink.title}
                      </Link>
                    </Typography>
                  </li>
                ))}
              </ul>
            )}
          </li>
        </Grid>
      </Grid>
    </>
  );
};
