/* eslint-disable @typescript-eslint/indent */
import { Grid } from '@material-ui/core';
import React from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import { getAllStats } from '../../api/contentprogressapi';
import { getCachedOps } from '../../api/opsCacher';
import { useFavoriteSubjects } from '../../features/favorites/hooks/useFavoriteSubjects';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { subjectShortForm } from '../../utils/subjectShortForm';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import { TransversalItemWithInfo } from '../ops/components/types/types';
import { getSubjectsByGrade } from '../ops/utils/getSubjectsByGrade';
import { parseSubjectList } from '../ops/utils/utils';
import { TransversalProgressCard } from './TransversalProgressCard';
import { parseTransversalsMap } from './TransversalHelpers';
import { TransversalProgressHeader } from './TransversalProgressHeader';
import { useSelectedGroup } from '../../hooks/useSelectedGroup';

// @ts-ignore
const queryFn = (obj: unknown) => getAllStats(obj.groupId);

export const TransversalProgressPage = ({
  location,
  match,
  history,
}: RouteComponentProps<{ transversalCode: string; transversal: string }>) => {
  const { transversal, transversalCode } = match.params;
  const group = useSelectedGroup();
  const { favoriteSubjects } = useFavoriteSubjects(group?.groupId);
  const { data: ops } = useQuery('ops', () => getCachedOps(), QUERY_CONFIG);
  const { data: bpdata } = useQuery({
    queryKey: ['contentprogressapiallstats', { groupId: group?.groupId }],
    queryFn,
    config: { ...QUERY_CONFIG, enabled: !!group?.groupId },
  });

  if (!ops || !bpdata) {
    return <div>Loading data</div>;
  }

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: true }, favoriteSubjects)
  );
  const bpStats: ContentProgressBulletpointStats[] = bpdata.length
    ? bpdata
    : [];
  const transversals = parseTransversalsMap(subjects);

  const currentTransversals = transversals.get(
    Array.from(transversals.keys()).find((key) => key === transversal)!!
  );

  const groupedBySubj = new Map<string, TransversalItemWithInfo[]>();

  currentTransversals?.forEach((ct) => {
    const subjectCode = subjectShortForm(
      ct.subject.abbreviation,
      ct.grade.grade
    );
    const existing = groupedBySubj.get(subjectCode);
    if (existing) {
      groupedBySubj.set(subjectCode, [...existing, ct]);
    } else {
      groupedBySubj.set(subjectCode, [ct]);
    }
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TransversalProgressHeader
            transversal={transversal}
            transversalCode={transversalCode}
            transversalItems={currentTransversals!!}
            bulletpointStats={bpStats}
          />
        </Grid>
        {Array.from(groupedBySubj.keys()).map((subj) => (
          <TransversalProgressCard
            key={subj}
            groupId={group?.groupId}
            bulletpointStats={bpStats}
            transversals={groupedBySubj.get(subj)!!}
            subjects={subjects}
          />
        ))}
      </Grid>
    </>
  );
};
