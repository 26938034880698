import * as t from './actionTypes';
import { OPSType, OPSFilterType, TransversalOrContentStateType } from './types';
import { Action } from '../../common/types/types';
import { SubjectItem, LinkItem } from './components/types/types';
import { StateEnum } from './enums';

type State = {
  item: OPSType | null;
  selectedSubject: SubjectItem | null;
  selectedGrade?: number | null;
  favoriteSubjects: Array<string> | null;
  opsFilter: OPSFilterType;
  links: Array<LinkItem> | null;
  selectedTransversalsAndContents: Array<TransversalOrContentStateType> | null;
};

export const initialState = {
  item: null,
  selectedSubject: null,
  selectedGrade: null,
  favoriteSubjects: null,
  opsFilter: {},
  links: null,
  selectedTransversalsAndContents: null,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case t.RETRIEVE_SUCCESS: {
      return {
        ...state,
        item: action.payload,
      };
    }
    case t.SELECT_SUBJECT: {
      return {
        ...state,
        selectedSubject: action.payload.subject,
        selectedGrade: action.payload.grade,
      };
    }
    case t.TOGGLE_FAVORITE_SUBJECT: {
      const { subject, grade } = action.payload;
      const { code } = subject;
      const favoriteSubjects = state.favoriteSubjects ?? [];

      const gradeCode = grade ? `${code}-${grade}` : code;
      const itemPosition = favoriteSubjects?.indexOf(gradeCode);

      if (itemPosition === undefined || itemPosition === -1) {
        if (!favoriteSubjects?.includes(code)) {
          favoriteSubjects?.push(code);
        }
        favoriteSubjects?.push(gradeCode);
      } else if (itemPosition !== undefined) {
        favoriteSubjects?.splice(itemPosition, 1);
      }
      return {
        ...state,
        favoriteSubjects,
      };
    }
    case t.SUBMIT_FAVORITE_SUBJECTS_SUCCESS: {
      return {
        ...state,
        favoriteSubjects: action.payload.favoriteSubjects,
      };
    }
    case t.RETRIEVE_FAVORITE_SUBJECTS_SUCCESS: {
      return {
        ...state,
        favoriteSubjects: action.payload,
      };
    }
    case t.UPDATE_OPS_FILTER: {
      return {
        ...state,
        opsFilter: action.payload,
      };
    }
    case t.RETRIEVE_LINKS_SUCCESS: {
      return {
        ...state,
        links: action.payload,
      };
    }
    case t.TOGGLE_TRANSVERSAL_OR_CONTENT_SELECTED: {
      const key = action.payload.linkCode;
      const items = state.selectedTransversalsAndContents?.slice() || [];
      const selectedItem = items.find(
        (item: TransversalOrContentStateType) => item.contentId === key
      );

      if (!selectedItem) {
        items.push({
          contentId: key,
          state: StateEnum.TODO,
          timestamp: action.payload.timestamp,
        });
      } else if (selectedItem.state === StateEnum.TODO) {
        selectedItem.state = StateEnum.INPROGRESS;
        selectedItem.timestamp = action.payload.timestamp;
      } else if (selectedItem.state === StateEnum.INPROGRESS) {
        selectedItem.state = StateEnum.DONE;
        selectedItem.timestamp = action.payload.timestamp;
      } else if (selectedItem.state === StateEnum.DONE) {
        selectedItem.state = StateEnum.TODO;
        selectedItem.timestamp = action.payload.timestamp;
      }

      return {
        ...state,
        selectedTransversalsAndContents: items,
      };
    }
    case t.RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_SUCCESS: {
      return {
        ...state,
        selectedTransversalsAndContents: action.payload,
      };
    }
    case t.RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_START:
    case t.RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_FAILURE:
    case t.RETRIEVE_START:
    case t.RETRIEVE_FAILURE:
    case t.RETRIEVE_LINKS_START:
    case t.RETRIEVE_LINKS_FAILURE:
    case t.SUBMIT_FAVORITE_SUBJECTS_START:
    case t.SUBMIT_FAVORITE_SUBJECTS_FAILURE:
    default:
      return state;
  }
};
