import { Button, DialogActions, DialogTitle } from '@material-ui/core';
import * as React from 'react';
import { queryCache, useMutation } from 'react-query';
import * as groupApi from '../../api/groupapi';
import { Group } from '../../features/app-base/Group';
import { StyledDialog } from '../../features/my-plan/StyledDialog';

export const DeleteStudentDialog = ({
  open,
  onClose,
  group,
  student,
}: {
  open: boolean;
  onClose: () => void;
  group: Group;
  student: string;
}) => {
  const [updateGroup] = useMutation(groupApi.addGroup, {
    onSuccess: (response) => {
      queryCache.invalidateQueries();
      onClose();
    },
  });
  return (
    <StyledDialog
      open={open}
      // @ts-ignore
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
      maxWidth="md"
    >
      <DialogTitle color="primary">
        Are you sure you want to remove this student from the group?
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() =>
            updateGroup({
              ...group,
              students: group.students.filter((s) => s !== student),
            })
          }
        >
          Confirm removal
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
