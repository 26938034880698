export const messagesFI = {
  'application.name': 'OPS',
  'application.title': 'OPS',

  'navigation.register.title': 'Rekisteröidy',
  'navigation.login.title': 'Kirjaudu',
  'navigation.users.title': 'Käyttäjät',

  'dialog.submit.title.save': 'Tallenna',
  'dialog.confirm.title': 'Vahvista',
  'dialog.confirm.description':
    'Oletko varma, että haluat suorittaa toiminnon?',
  'dialog.button.ok': 'Ok',
  'dialog.button.cancel': 'Peruuta',

  'search.startTime': 'Alkaen',
  'search.endTime': 'Päättyen',

  'footer.itemCount': 'Tuloksia',

  'dashboardPage.table.title': 'Mitä uutta',

  'alert.title.error': 'Virhe',
  'alert.title.success': 'Onnistui',
  'alert.message.fetch': 'Listaus',
  'alert.message.search': 'Hakutoiminto',
  'alert.message.create': 'Luonti',
  'alert.message.update': 'Päivitys',
  'alert.message.delete': 'Poisto',

  'form.add': 'Lisää',
  'form.delete': 'Poista',
  'form.submit': 'Lähetä',
  'form.save': 'Tallenna',
  'form.cancel': 'Palaa',
  'form.login': 'Kirjaudu',
  'form.logout': 'Kirjaudu ulos',

  'value.unknown': 'Ei tiedossa',
  'value.active.true': 'Aktiivinen',
  'value.active.false': 'Inaktiivinen',
  'value.private.true': 'Yksityinen',
  'value.private.false': 'Julkinen',

  'registerPage.table.title': 'Rekisteröidy',
  'registerPage.table.username': 'Käyttäjätunnus',
  'registerPage.table.password': 'Salasana',
  'registerPage.table.firstname': 'Etunimi',
  'registerPage.table.lastname': 'Sukunimi',
  'registerPage.link.signin': 'Kirjaudu sisään',

  'registerPage.form.username': 'Sähköpostiosoite',

  'loginPage.table.title': 'Kirjaudu',
  'loginPage.table.username': 'Käyttäjätunnus',
  'loginPage.table.password': 'Salasana',
  'loginPage.link.signup': 'Rekisteröidy',
  'loginPage.snackbar.message.failed': 'Kirjautuminen epäonnistui',

  'opsPage.dialog.title': 'OPS',
  'opsPage.table.title': 'OPS',
  'opsPage.ops.subjects': 'Oppiaineet',
  'opsPage.ops.grades': 'Luokat',
  'opsPage.ops.objectives': 'Tavoitteet',
  'opsPage.ops.contents': 'Sisällöt',
  'opsPage.ops.transversals': 'Taidot',
  'opsPage.filter.onlyFavorites': 'Vain suosikit',
  'opsPage.filter.onlyGrades': 'Vain luokat',

  'opsPage.dialog.form.field.title': 'Nimi',
  'opsPage.dialog.form.field.type': 'Tyyppi',
  'opsPage.dialog.form.field.link': 'Link',
  'opsPage.dialog.form.field.private': 'Yksityinen',
  'opsPage.dialog.form.field.description': 'Kuvaus',

  'opsPage.form.date': 'Päivämäärä',

  'usersPage.dialog.title': 'Käyttäjän tiedot',
  'usersPage.table.title': 'Käyttäjä',
  'usersPage.table.header.username': 'Käyttäjätunnus',
  'usersPage.table.header.description': 'Lisätietoja',
  'usersPage.table.header.password': 'Salasana',
  'usersPage.table.header.password_confirmation': 'Vahvista salasana',
  'usersPage.table.header.firstname': 'Etunimi',
  'usersPage.table.header.lastname': 'Sukunimi',
  'usersPage.table.header.status': 'Tila',
  'usersPage.table.header.lastloginAt': 'Kirjautunut',
  'usersPage.table.header.createdAt': 'Liittynyt',
};
