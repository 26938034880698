import { Log } from '../utils/debug';
import * as api from './opsapi';

const LOCAL_STORAGE_KEY_OPS = 'ops';

export const getCachedOps = async () => {
  const ops = localStorage.getItem(LOCAL_STORAGE_KEY_OPS);

  if (!ops) {
    try {
      const response = await api.get('generic');
      localStorage.setItem(LOCAL_STORAGE_KEY_OPS, JSON.stringify(response));
      return response;
    } catch (e) {
      Log.error('could not get ops');
      throw new Error('Could not fetch ops');
    }
  }

  return JSON.parse(ops);
};
