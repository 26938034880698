import { takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import * as a from './actions';
import * as aa from '../../App/actions';
import * as t from './actionTypes';
import { Action } from '../../common/types/types';
import * as api from '../../api/opsapi';
import * as linksapi from '../../api/linksapi';
import { AlertSeverity } from '../../App/enums';
import { LS_KEY_OPS } from './constants';
import * as s from './selectors';
import { updateDetail, getDetail } from '../../api/usersapi';
import { UserDetailType } from '../users/enums';

export function* orchestrateRetrieveItem(action: Action) {
  const id = action.payload;

  const ops = localStorage.getItem(LS_KEY_OPS);
  if (!ops) {
    const response = yield api.get(id).catch(() => null);

    if (response) {
      if (response.length > 50) {
        yield localStorage.setItem(
          LS_KEY_OPS,
          JSON.stringify(response, null, 2)
        );
        yield put(a.storeItem(response));
      }
    } else {
      yield put(aa.setFetchAlert(AlertSeverity.ERROR));
    }
  }
}

export function* orchestrateRetrieveLinks(action: Action) {
  const id = action.payload;

  const response = yield linksapi.getByContentId(id).catch(() => null);

  if (response) {
    yield put(a.storeLinkItems(response));
  } else {
    yield put(aa.setFetchAlert(AlertSeverity.ERROR));
  }
}

export function* orchestrateToggleFavoriteSubject(action: Action) {
  const subjects = yield select(s.getFavoriteSubjects);
  yield put(a.submitFavorites(subjects));
}

export function* orchestrateRetrieveFavorites(action: Action) {
  // Retrieve from API
  const favoriteSubjects = yield getDetail(
    UserDetailType.FAVORITE_SUBJECTS.toString()
  );

  // Store to state
  if (favoriteSubjects && favoriteSubjects.value) {
    yield put(a.storeFavorites(JSON.parse(favoriteSubjects.value)));
  }

  // TODO: On success store to state and notify
  // TODO: On false notify
}

export function* orchestrateSubmitFavorites(action: Action) {
  // Retrieve favorites from state
  const subjects = action.payload;

  // Submit to API
  yield updateDetail(UserDetailType.FAVORITE_SUBJECTS.toString(), {
    type: UserDetailType.FAVORITE_SUBJECTS.toString(),
    value: JSON.stringify(subjects),
  });

  // TODO: On success notify
  // TODO: On false schedule retry
}

export function* orchestrateSubmitTransversalOrContentSelected(action: Action) {
  // Retrieve favorites from state
  const progressDetails = yield select(s.getSelectedTransversalsAndContents);

  // Submit to API
  yield updateDetail(
    UserDetailType.CONTENT_OR_TRANSVERSAL_PROGRESS.toString(),
    {
      type: UserDetailType.CONTENT_OR_TRANSVERSAL_PROGRESS.toString(),
      value: JSON.stringify(progressDetails),
    }
  );
}

export function* orchestrateRetrieveSelectedTransversalsAndContents(
  action: Action
) {
  // Retrieve from API
  const getSelectedTransversalsAndContents = yield getDetail(
    UserDetailType.CONTENT_OR_TRANSVERSAL_PROGRESS.toString()
  ) ?? {
    type: UserDetailType.FAVORITE_SUBJECTS,
    value: [],
  };

  // Store to state
  if (
    getSelectedTransversalsAndContents &&
    getSelectedTransversalsAndContents.value
  ) {
    yield put(
      a.storeSelectedTransversalsAndContents(
        JSON.parse(getSelectedTransversalsAndContents.value)
      )
    );
  }

  // TODO: On success store to state and notify
  // TODO: On false notify
}

export function* orchestrateSubmitLink(action: Action) {
  const response = yield linksapi.createAndAttachLink(action.payload);

  // TODO: On success notify
  if (response) {
    yield put(a.searchLink(action.payload.contentId));
  }
  // TODO: On false notify
}

export const spawnOrchestrations = [
  takeLatest(t.RETRIEVE_START, orchestrateRetrieveItem),
  takeLatest(t.RETRIEVE_FAVORITE_SUBJECTS_START, orchestrateRetrieveFavorites),
  takeLatest(t.RETRIEVE_LINKS_START, orchestrateRetrieveLinks),
  takeLatest(
    t.RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_START,
    orchestrateRetrieveSelectedTransversalsAndContents
  ),
  takeLatest(t.SUBMIT_LINK, orchestrateSubmitLink),
  takeEvery(t.TOGGLE_FAVORITE_SUBJECT, orchestrateToggleFavoriteSubject),
  takeEvery(t.SUBMIT_FAVORITE_SUBJECTS_START, orchestrateSubmitFavorites),
  takeEvery(
    t.TOGGLE_TRANSVERSAL_OR_CONTENT_SELECTED,
    orchestrateSubmitTransversalOrContentSelected
  ),
];
