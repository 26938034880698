import { Box, Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ProgressBarWithText } from '../../features/progress/ProgressBarWithText';
import { getTransversalStats } from '../../utils/stathelpers';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import { TransversalItem } from '../ops/components/types/types';

type Props = {
  transversals: TransversalItem[];
  bulletpointStats: ContentProgressBulletpointStats[];
  simpleMode?: boolean;
};

export const TransversalProgress = ({
  transversals,
  bulletpointStats,
  simpleMode = false,
}: Props) => {
  const history = useHistory();
  const stats = getTransversalStats(transversals, bulletpointStats);
  const clickBox = () => {
    history.push(
      `/progress/transversal/${transversals[0].code}/${transversals[0].name}`
    );
  };
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Box onClick={!simpleMode ? clickBox : () => {}}>
        {!simpleMode && (
          <Typography variant="body1">
            {transversals[0].code} {transversals[0].name}
          </Typography>
        )}
        <ProgressBarWithText stats={stats} />
      </Box>
    </Grid>
  );
};
