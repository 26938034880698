import { doPost } from '../utils/http';
import { REGISTER_API_URL } from '../routes/constants';

export type RegisterRequestType = {
  username: string;
  password: string;
  firstname: string;
  lastname: string;
};

export const register = async <R>(data: RegisterRequestType): Promise<R> => {
  const response = await doPost({
    url: REGISTER_API_URL,
    payload: data,
  });

  return response.json();
};
