export enum HeaderType {
  ID = 'usersPage.table.header.id',
  USERNAME = 'usersPage.table.header.username',
  PASSWORD = 'usersPage.table.header.password',
  PASSWORD_CONFIRM = 'usersPage.table.header.password_confirmation',
  ACTIVE = 'usersPage.table.header.status',
  FIRSTNAME = 'usersPage.table.header.firstname',
  LASTNAME = 'usersPage.table.header.lastname',
  DESCRIPTION = 'usersPage.table.header.description',
  LAST_LOGIN_AT = 'usersPage.table.header.lastloginAt',
  CREATED_AT = 'usersPage.table.header.createdAt',
}

export enum FieldType {
  ID = 'id',
  USERNAME = 'username',
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'password_confirmation',
  ACTIVE = 'active',
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  DESCRIPTION = 'description',
  LAST_LOGIN_AT = 'lastloginAt',
  CREATED_AT = 'createdAt',
}

export enum UserDetailType {
  FAVORITE_SUBJECTS = 'FAVORITE_SUBJECTS',
  CONTENT_OR_TRANSVERSAL_PROGRESS = 'TRANSVERSAL_AND_CONTENT_PROGRESS',
}
