import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as a from './actions';
import { App } from './App';
import { getActiveAlert, isAlertVisible } from './selectors';

const mapStateToProps = createStructuredSelector({
  activeAlert: getActiveAlert,
  isAlertVisible,
});

const actionCreators = {
  hideAlert: a.hideAlert,
};

export const AppContainer = compose(
  connect(mapStateToProps, actionCreators),
  withRouter
)(App);
