import styled from 'styled-components';
import React from 'react';
import {
  StatStyleCompletedColor,
  StatStylePlannedColor,
} from '../curriculum/components/StatStyles';

const ProgressBarBackground = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  padding: 3px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
`;

const ProgressBarFill = styled.div`
  display: block;
  height: 22px;
  background-color: #659cef;
  border-radius: 3px;
  transition: width 500ms ease-in-out;
`;

interface Props {
  fill: number;
  secondaryFill: number;
}

export const ProgressBar = ({ fill, secondaryFill }: Props) => (
  <div>
    <ProgressBarBackground>
      <div style={{ display: 'grid' }}>
        <ProgressBarFill
          style={{
            width: `${secondaryFill}%`,
            backgroundColor: StatStylePlannedColor,
            gridArea: '1/1',
          }}
        />
        <ProgressBarFill
          style={{
            width: `${fill}%`,
            backgroundColor: StatStyleCompletedColor,
            gridArea: '1/1',
          }}
        />
      </div>
    </ProgressBarBackground>
  </div>
);
