import * as React from 'react';
import { Grid } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { selectedSkillCodes, SkillCode } from './state';
import { SubjectItem } from '../ops/components/types/types';
import { ObjectiveCard } from '../../features/curriculum/components/ObjectiveCard';
import * as contentProgressApi from '../../api/contentprogressapi';
import { ContentProgressObjectiveStats } from './types';
import { QUERY_CONFIG } from '../../utils/queryconfig';

type Props = {
  subjectCode: string;
  grade: number;
  subjects: SubjectItem[];
  groupId: string | undefined;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const fetchContentProgressStats = (key: string, obj: any) =>
  contentProgressApi.getStats(obj.groupId, obj.gradeId, obj.subjectId);

export const MyPlanSubjectPage = ({
  groupId,
  subjects,
  grade,
  subjectCode,
}: Props) => {
  const contentProgressStats = useQuery({
    queryKey: [
      'contentprogressapistats',
      { gradeId: grade, subjectId: subjectCode, groupId },
    ],
    queryFn: fetchContentProgressStats,
    config: { ...QUERY_CONFIG, enabled: !!groupId },
  });

  const [addedSkillCodes, setSkillCodes] = useRecoilState(selectedSkillCodes);

  if (!contentProgressStats.data) {
    return <div>Loading</div>;
  }

  // eslint-disable-next-line max-len
  const contentProgressObjectiveStats: ContentProgressObjectiveStats[] =
    contentProgressStats?.data?.length ? contentProgressStats.data : [];

  const onAddOrRemoveSkill = (
    skillCode: string,
    gradeId: string,
    subjectId: string,
    contentId: string,
    objectiveId: string,
    desc: string
  ) => {
    setSkillCodes((codes: SkillCode[]) =>
      codes.filter((c) => c.skillCode === skillCode && c.gradeId === gradeId)
        .length > 0
        ? codes.filter(
            (c) => c.skillCode !== skillCode && c.gradeId === gradeId
          )
        : [
            ...codes,
            { skillCode, gradeId, subjectId, contentId, objectiveId, desc },
          ]
    );
  };
  return (
    <Grid container spacing={2}>
      {subjects
        .find((subject) => subject.code === subjectCode)
        ?.grades?.find((_grade) => _grade.grade === grade)
        ?.objectives?.map((objective) => (
          <ObjectiveCard
            gradeId={grade.toString()}
            key={objective.code}
            objective={objective}
            subjectCode={subjectCode}
            onSkillClick={onAddOrRemoveSkill}
            addedSkillCodes={addedSkillCodes}
            hideLinkButtons
            contentProgressObjectiveStats={contentProgressObjectiveStats.find(
              (s) => s.objectiveId === objective.code
            )}
          />
        ))}
    </Grid>
  );
};
