/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const BreadcrumbLink = (
  props: React.ComponentProps<RouterLink>,
  currentPage: string
) => (
  // @ts-ignore
  <MuiLink {...props} component={RouterLink as any} />
);

export const Breadcrumbs = ({
  previousPages,
  currentPage,
}: {
  previousPages: { title: string; path: string }[];
  currentPage: string;
}) => (
  <Grid container style={{ padding: '8px' }}>
    <MuiBreadcrumbs>
      {previousPages.map((page) => (
        <BreadcrumbLink key={page.path} to={page.path}>
          {page.title}
        </BreadcrumbLink>
      ))}
      <span>{currentPage}</span>
    </MuiBreadcrumbs>
  </Grid>
);
