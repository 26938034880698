/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { queryCache } from 'react-query';
import {
  setToken as setLocalStorageToken,
  getParsedToken as getParsedLocalStorageToken,
} from './session';
import { UserSessionType } from '../../routes/login/types';

type AuthState = {
  token?: UserSessionType;
  setToken: (token?: UserSessionType) => void;
};

const INITIAL_STATE: AuthState = {
  token: undefined,
  setToken: () => {},
};

const TOKEN_PREFIX_IN_URL = 'id_token=';

export const AuthContext = React.createContext<AuthState>(INITIAL_STATE);

const parseTokenFromHash = (hash: string): string => {
  const frontRemoved = hash.split(TOKEN_PREFIX_IN_URL)[1];
  // eslint-disable-next-line prefer-destructuring
  return frontRemoved.split('&')[0];
};

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();

  const queryToken = location.hash.includes(TOKEN_PREFIX_IN_URL)
    ? parseTokenFromHash(location.hash)
    : undefined;

  const localStorageToken = getParsedLocalStorageToken();

  // Initialize token from url or localstorage at app start
  const [token, setToken] = React.useState<UserSessionType | undefined>(
    // @ts-ignore
    queryToken
      ? // @ts-ignore
        jwtDecode(queryToken)
      : localStorageToken
  );

  const [contextValue, setContextValue] = React.useState<AuthState>({
    token,
    setToken,
  });

  // Avoid unnecessary deep renders. https://reactjs.org/docs/context.html#caveats
  React.useEffect(() => {
    setContextValue({ token, setToken });
    // Set raw token to localstorage if it's found on url
    if (queryToken) {
      // @ts-ignore
      setLocalStorageToken(queryToken);
      queryCache.invalidateQueries();
      window.location.href = `${process.env.REACT_APP_BASE_URL}/home/today?groupOpen`;
    }
    queryCache.invalidateQueries();
  }, [token, queryToken]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
