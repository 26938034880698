import React from 'react';
import { hotjar } from 'react-hotjar';
import styled from 'styled-components';
import './App.css';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
import Header from '../features/app-base/Header';
import { RootRoute } from '../routes/RootRoute';
import { BottomBar } from '../features/app-base/BottomBar';
import { Drawer } from '../features/app-base/Drawer';

if (!process.env.REACT_APP_BASE_URL?.includes('localhost')) {
  hotjar.initialize(2300311, 6);
}

const AppContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
`;

const ContentContainer = styled(Box)`
  flex: 1;
`;
// @ts-ignore
export const AppBarPlaceholder = styled.div`
  ${({ theme }) => theme.mixins.toolbar}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const App = ({ activeAlert, isAlertVisible, hideAlert }: any) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  return (
    <AppContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="" />
        <title>Curriculum</title>
        <link rel="canonical" href={process.env.REACT_APP_BASE_URL} />
      </Helmet>

      <Header setDrawerOpen={setDrawerOpen} />
      <Drawer open={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <ContentContainer
        pl={{ xs: 2, md: 3 }}
        pr={{ xs: 2, md: 3 }}
        pb={{ xs: 2, md: 3 }}
      >
        <AppBarPlaceholder />
        <RootRoute />
        <div style={{ height: '56px' }} /* Bottom bar placeholder */ />
      </ContentContainer>
      <BottomBar />
    </AppContainer>
  );
};
