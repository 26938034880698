import { takeEvery, put } from 'redux-saga/effects';
import * as a from './actions';
import * as t from './actionTypes';
import { Action } from '../../common/types/types';
import { login } from '../../api/loginapi';
import { LS_KEY_TOKEN } from '../../utils/constants';
import { Log } from '../../utils/debug';
import { setToken } from '../../utils/auth/session';
import { LS_KEY_OPS } from '../ops/constants';

export function* orchestrateRetrieveToken(action: Action) {
  const code = yield login(action.payload)
    .then((token) => {
      Log.trace(`Token is ${token}`, 'orchestrations.ts');
      if (token) {
        setToken(token);
        return 200;
      }

      return 403;
    })
    .catch((error) => {
      Log.error(error, 'orchestrations.ts');
      return error;
    });

  yield put(a.setLoginResultCode(code));
}

export function* orchestrateClearToken(action: Action) {
  yield localStorage.removeItem(LS_KEY_TOKEN);
  yield localStorage.removeItem(LS_KEY_OPS);
}

export const spawnOrchestrations = [
  takeEvery(t.LOGIN, orchestrateRetrieveToken),
  takeEvery(t.LOGOUT, orchestrateClearToken),
];
