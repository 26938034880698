/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/indent */
import { CONTENT_PROGRESS_API_URL } from '../routes/constants';
import { apiDeleteWithUrl, apiGet, apiPost, apiPut } from './genericapi';

export const createContentProgress = (payload: any): Promise<any> =>
  apiPost(`${CONTENT_PROGRESS_API_URL}?groupId=${payload.groupId}`, payload);

export const deleteContentProgress = (
  contentProgressId: string
): Promise<any> =>
  apiDeleteWithUrl(`${CONTENT_PROGRESS_API_URL}/${contentProgressId}`);

export const saveSelectedLinks = (payload: any): Promise<any> =>
  apiPut(`${CONTENT_PROGRESS_API_URL}/links`, payload);

export const get = <T>(
  groupId: string | undefined,
  plannedAtStartDate: string,
  plannedAtEndDate: string
): Promise<T> =>
  apiGet(
    `${CONTENT_PROGRESS_API_URL}?&plannedAtStartDate=${plannedAtStartDate}&plannedAtEndDate=${plannedAtEndDate}&groupId=${groupId}`
  );

export const getStats = (
  groupId: string | undefined,
  gradeId: string,
  subjectId: string
): Promise<any> =>
  apiGet(
    `${CONTENT_PROGRESS_API_URL}/stats?&gradeId=${gradeId}&subjectId=${subjectId}&groupId=${groupId}`
  );

export const getAllStats = (groupId: string | undefined): Promise<any> =>
  apiGet(`${CONTENT_PROGRESS_API_URL}/bulletpointstats?groupId=${groupId}`);
