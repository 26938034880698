import { Grid } from '@material-ui/core';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { WeekSelection } from '../../features/my-plan/WeekSelection';
import { WEEKDAYS } from '../my-plan/helpers';
import { selectedStateWeek } from '../my-plan/state';
import { WeekdayView } from '../my-plan/WeekdayView';
import { SubjectItem } from '../ops/components/types/types';

type Props = {
  groupId: string | undefined;
  subjects: SubjectItem[];
};

export const FrontpageThisWeek = ({ groupId, subjects }: Props) => {
  const [week, setWeek] = useRecoilState(selectedStateWeek);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WeekSelection />
      </Grid>
      <Grid item xs={12}>
        <WeekdayView
          groupId={groupId}
          subjects={subjects}
          weekdays={WEEKDAYS}
          week={week}
          showLinks={true}
          showDelete={false}
        />
      </Grid>
    </Grid>
  );
};
