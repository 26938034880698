import * as actionTypes from './actionTypes';
import { OPSType as DtoType, OPSFilterType } from './types';
import { Action } from '../../common/types/types';
import { SubjectItem, LinkItem } from './components/types/types';

export const retrieveItem = (id?: string): Action => ({
  type: actionTypes.RETRIEVE_START,
  payload: id,
});

export const storeItem = (item: DtoType): Action => ({
  type: actionTypes.RETRIEVE_SUCCESS,
  payload: item,
});

export const storeLinkItems = (item: Array<LinkItem>): Action => ({
  type: actionTypes.RETRIEVE_LINKS_SUCCESS,
  payload: item,
});

export const selectItem = (item?: DtoType): Action => ({
  type: actionTypes.SELECT_ITEM,
  payload: item,
});

type SelectSubjectProps = {
  subject: SubjectItem;
  grade?: number;
};

export const selectSubject = (payload: SelectSubjectProps): Action => ({
  type: actionTypes.SELECT_SUBJECT,
  payload,
});

export const toggleFavoriteSubject = (payload: SelectSubjectProps): Action => ({
  type: actionTypes.TOGGLE_FAVORITE_SUBJECT,
  payload,
});

export const retrieveFavorites = (): Action => ({
  type: actionTypes.RETRIEVE_FAVORITE_SUBJECTS_START,
});

export const storeFavorites = (payload: Array<string>): Action => ({
  type: actionTypes.RETRIEVE_FAVORITE_SUBJECTS_SUCCESS,
  payload,
});

export const submitFavorites = (payload: Array<string>): Action => ({
  type: actionTypes.SUBMIT_FAVORITE_SUBJECTS_START,
  payload,
});

export const updateOPSFilter = (payload: OPSFilterType): Action => ({
  type: actionTypes.UPDATE_OPS_FILTER,
  payload,
});

export const searchLink = (payload: string): Action => ({
  type: actionTypes.RETRIEVE_LINKS_START,
  payload,
});

export const toggleTransversalOrContentSelected = (
  linkCode: string,
  timestamp: string
): Action => ({
  type: actionTypes.TOGGLE_TRANSVERSAL_OR_CONTENT_SELECTED,
  payload: {
    linkCode,
    timestamp,
  },
});

export const retrieveSelectedTransversalsAndContents = (): Action => ({
  type: actionTypes.RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_START,
});

export const storeSelectedTransversalsAndContents = (
  payload: Array<string>
): Action => ({
  type: actionTypes.RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_SUCCESS,
  payload,
});

export const submitEditLink = (payload: LinkItem): Action => ({
  type: actionTypes.SUBMIT_LINK,
  payload,
});
