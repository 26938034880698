/* eslint-disable @typescript-eslint/no-explicit-any */

import { SubjectItem /* GradeItem */ } from '../components/types/types';
import { OPSFilterType } from '../types';

type Subject = {
  code: string;
  title: string;
  favorite: boolean;
  grades: Array<any>;
  abbreviation: string;
};

export const sortOnlyByNumber = (a: string, b: string): number => {
  const aNumber = a.substr(1, a.length);
  const bNumber = b.substr(1, b.length);
  return parseInt(aNumber, 10) > parseInt(bNumber, 10) ? 1 : -1;
};

export const parseSubjectList = (
  ops: any,
  filter: OPSFilterType,
  favoriteSubjects: string[] | undefined = []
): Map<String, SubjectItem> => {
  const rawSubjectList = Object.keys(ops.subjects)
    // Get subject by subject code
    .map((subjectCode: string) => {
      const subject = ops.subjects[subjectCode];
      // Get grades for subject
      const grades =
        subject.grades &&
        Object.keys(subject.grades)
          .filter((gradeCode: string) => {
            // Filter only favorite grades
            if (filter.onlyFavorites) {
              return (
                favoriteSubjects &&
                favoriteSubjects.includes(`${subjectCode}-${gradeCode}`)
              );
            }

            // Filter only grades
            if (filter.grades && filter.grades.length > 0) {
              // eslint-disable-next-line radix
              return filter.grades.includes(Number.parseInt(gradeCode));
            }

            return true;
          })
          .map((gradeCode: string) => {
            const gradeObj = subject.grades[gradeCode];
            // Objectives
            const objectives =
              gradeObj &&
              Object.keys(gradeObj)
                .sort((a: string, b: string) => sortOnlyByNumber(a, b))
                .map((objectiveCode: string) => {
                  const objectiveObj = gradeObj[objectiveCode];

                  // Contents
                  const contents = Object.keys(objectiveObj.contents)
                    .sort((a: string, b: string) => sortOnlyByNumber(a, b))
                    .map((contentCode: string) => {
                      const contentObj = objectiveObj.contents[contentCode];
                      return {
                        code: contentCode,
                        key: contentObj.name.substr(0, 3).trim(),
                        name: contentObj.name,
                        bulletpoints: contentObj.bulletpoints,
                      };
                    });

                  // Transversals
                  const transversals = Object.keys(
                    objectiveObj.transversal_skills
                  )
                    .sort((a: string, b: string) => sortOnlyByNumber(a, b))
                    .map((transversalCode: string) => {
                      const transversalObj =
                        objectiveObj.transversal_skills[transversalCode];
                      return {
                        code: transversalCode,
                        key: transversalObj.name.substr(0, 3).trim(),
                        name: transversalObj.name,
                        bulletpoints: transversalObj.bulletpoints,
                      };
                    });
                  return {
                    code: objectiveCode,
                    key: objectiveObj.name.substr(0, 3).trim(),
                    name: objectiveObj.name,
                    contents,
                    transversals,
                  };
                });

            // Lisätään suosikkitarkistus favourite-taulukosta
            return {
              grade: Number(gradeCode),
              objectives,
              favorite: false, // TODO
            };
          });

      /* const gradesPart = grades
        ?.map((grade: GradeItem) => grade.grade)
        .join(', ');
      */

      return {
        code: subjectCode,
        title: subject.name,
        favorite: false, // TODO
        grades,
        abbreviation: subject.abbreviation,
      };
    });

  const sortedSubjectList = rawSubjectList.sort((a: Subject, b: Subject) =>
    a.title.localeCompare(b.title)
  );

  const subjectList: Map<String, SubjectItem> = new Map();
  // @ts-ignore
  sortedSubjectList.forEach((subject: SubjectItem) => {
    subjectList.set(subject.title, {
      ...subject,
      grades: subject?.grades,
      title: subject.title,
    });
  });

  return subjectList;
};
