import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  useLocation,
} from 'react-router-dom';
import { LoginPageContainer } from './login/LoginPageContainer';
import { RegisterPageContainer } from './register/RegisterPageContainer';
import { RoleType } from '../common/types/roleType';
import { OPSPageContainer } from './ops/OPSPageContainer';
import { Frontpage } from './frontpage/Frontpage';
import { OAUTH_LOGIN } from './constants';
import { CurriculumPage } from './curriculum/CurriculumPage';
import { CurriculumSubjectsPage } from './curriculum/subjects/CurriculumSubjectsPage';
import { useAuth } from '../utils/auth/useAuth';
import { MyPlanPage } from './my-plan/MyPlanPage';
import { AdminPage } from './admin/AdminPage';
import { ProgressPage } from './progress/ProgressPage';
import { TransversalProgressPage } from './progress/TransversalProgressPage';
import { GroupsPage } from './groups/GroupsPage';

const LoggedInRoute = ({
  admin,
  ...routeProps
}: RouteProps & { admin?: boolean }) => {
  const location = useLocation();
  const { isLoggedIn, admin: isAdmin } = useAuth();

  if (!isLoggedIn && !location.hash.includes('callback')) {
    return <Redirect to="/login" />;
  }

  if (admin && !isAdmin) {
    alert('Not authorized to view this page');
    return <Redirect to="/" />;
  }

  return <Route {...routeProps} />;
};

const LoggedOutRoute = ({
  requiredRole,
  ...routeProps
}: RouteProps & { requiredRole?: RoleType }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  const groupDialogOpen = location.search === '?groupOpen';

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return <Route {...routeProps} />;
};

export const RootRoute = () => {
  const { isLoggedIn } = useAuth();

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() =>
          isLoggedIn ? <Redirect to="/home/today" /> : <Redirect to="/login" />
        }
      />
      <LoggedInRoute path="/admin" component={AdminPage} admin={true} />
      <LoggedInRoute path="/home/:tab" component={Frontpage} />
      <LoggedInRoute path="/ops" component={OPSPageContainer} />
      <LoggedInRoute path="/curriculum" component={CurriculumPage} exact />
      <LoggedInRoute path="/my-plan/:tab" component={MyPlanPage} />
      <LoggedInRoute path="/progress" component={ProgressPage} exact />
      <LoggedInRoute path="/groups" component={GroupsPage} exact />
      <LoggedInRoute
        path="/progress/transversal/:transversalCode/:transversal"
        component={TransversalProgressPage}
        exact
      />
      <LoggedInRoute
        path="/curriculum/subjects/:tab/:grade"
        component={CurriculumSubjectsPage}
      />
      <Route
        path="/logout"
        component={() => {
          window.location.href = process.env.REACT_APP_BASE_URL
            ? `${process.env.REACT_APP_BASE_URL}/login`
            : '';
          return null;
        }}
      />
      <LoggedOutRoute
        path="/login"
        key="/login"
        component={LoginPageContainer}
      />
      <LoggedOutRoute
        path="/auth/azure"
        component={() => {
          window.location.href = `${OAUTH_LOGIN}` ?? '';
          return null;
        }}
      />
      <LoggedOutRoute
        path="/auth/google"
        component={() => {
          window.location.href = `${OAUTH_LOGIN}` ?? '';
          return null;
        }}
      />
      <LoggedOutRoute
        path="/register"
        key="/register"
        component={RegisterPageContainer}
      />
    </Switch>
  );
};
