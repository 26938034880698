import { isAfter, isBefore, isEqual, parseISO } from 'date-fns';
import {
  ContentProgressBulletpointStats,
  ContentProgressContentStats,
  ContentProgressObjectiveStats,
} from '../routes/my-plan/types';
import {
  Objective,
  TransversalItem,
} from '../routes/ops/components/types/types';

const today = new Date();

// returns true if completed, false if in progress, null if not planned
export const isBulletpointCompleted = (
  bulletpointStat?: ContentProgressBulletpointStats
): boolean | null => {
  if (bulletpointStat && bulletpointStat.plannedAt) {
    return isBefore(parseISO(bulletpointStat?.plannedAt), today);
  }
  return null;
};

export const isBulletpointPlanned = (
  bulletpointStat?: ContentProgressBulletpointStats
): boolean | null => {
  if (bulletpointStat && bulletpointStat.plannedAt) {
    const parsed = parseISO(bulletpointStat?.plannedAt);
    return isAfter(parsed, today) || isEqual(parsed, today);
  }
  return null;
};

export const isContentCompleted = (
  amountOfBulletpoints: number,
  contentStat?: ContentProgressContentStats
): boolean | null => {
  if (contentStat) {
    if (contentStat.bulletpoints.length < amountOfBulletpoints) {
      return false;
    }
    return !contentStat.bulletpoints.find(
      (i) => isBulletpointCompleted(i) !== true
    );
  }
  return null;
};

export const isObjectiveCompleted = (
  objective: Objective,
  objectiveStat?: ContentProgressObjectiveStats
): boolean | null => {
  if (objectiveStat && objectiveStat.contents.length > 0) {
    const contentsLength = objective.contents ? objective.contents.length : 0;
    const transversalsLength = objective.transversals
      ? objective.transversals.length
      : 0;
    const combined = contentsLength + transversalsLength;
    if (objectiveStat.contents.length < combined) {
      return false;
    }
    const contentsCompleted = objective.contents
      ?.map((c) =>
        isContentCompleted(
          c.bulletpoints.keys?.length,
          objectiveStat?.contents.find((co) => co.contentId === c.code)
        )
      )
      .every((con) => con === true);
    const transversalsCompleted = objective.transversals
      ?.map((t) =>
        isContentCompleted(
          t.bulletpoints.keys?.length,
          objectiveStat?.contents.find((tr) => tr.contentId === t.code)
        )
      )
      .every((tra) => tra === true);
    return contentsCompleted === true && transversalsCompleted === true;
  }
  return null;
};

export interface Stats {
  completed: number;
  planned: number;
  all: number;
}

const countAllBulletpoints = (objectives: Objective[]) =>
  objectives
    .map((o) => {
      const contentBulletpointsAmount =
        o?.contents
          ?.map((c) => Object.keys(c.bulletpoints).length)
          .reduce((a, b) => a + b, 0) ?? 0;
      const transversalsBulletpointsAmount =
        o?.transversals
          ?.map((t) => Object.keys(t.bulletpoints).length)
          .reduce((a, b) => a + b, 0) ?? 0;
      return contentBulletpointsAmount + transversalsBulletpointsAmount;
    })
    .reduce((a, b) => a + b, 0);

const countCompletedBulletpoints = (
  objectiveStats: ContentProgressObjectiveStats[]
) =>
  objectiveStats
    .map((os) =>
      os.contents
        .map(
          (osc) =>
            osc.bulletpoints.filter((oscb) => isBulletpointCompleted(oscb))
              .length
        )
        .reduce((a, b) => a + b, 0)
    )
    .reduce((a, b) => a + b, 0) ?? 0;

const countPlannedBulletpoints = (
  objectiveStats: ContentProgressObjectiveStats[]
) =>
  objectiveStats
    .map((os) =>
      os.contents
        .map(
          (osc) =>
            osc.bulletpoints.filter((oscb) => isBulletpointPlanned(oscb)).length
        )
        .reduce((a, b) => a + b, 0)
    )
    .reduce((a, b) => a + b, 0) ?? 0;

export const getSubjectStats = (
  objectives: Objective[],
  objectiveStats: ContentProgressObjectiveStats[]
): Stats => ({
  completed: countCompletedBulletpoints(objectiveStats),
  planned: countPlannedBulletpoints(objectiveStats),
  all: countAllBulletpoints(objectives),
});

const countAllTransversalBulletpoints = (transversals: TransversalItem[]) =>
  transversals
    .map((t) => Object.keys(t.bulletpoints).length ?? 0)
    .reduce((a, b) => a + b, 0) ?? 0;

const countAllCompletedTransversalBulletpoints = (
  transversals: TransversalItem[],
  bulletpointStats: ContentProgressBulletpointStats[]
) =>
  transversals
    .map(
      (t) =>
        Object.keys(t.bulletpoints).filter((bp) => {
          const found = bulletpointStats.find(
            (bps) => bps.bulletpointId === bp
          );
          if (found) {
            return isBulletpointCompleted(found);
          }
          return false;
        }).length ?? 0
    )
    .reduce((a, b) => a + b, 0) ?? 0;

const countAllPlannedTransversalBulletpoints = (
  transversals: TransversalItem[],
  bulletpointStats: ContentProgressBulletpointStats[]
) =>
  transversals
    .map(
      (t) =>
        Object.keys(t.bulletpoints).filter((bp) => {
          const found = bulletpointStats.find(
            (bps) => bps.bulletpointId === bp
          );
          if (found) {
            return isBulletpointPlanned(found);
          }
          return false;
        }).length ?? 0
    )
    .reduce((a, b) => a + b, 0) ?? 0;

export const getTransversalStats = (
  transversals: TransversalItem[],
  bulletpointStats: ContentProgressBulletpointStats[]
): Stats => ({
  completed: countAllCompletedTransversalBulletpoints(
    transversals,
    bulletpointStats
  ),
  planned: countAllPlannedTransversalBulletpoints(
    transversals,
    bulletpointStats
  ),
  all: countAllTransversalBulletpoints(transversals),
});
