/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { queryCache, useMutation, useQuery } from 'react-query';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import {
  ContentItem,
  LinkItem,
  TransversalItem,
} from '../../../routes/ops/components/types/types';
import { ViewLinksDialog } from './ViewLinksDialog';
import { AddLinkDialog } from './AddLinkDialog';
import { EditLinkDialog } from './EditLinkDialog';
import * as linksApi from '../../../api/linksapi';
import { InfoLinkDialog } from './InfoLinkDialog';
import { SkillCode } from '../../../routes/my-plan/state';
import { ContentProgressContentStats } from '../../../routes/my-plan/types';
import { BulletpointBall, ContentBall, SmallLinkBall } from './Balls';
import { resolveBulletpointStyle, resolveContentStyle } from './StatStyles';
import { QUERY_CONFIG } from '../../../utils/queryconfig';
import { useAuth } from '../../../utils/auth/useAuth';

const LINKS_API_KEY = 'links-api';

export const ContentOrTransversalView = ({
  gradeId,
  item,
  subjectCode,
  objectiveCode,
  onSkillClick,
  addedSkillCodes,
  hideLinkButtons,
  contentProgressContentStats,
  onSkillClickIconVisible = true,
}: {
  gradeId: string;
  item: TransversalItem | ContentItem;
  subjectCode: string;
  objectiveCode: string;
  onSkillClick?: (
    code: string,
    gradeId: string,
    subjectId: string,
    contentId: string,
    objectiveId: string,
    desc: string
  ) => void;
  addedSkillCodes?: SkillCode[];
  hideLinkButtons?: boolean;
  contentProgressContentStats?: ContentProgressContentStats;
  onSkillClickIconVisible?: boolean;
}) => {
  const { user } = useAuth();
  const [linksOpen, setLinksOpen] = React.useState(false);
  const [selectedLinkBulletpoint, setSelectedLinkBulletpoint] =
    React.useState<string>();
  const [addLinkOpen, setAddLinkOpen] = React.useState(false);
  const [editItem, setEditItem] = React.useState<LinkItem | undefined>();
  const [infoItem, setInfoItem] = React.useState<LinkItem | undefined>();
  const descriptions = Object.keys(item.bulletpoints || {}).map((key) => ({
    code: key,
    // @ts-ignore
    description: item.bulletpoints[key],
  }));

  const contentId = `${subjectCode}-${objectiveCode}-${item.code}`;

  const links = useQuery(
    `${LINKS_API_KEY}/${contentId}`,
    () => linksApi.getByContentId(contentId),
    {
      enabled: !!item,
      ...QUERY_CONFIG,
    }
  );

  const [createLink] = useMutation(linksApi.createAndAttachLink, {
    onSuccess: () => {
      queryCache.invalidateQueries(`${LINKS_API_KEY}/${contentId}`);
    },
  });

  const [updateLink] = useMutation(linksApi.updateLink, {
    onSuccess: () => {
      queryCache.invalidateQueries(`${LINKS_API_KEY}/${contentId}`);
    },
  });

  const [removeLink] = useMutation(linksApi.deAttachLink, {
    onSuccess: () => {
      queryCache.invalidateQueries(`${LINKS_API_KEY}/${contentId}`);
    },
  });

  // @ts-ignore
  const linksData: LinkItem[] = links?.data?.length ? links.data : [];
  const linksDataWithOwner = linksData.map((link) => ({
    ...link,
    owner: link.pk?.split('USER#')[1] === user?.sub,
  }));

  return (
    <>
      <ViewLinksDialog
        open={linksOpen}
        onClose={() => setLinksOpen(false)}
        title={item.name}
        descriptions={descriptions}
        links={linksDataWithOwner}
        onEditLink={(link: LinkItem) => {
          setEditItem(link);
        }}
        onRemoveLink={(linkId: number, bulletpointId: string) => {
          removeLink({
            contentId,
            linkId,
            bulletpointId,
          });
        }}
        onInfoLink={(link: LinkItem) => {
          setInfoItem(link);
        }}
        onAddLinkOpen={(open: boolean) => {
          setAddLinkOpen(open);
        }}
        onLinksDialogOpen={(open: boolean) => {
          setLinksOpen(open);
        }}
        selectedBulletpoint={selectedLinkBulletpoint}
      />
      <AddLinkDialog
        open={addLinkOpen}
        onClose={() => setAddLinkOpen(false)}
        description={
          descriptions.filter((d) => d.code === selectedLinkBulletpoint)[0]
        }
        onAddLink={(
          title: string,
          link: string,
          description: string,
          bulletpointId: string | null,
          publicLink: boolean
        ) => {
          createLink({
            contentId,
            linkId: 0,
            description,
            link,
            title,
            bulletpointId,
            type: 'WEB',
            private: !publicLink,
            enabled: true,
          });
        }}
      />
      <EditLinkDialog
        open={!!editItem}
        onClose={() => {
          setEditItem(undefined);
        }}
        title={item.name}
        descriptions={descriptions}
        setEditItem={setEditItem}
        editItem={editItem}
        onUpdateLink={(
          title: string,
          link: string,
          description: string,
          bulletpointId: string
        ) => {
          updateLink({
            contentId,
            linkId: editItem?.linkId,
            description,
            link,
            title,
            // Bulletpoint id:s currently not implemented in ops json.
            // Maybe they should not be implemented altogether?
            // 1 for palceholder. We should probably get rid of this in api
            bulletpointId,
            type: 'WEB',
            private: !!editItem?.private,
            enabled: true,
          });
        }}
      />
      <InfoLinkDialog
        open={!!infoItem}
        onClose={() => {
          setInfoItem(undefined);
        }}
        title={item.name}
        descriptions={descriptions}
        infoItem={infoItem}
      />
      <Grid item xs={3} />
      <Grid item xs={9}>
        <Box style={{ display: 'flex', marginLeft: '-40px' }}>
          <ContentBall
            style={resolveContentStyle(
              descriptions.length,
              contentProgressContentStats
            )}
          >
            <Typography variant="body1" color="primary" align="right">
              {item.code.substring(0, 3)}
            </Typography>
          </ContentBall>
          <Typography variant="body2" style={{ marginTop: '8px' }}>
            {' '}
            {item.name}{' '}
          </Typography>
        </Box>
        <ul
          style={
            onSkillClick
              ? { padding: '0 0 0 0px', margin: '0px' }
              : { padding: '0 0 0 16px', margin: '0px' }
          }
        >
          {descriptions.map((desc) => (
            <div
              key={desc.code}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                padding: '5px',
              }}
            >
              {hideLinkButtons && (
                <SmallLinkBall
                  style={{ marginLeft: '-38px', marginTop: '3px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedLinkBulletpoint(desc.code);
                    setLinksOpen(true);
                  }}
                >
                  {linksDataWithOwner.filter(
                    (l) => l.bulletpointId === desc.code
                  ).length > 0 ? (
                    <LinkIcon fontSize="small" />
                  ) : (
                    <LinkOffIcon fontSize="small" />
                  )}
                </SmallLinkBall>
              )}
              {onSkillClick && (
                <IconButton
                  size="small"
                  style={{ marginRight: '4px' }}
                  onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    onSkillClick(
                      desc.code,
                      gradeId,
                      subjectCode,
                      item.code,
                      objectiveCode,
                      desc.description
                    );
                  }}
                >
                  {onSkillClickIconVisible ? (
                    <BulletpointBall
                      style={resolveBulletpointStyle(
                        contentProgressContentStats?.bulletpoints.find(
                          (bulletpointStat) =>
                            bulletpointStat.bulletpointId === desc.code
                        )
                      )}
                    >
                      {addedSkillCodes &&
                      addedSkillCodes.filter((a) => a.skillCode === desc.code)
                        .length > 0 ? (
                        <RemoveIcon />
                      ) : (
                        <AddIcon />
                      )}
                    </BulletpointBall>
                  ) : (
                    <BulletpointBall
                      style={resolveBulletpointStyle(
                        contentProgressContentStats?.bulletpoints.find(
                          (bulletpointStat) =>
                            bulletpointStat.bulletpointId === desc.code
                        )
                      )}
                    >
                      <li
                        style={{
                          position: 'relative',
                          left: '14px',
                          top: '-1px',
                        }}
                      />
                    </BulletpointBall>
                  )}
                </IconButton>
              )}
              <li style={{ listStyle: 'none' }}>
                <Typography variant="caption">{desc.description}</Typography>
              </li>
            </div>
          ))}
        </ul>
      </Grid>
    </>
  );
};
