import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { LoginPage } from './components/LoginPage';
import * as a from './actions';
import { getLoginResultCode, isLoginRunning } from './selectors';

const mapStateToProps = createStructuredSelector({
  loginResultCode: getLoginResultCode,
  isLoginRunning,
});

const actionCreators = {
  login: a.loginAction,
};

export const LoginPageContainer = compose(
  connect(mapStateToProps, actionCreators),
  withRouter
)(LoginPage);
