import { Typography } from '@material-ui/core';
import React from 'react';
import { Stats } from '../../utils/stathelpers';

interface Props {
  stats: Stats;
}

export const BulletpointStatsText = ({ stats }: Props) => (
  <Typography variant="caption">
    Tasks completed: {stats.completed} / {stats.all} ({stats.planned} planned)
  </Typography>
);
