import { atom } from 'recoil';
import { getThisWeek, Week } from '../../utils/weekhelpers';
import { SimpleSubject } from './helpers';

export type SkillCode = {
  skillCode: string;
  gradeId: string;
  objectiveId: string;
  subjectId: string;
  contentId: string;
  desc: string;
};
// prettier-ignore
export const selectedSkillCodes = atom<SkillCode[]>({
  key: 'selectedSkillsState',
  default: [],
});

export const selectedStateWeek = atom<Week>({
  key: 'week',
  default: getThisWeek(),
});

export const selectedStateDate = atom<Date>({
  key: 'date',
  default: new Date(),
});

export const disabledCalendarSubjects = atom<SimpleSubject[]>({
  key: 'disabledCalendarSubjects',
  default: [],
});

export const selectedSubject = atom<SimpleSubject | undefined>({
  key: 'simpleSubject',
  default: undefined,
});

export const calendarPageShowAllFilter = atom<boolean>({
  key: 'calendarPageShowAllFilter',
  default: true,
});
