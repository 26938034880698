import { Box, Card, Collapse, Grid } from '@material-ui/core';
import * as React from 'react';
import { SubjectCardHeaderBox } from '../../components/SubjectCardHeaderBox';
import { ProgressBarWithText } from '../../features/progress/ProgressBarWithText';
import { findSubjectAbbreviation } from '../../utils/findSubjectAbbreviation';
import { getTransversalStats } from '../../utils/stathelpers';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import {
  SubjectItem,
  TransversalItemWithInfo,
} from '../ops/components/types/types';
import { TransversalProgressCardBody } from './TransversalProgressCardBody';

interface Props {
  groupId: string | undefined;
  transversals: TransversalItemWithInfo[];
  bulletpointStats: ContentProgressBulletpointStats[];
  subjects: SubjectItem[];
}

export const TransversalProgressCard = ({
  groupId,
  transversals,
  bulletpointStats,
  subjects,
}: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  const stats = getTransversalStats(transversals, bulletpointStats);
  const { subject, grade } = transversals[0];
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card variant="outlined">
        <Box display="flex" onClick={() => setExpanded(!expanded)}>
          <SubjectCardHeaderBox
            subjectAbbreviation={findSubjectAbbreviation(
              subject.title,
              subjects
            )}
            grade={grade.grade}
          />
          <Box flex={1} p={1}>
            <ProgressBarWithText stats={stats} />
          </Box>
        </Box>
        <Collapse in={expanded}>
          <TransversalProgressCardBody
            groupId={groupId}
            transversals={transversals}
            bulletpointStats={bulletpointStats}
            subjects={subjects}
          />
        </Collapse>
      </Card>
    </Grid>
  );
};
