import { validateEmail, validatePassword } from '../../utils/validate';
import { validateRequiredValues } from '../../utils/formvalidator';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateFormValues = (values: any): Object => {
  const errors = Object();

  const required = validateRequiredValues(values);

  if (!validatePassword(values.password)) {
    errors.password = 'Password too weak';
  }

  if (!validateEmail(values.username)) {
    errors.username = 'Invalid username';
  }

  return {
    ...errors,
    ...required,
  };
};
