import { FormControl, MenuItem, Select } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { updateGroupSelection } from '../../api/groupapi';
import { Group } from './Group';

export const GroupSelection = ({
  groups,
  open,
}: {
  groups: Group[];
  open: boolean;
}) => {
  const selectedGroup = groups.filter((f) => f.selected === true)[0];
  const location = useLocation();
  const [selectOpen, setSelectOpen] = useState(open);
  const [group, setGroup] = useState(selectedGroup.groupId);
  const isGroupsPage = location.pathname === '/groups';
  const [updateSelectedGroup] = useMutation(updateGroupSelection, {
    onSuccess: () => {
      queryCache.invalidateQueries();
      setSelectOpen(false);
    },
  });

  return !isGroupsPage ? (
    <FormControl
      style={{
        backgroundColor: '#245f73',
        borderColor: '#245f73',
        width: '300px',
      }}
    >
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        key="group-control"
        disableUnderline
        open={selectOpen}
        style={{ backgroundColor: '#245f73', borderColor: '#245f73' }}
        labelId="group-select"
        id="groups"
        defaultValue={selectedGroup.groupId}
        onChange={(evt) => {
          setGroup(evt.target.value as string);
          updateSelectedGroup(group);
        }}
        value={group}
        onOpen={() => setSelectOpen(true)}
        onClose={() => setSelectOpen(false)}
        label="Group"
      >
        {groups.map((g) => (
          <MenuItem
            style={{ backgroundColor: '#245f73', borderColor: '#245f73' }}
            key={g.groupId}
            value={g.groupId}
          >
            <Typography variant="body2" style={{ color: 'white' }}>
              {g.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <div />
  );
};
