import { ADMIN_API_URL } from '../routes/constants';
import { apiGet, apiPatch } from './genericapi';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUsers = (): Promise<any> => apiGet(`${ADMIN_API_URL}/users`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateOps = (payload: any): Promise<any> =>
  apiPatch(`${ADMIN_API_URL}/users/${payload.userId}/ops`, {
    ops: payload.ops,
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateAdmin = (payload: any): Promise<any> =>
  apiPatch(`${ADMIN_API_URL}/users/${payload.userId}/admin`, payload.admin);
