import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { queryCache, useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { StyledDialog } from './StyledDialog';
import { ContentProgress } from '../../routes/my-plan/types';
import * as contentProgressApi from '../../api/contentprogressapi';
import { selectedSkillCodes, SkillCode } from '../../routes/my-plan/state';

export const DeleteContentProgressDialog = ({
  open,
  onClose,
  contentProgress,
  desc,
}: {
  open: boolean;
  onClose: () => void;
  contentProgress: ContentProgress;
  desc: string;
}) => {
  const [addedSkillCodes, setSkillCodes] = useRecoilState(selectedSkillCodes);
  const [deleteContentProgress] = useMutation(
    contentProgressApi.deleteContentProgress,
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries('contentprogressapi');
      },
    }
  );
  return (
    <StyledDialog
      open={open}
      // @ts-ignore
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
      maxWidth="md"
    >
      <DialogTitle color="primary">
        Are you sure you want to delete?
      </DialogTitle>
      <DialogContent>
        <ul style={{ padding: '0 0 0 16px' }}>
          <li>
            <Typography variant="body2">{desc}</Typography>
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setSkillCodes((codes: SkillCode[]) => [
              ...codes,
              {
                skillCode: contentProgress.bulletpointId,
                gradeId: contentProgress.gradeId,
                subjectId: contentProgress.subjectId,
                contentId: contentProgress.contentId,
                objectiveId: contentProgress.objectiveId,
                desc,
              },
            ]);
            deleteContentProgress(contentProgress.contentProgressId).then(() =>
              onClose()
            );
          }}
        >
          Reschedule
        </Button>
        <Button
          color="primary"
          onClick={() => {
            deleteContentProgress(contentProgress.contentProgressId).then(() =>
              onClose()
            );
          }}
        >
          Confirm Delete
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
