import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { WeekdayView } from '../my-plan/WeekdayView';
import { SubjectItem } from '../ops/components/types/types';
import { selectedStateDate, selectedStateWeek } from '../my-plan/state';
import { DaySelection } from '../../features/my-plan/DaySelection';
import { WEEKDAYS } from '../my-plan/helpers';
import { GroupSelectionDialog } from './GroupSelectionDialog';

type Props = {
  groupId: string | undefined;
  subjects: SubjectItem[];
  groupDialogOpen: boolean;
};

export const FrontpageToday = ({
  groupId,
  subjects,
  groupDialogOpen,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(groupDialogOpen);
  const [week, setWeek] = useRecoilState(selectedStateWeek);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedStateDate);
  return (
    <>
      <GroupSelectionDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DaySelection />
        </Grid>
        <Grid item xs={12}>
          <WeekdayView
            groupId={groupId}
            subjects={subjects}
            week={week}
            weekdays={WEEKDAYS}
            onlyDate={selectedDate}
            showLinks={true}
            showDelete={false}
          />
        </Grid>
      </Grid>
    </>
  );
};
