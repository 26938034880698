import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export const ProgressIndicator = () => (
  <CircularProgress
    color="inherit"
    style={{
      left: '50%',
      top: '50%',
      position: 'absolute',
    }}
  />
);
