export const skillCodeToLabel = (skillCode: string): string => {
  const splittedSkillCode = skillCode.split('-');
  return `${splittedSkillCode[splittedSkillCode.length - 2]}-${
    splittedSkillCode[splittedSkillCode.length - 1]
  }`;
};

export const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export interface SimpleSubject {
  subjectId: string;
  gradeId: number;
}
