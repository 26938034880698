import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const SmallButton = styled(Button)`
  padding: 4px;
  margin-right: 32px;
  & > .MuiButton-label {
    line-height: 1;
    margin-bottom: -2px;
  }
`;

export const ExtraSmallButton = styled(Button)`
  padding: 4px;
  min-width: 32px;
  & > .MuiButton-label {
    line-height: 1;
  }
`;
