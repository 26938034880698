import * as t from './actionTypes';
import { AlertType } from './types';
import { Action } from '../common/types/types';

type State = {
  activeAlert: AlertType | null;
  alertVisible: boolean;
};

export const initialState = {
  activeAlert: null,
  alertVisible: false,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case t.SET_ALERT: {
      return {
        ...state,
        activeAlert: action.payload,
        alertVisible: true,
      };
    }
    case t.RESET_ALERT: {
      return {
        ...state,
        activeAlert: null,
      };
    }
    case t.TOGGLE_ALERT: {
      return { ...state, alertVisible: !state.alertVisible };
    }
    case t.HIDE_ALERT: {
      return { ...state, alertVisible: false };
    }
    default:
      return state;
  }
};
