import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 24px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 16px;
      padding: 16px;
    }
  }
`;
