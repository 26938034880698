import { parseNumber } from '../../utils/string';
import {
  SubjectItem,
  TransversalItemWithInfo,
} from '../ops/components/types/types';

export const parseTransversalsMap = (
  subjects: SubjectItem[]
): Map<string, TransversalItemWithInfo[]> => {
  let transversals: TransversalItemWithInfo[] = [];
  subjects.forEach((subject) =>
    subject.grades?.forEach((grade) =>
      grade.objectives?.forEach((o) => {
        if (o?.transversals) {
          transversals = [
            ...transversals,
            ...o.transversals.map((t) => ({
              ...t,
              grade,
              subject,
              objective: o,
            })),
          ];
        }
      })
    )
  );
  const transversalsMap = new Map<string, TransversalItemWithInfo[]>();
  transversals.forEach((t) => {
    const found = transversalsMap.get(t.name);
    if (found) {
      transversalsMap.set(t.name, [...found, t]);
    } else {
      transversalsMap.set(t.name, [t]);
    }
  });
  return transversalsMap;
};

export const parseTransversalNumber = (code: string) => parseNumber(code) ?? 0;
