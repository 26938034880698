import React from 'react';
import styled from 'styled-components';
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
  Hidden,
  Paper,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import BookIcon from '@material-ui/icons/Book';
import GroupIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { NavLink as RouterNavLink } from 'react-router-dom';

const BottomNavigation = styled(MuiBottomNavigation)`
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
`;

export const NavLink = styled(RouterNavLink).attrs({
  activeClassName: 'list-item-link-active',
})`
  &.${(p) => p.activeClassName} {
    color: ${(p) => p.theme.palette.primary.main};
  }

  &.${(p) => p.activeClassName} svg {
    fill: ${(p) => p.theme.palette.primary.main};
  }
`;

export const BottomBar = () => (
  // @ts-ignore
  <BottomNavigation showLabels component={Paper} elevation={4}>
    <BottomNavigationAction
      component={NavLink}
      to="/home/today"
      label={<Hidden xsDown>Home</Hidden>}
      exact
      icon={<HomeIcon />}
    />
    <BottomNavigationAction
      component={NavLink}
      to="/curriculum"
      label={<Hidden xsDown>Curriculum</Hidden>}
      icon={<BookIcon />}
    />
    <BottomNavigationAction
      component={NavLink}
      to="/my-plan/content"
      label={<Hidden xsDown>My Plan</Hidden>}
      icon={<EventIcon />}
    />
    <BottomNavigationAction
      component={NavLink}
      to="/progress"
      label={<Hidden xsDown>Progress</Hidden>}
      icon={<AssessmentIcon />}
    />
    <BottomNavigationAction
      component={NavLink}
      to="/groups"
      label={<Hidden xsDown>Groups</Hidden>}
      icon={<GroupIcon />}
    />
  </BottomNavigation>
);
