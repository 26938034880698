import { takeEvery, put, select, delay } from 'redux-saga/effects';
import * as a from './actions';
import * as aa from '../../App/actions';
import * as t from './actionTypes';
import * as s from './selectors';
import { Action } from '../../common/types/types';
import * as api from '../../api/usersapi';
import { sortArrayByProperty } from '../../utils/tableutils';
import { AlertSeverity } from '../../App/enums';

export function* orchestrateRetrieveItems(action: Action) {
  const id = action.payload;
  const response = yield api.get(id).catch(() => null);

  if (response) {
    yield id ? put(a.selectItem(response)) : put(a.storeItems(response));
  } else {
    yield put(aa.setFetchAlert(AlertSeverity.ERROR));
  }
}

export function* orchestrateCreateOrUpdateItemStart(action: Action) {
  const { history, item } = action.payload;
  const { id } = item;
  const response = id
    ? yield api.update(id, item).catch(() => null)
    : yield api.create(item).catch(() => null);
  const severity = response ? AlertSeverity.SUCCESS : AlertSeverity.ERROR;
  yield put(id ? aa.setUpdateAlert(severity) : aa.setCreateAlert(severity));

  if (response && history) {
    yield put(a.retrieveItems());
    yield delay(500);
    yield put(aa.navigateTo(history, '/users'));
  }
}

export function* orchestrateDeleteStart(action: Action) {
  const { history, id } = action.payload;
  const response = yield id && api.remove(id).catch(() => null);
  const severity = response ? AlertSeverity.SUCCESS : AlertSeverity.ERROR;
  yield put(id ? aa.setDeleteAlert(severity) : aa.setDeleteAlert(severity));

  if (response && history) {
    yield put(a.retrieveItems());
    yield delay(500);
    yield put(aa.navigateTo(history, '/users'));
  }
}

export function* orchestrateSort(action: Action) {
  const property = yield action.payload.property;
  const fieldType = yield action.payload.fieldType;
  const items = yield select(s.getItems);
  const sortDesc = yield select(s.getSortDesc);
  const sortedItems = yield sortArrayByProperty(
    items,
    property,
    fieldType,
    sortDesc
  );
  yield put(a.storeItems(sortedItems));
}

export const spawnOrchestrations = [
  takeEvery(t.RETRIEVE_START, orchestrateRetrieveItems),
  takeEvery(t.SORT, orchestrateSort),
  takeEvery(t.UPDATE_START, orchestrateCreateOrUpdateItemStart),
  takeEvery(t.DELETE_START, orchestrateDeleteStart),
];
