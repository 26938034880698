/* eslint-disable @typescript-eslint/no-explicit-any */
import { LINKS_API_URL } from '../routes/constants';
import { doDelete } from '../utils/http';
import { apiGet, apiPost, apiPut } from './genericapi';

export const getByLinkId = (linkId: number): Promise<any> =>
  apiGet(`${LINKS_API_URL}/id/${linkId}`);
export const getByContentId = <T>(id?: string | number): Promise<T> =>
  apiGet(`${LINKS_API_URL}/cid/${id}`);
export const createLink = <T, P>(payload: P): Promise<T> =>
  apiPost(`${LINKS_API_URL}/id`, payload);
export const updateLink = (payload: any): Promise<any> =>
  apiPut(`${LINKS_API_URL}/id/${payload.linkId}`, {
    title: payload.title,
    type: payload.type,
    link: payload.link,
    private: payload.private,
    userId: payload.userId,
    description: payload.description,
  });

const createLinksApiBulletPointUrl = (
  contentId: string,
  bulletpointId: string | null
): string => {
  const base = `${LINKS_API_URL}/id?contentId=${contentId}`;
  return bulletpointId !== null
    ? `${base}&bulletpointId=${bulletpointId}`
    : base;
};

export const createAndAttachLink = (payload: any): Promise<any> =>
  apiPost(
    createLinksApiBulletPointUrl(payload.contentId, payload.bulletpointId),
    {
      title: payload.title,
      type: payload.type,
      link: payload.link,
      private: payload.private,
      description: payload.description,
    }
  );

const createDeAttachLinkApiUrl = (
  linkId: string,
  contentId: string,
  bulletpointId: string | null
): string => {
  const base = `${LINKS_API_URL}/deattach/${linkId}/${contentId}`;
  return bulletpointId !== null ? `${base}/${bulletpointId}` : base;
};

export const deAttachLink = (payload: any): Promise<any> =>
  doDelete({
    url: createDeAttachLinkApiUrl(
      payload.linkId,
      payload.contentId,
      payload.bulletpointId
    ),
  });
