import React, { Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { useAuth } from '../../utils/auth/useAuth';
import { GroupSelectionWrapper } from './GroupSelectionWrapper';

type Props = {
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const Header = ({ setDrawerOpen }: Props) => {
  const { logout, isLoggedIn, name } = useAuth();
  return (
    <AppBar>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        {isLoggedIn && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        )}
        {name && (
          <Typography
            className="data-hj-suppress"
            variant="body2"
            color="inherit"
            style={{ padding: '12px' }}
          >
            {name}
          </Typography>
        )}
        {isLoggedIn && <GroupSelectionWrapper open={false} />}
        <IconButton
          onClick={() => {
            logout();
          }}
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
