import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import { useAuth } from '../../utils/auth/useAuth';

// eslint-disable-next-line
const Logo = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.21 20">
    <title>Polar partners logo</title>
    <path
      d="M3.32,4.15a2,2,0,0,0,1.32-.5,1.57,1.57,0,0,0,.55-1.19,1.53,1.53,0,0,0-.57-1.2A2,2,0,0,0,3.27.77H.76V4.15ZM3.27,0A2.69,2.69,0,0,1,5.16.73a2.28,2.28,0,0,1,.78,1.73,2.24,2.24,0,0,1-.73,1.68,2.73,2.73,0,0,1-1.79.76H.76V8.64a.35.35,0,0,1-.11.26A.36.36,0,0,1,.38,9,.36.36,0,0,1,.11,8.9.35.35,0,0,1,0,8.64V.39A.35.35,0,0,1,.11.13.36.36,0,0,1,.38,0Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M14.63.77A3.63,3.63,0,0,0,12,1.87a3.6,3.6,0,0,0-1.11,2.65A3.61,3.61,0,0,0,12,7.17a3.83,3.83,0,0,0,5.35,0,3.61,3.61,0,0,0,1.11-2.65,3.6,3.6,0,0,0-1.11-2.65A3.64,3.64,0,0,0,14.63.77m0,8.25A4.4,4.4,0,0,1,11.42,7.7a4.31,4.31,0,0,1-1.33-3.18,4.31,4.31,0,0,1,1.33-3.18A4.4,4.4,0,0,1,14.63,0a4.37,4.37,0,0,1,3.21,1.32,4.31,4.31,0,0,1,1.33,3.18A4.31,4.31,0,0,1,17.84,7.7,4.37,4.37,0,0,1,14.63,9"
      style={{ fill: '#fff' }}
    />
    <path
      d="M24.6,0a.4.4,0,0,1,.27.11A.36.36,0,0,1,25,.41V8.27h4.11a.36.36,0,0,1,.26.11.34.34,0,0,1,.12.26.34.34,0,0,1-.12.27.36.36,0,0,1-.26.11H24.6a.4.4,0,0,1-.27-.11.37.37,0,0,1-.11-.27V.41a.36.36,0,0,1,.11-.26A.4.4,0,0,1,24.6,0"
      style={{ fill: '#fff' }}
    />
    <path
      d="M39.59,6.16l-2-4.78-2,4.78ZM34,9a.36.36,0,0,1-.2-.2.3.3,0,0,1,0-.29l.88-2.11h0L37.24.26l0-.07,0,0s0,0,0,0l0,0,0,0A.35.35,0,0,1,37.52,0h.14a.35.35,0,0,1,.14.06l0,0,0,0s0,0,0,0l0,0,0,.07L40.5,6.39h0l.89,2.11a.37.37,0,0,1,0,.29.38.38,0,0,1-.21.2A.32.32,0,0,1,41,9a.35.35,0,0,1-.35-.23L39.9,6.92H35.28l-.79,1.87a.35.35,0,0,1-.35.23h-.07L34,9"
      style={{ fill: '#fff' }}
    />
    <path
      d="M51.71,2.45a1.52,1.52,0,0,0-.56-1.18,2,2,0,0,0-1.34-.5H47.32V4.13h2.53a2,2,0,0,0,1.31-.5,1.55,1.55,0,0,0,.55-1.18M49.81,0a2.69,2.69,0,0,1,1.87.71,2.21,2.21,0,0,1,.77,1.71A2.22,2.22,0,0,1,51.82,4a2.59,2.59,0,0,1-1.56.82l1.8,3.64a.37.37,0,0,1,0,.29.39.39,0,0,1-.19.21.42.42,0,0,1-.16,0,.35.35,0,0,1-.34-.21l-2-3.93H47.32v3.7a.37.37,0,0,1-.12.27.35.35,0,0,1-.26.1.39.39,0,0,1-.27-.1.4.4,0,0,1-.11-.27V.4a.36.36,0,0,1,.11-.26A.4.4,0,0,1,46.94,0Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M67.63,4.15A2,2,0,0,0,69,3.65a1.57,1.57,0,0,0,.55-1.19,1.53,1.53,0,0,0-.57-1.2A2,2,0,0,0,67.58.77H65.07V4.15Zm0-4.14a2.69,2.69,0,0,1,1.89.72,2.28,2.28,0,0,1,.78,1.73,2.24,2.24,0,0,1-.73,1.68,2.73,2.73,0,0,1-1.79.76H65.07V8.64A.32.32,0,0,1,65,8.9a.34.34,0,0,1-.26.12.34.34,0,0,1-.27-.12.35.35,0,0,1-.11-.26V.39a.35.35,0,0,1,.11-.26A.34.34,0,0,1,64.69,0Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M80.62,6.16l-2-4.78-2,4.78ZM75,9a.38.38,0,0,1-.21-.2.37.37,0,0,1,0-.29l.88-2.11h0L78.27.26l0-.07,0,0s0,0,0,0l0,0,0,0A.31.31,0,0,1,78.54,0h.15a.42.42,0,0,1,.14.06l0,0,0,0,0,0s0,0,0,0a.25.25,0,0,1,0,.07l2.56,6.13h0l.88,2.11a.37.37,0,0,1,0,.29.36.36,0,0,1-.2.2l-.15,0a.36.36,0,0,1-.35-.23l-.78-1.87H76.31l-.79,1.87a.35.35,0,0,1-.35.23H75.1L75,9"
      style={{ fill: '#fff' }}
    />
    <path
      d="M92.47,2.45a1.51,1.51,0,0,0-.55-1.18,2,2,0,0,0-1.35-.5H88.08V4.13h2.54a2,2,0,0,0,1.31-.5,1.54,1.54,0,0,0,.54-1.18M90.57,0a2.72,2.72,0,0,1,1.88.71,2.24,2.24,0,0,1,.77,1.71A2.22,2.22,0,0,1,92.59,4,2.64,2.64,0,0,1,91,4.84l1.81,3.64a.42.42,0,0,1,0,.29.43.43,0,0,1-.19.21.47.47,0,0,1-.17,0,.34.34,0,0,1-.33-.21l-2-3.93H88.08v3.7A.36.36,0,0,1,87.7,9a.35.35,0,0,1-.26-.1.4.4,0,0,1-.11-.27V.4a.36.36,0,0,1,.11-.26A.36.36,0,0,1,87.7,0Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M100.57.77h-2.5a.41.41,0,0,1-.28-.11.35.35,0,0,1-.1-.26.36.36,0,0,1,.1-.27A.41.41,0,0,1,98.07,0h5.75a.37.37,0,0,1,.27.11.33.33,0,0,1,.11.27.33.33,0,0,1-.11.26.37.37,0,0,1-.27.11h-2.5V8.64a.33.33,0,0,1-.11.27.37.37,0,0,1-.27.11.36.36,0,0,1-.26-.11.33.33,0,0,1-.11-.27Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M115.75,8.89h0L110,1.48V8.64a.32.32,0,0,1-.12.26.34.34,0,0,1-.26.12.34.34,0,0,1-.27-.12.31.31,0,0,1-.11-.26V.38a.33.33,0,0,1,.25-.35.34.34,0,0,1,.42.12l5.81,7.39V.38a.35.35,0,0,1,.1-.26A.36.36,0,0,1,116,0a.33.33,0,0,1,.27.11.36.36,0,0,1,.11.26V8.67a0,0,0,0,1,0,0v0s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0l0,0,0,0,0,0,0,0s0,0,0,0h0l0,0h-.31l0,0,0,0,0,0,0,0h0"
      style={{ fill: '#fff' }}
    />
    <path
      d="M122.68,9a.37.37,0,0,1-.27-.12.35.35,0,0,1-.11-.26V.38a.36.36,0,0,1,.11-.26.33.33,0,0,1,.27-.11h5a.39.39,0,0,1,.27.11.4.4,0,0,1,.11.27.4.4,0,0,1-.11.27.38.38,0,0,1-.27.1h-4.65V4.14h3.48a.33.33,0,0,1,.26.11.34.34,0,0,1,.12.26.36.36,0,0,1-.12.27.36.36,0,0,1-.26.11h-3.48V8.26h4.65a.36.36,0,0,1,.27.12.36.36,0,0,1,.11.26.35.35,0,0,1-.11.26.36.36,0,0,1-.27.12Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M138.47,2.45a1.51,1.51,0,0,0-.55-1.18,2,2,0,0,0-1.35-.5h-2.48V4.13h2.53a2,2,0,0,0,1.31-.5,1.54,1.54,0,0,0,.54-1.18M136.57,0a2.7,2.7,0,0,1,1.88.71,2.21,2.21,0,0,1,.77,1.71A2.22,2.22,0,0,1,138.59,4a2.61,2.61,0,0,1-1.57.82l1.81,3.64a.37.37,0,0,1,0,.29.43.43,0,0,1-.19.21.47.47,0,0,1-.17,0,.35.35,0,0,1-.33-.21l-2-3.93h-2.11v3.7a.37.37,0,0,1-.12.27.36.36,0,0,1-.27.1.35.35,0,0,1-.26-.1.4.4,0,0,1-.11-.27V.4a.36.36,0,0,1,.11-.26A.36.36,0,0,1,133.7,0Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M145.4,8.61a2.24,2.24,0,0,1-1.19-1.43.39.39,0,0,1,0-.28.35.35,0,0,1,.24-.17.35.35,0,0,1,.28,0,.36.36,0,0,1,.17.23,1.54,1.54,0,0,0,.89,1,3.09,3.09,0,0,0,1.36.28,2.07,2.07,0,0,0,1.42-.53,1.79,1.79,0,0,0,.64-1.28,1.29,1.29,0,0,0-.38-1,2.38,2.38,0,0,0-1.76-.6.36.36,0,0,1-.26-.11.4.4,0,0,1-.11-.27.34.34,0,0,1,.12-.26.37.37,0,0,1,.27-.11,3.12,3.12,0,0,1,2.29.84A2,2,0,0,1,150,6.49a2.44,2.44,0,0,1-.86,1.79,2.8,2.8,0,0,1-1.94.74,3.87,3.87,0,0,1-1.79-.41m1.68-3.72a3.05,3.05,0,0,1-2.3-.84,2,2,0,0,1-.58-1.51,2.45,2.45,0,0,1,.87-1.79A2.8,2.8,0,0,1,147,0a3.75,3.75,0,0,1,1.79.42A2.2,2.2,0,0,1,150,1.85a.38.38,0,0,1,0,.28.36.36,0,0,1-.23.17.33.33,0,0,1-.29,0,.3.3,0,0,1-.17-.22,1.48,1.48,0,0,0-.89-1A3.09,3.09,0,0,0,147,.76a2.07,2.07,0,0,0-1.42.53A1.79,1.79,0,0,0,145,2.58a1.28,1.28,0,0,0,.38,1,2.38,2.38,0,0,0,1.76.6.41.41,0,0,1,.26.11.37.37,0,0,1,0,.53.4.4,0,0,1-.27.11Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M19.87,17.24h.63a1.34,1.34,0,0,0,1-.4,1.29,1.29,0,0,0,.4-1,1.31,1.31,0,0,0-.4-1,1.34,1.34,0,0,0-1-.4h-.63Zm-.14.28a.13.13,0,0,1-.1,0,.12.12,0,0,1,0-.1v-3a.14.14,0,0,1,.14-.14h.77a1.63,1.63,0,0,1,1.18.48,1.65,1.65,0,0,1,0,2.33,1.63,1.63,0,0,1-1.18.48Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M24.46,17.52a.12.12,0,0,1-.1,0,.13.13,0,0,1,0-.1v-3a.14.14,0,0,1,.14-.14H26.3a.18.18,0,0,1,.1,0,.14.14,0,0,1-.1.24H24.6v1.24h1.27a.18.18,0,0,1,.1,0,.14.14,0,0,1-.1.24H24.6v1.24h1.7a.13.13,0,0,1,.1,0,.11.11,0,0,1,0,.09.14.14,0,0,1-.14.14Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M28.57,14.23a.14.14,0,0,1,.14.14v2.88h1.51a.1.1,0,0,1,.09,0,.14.14,0,0,1,.05.09.12.12,0,0,1-.05.1.1.1,0,0,1-.09,0H28.57a.13.13,0,0,1-.1,0,.15.15,0,0,1,0-.1v-3a.18.18,0,0,1,0-.1.13.13,0,0,1,.1,0"
      style={{ fill: '#fff' }}
    />
    <path
      d="M32.58,17.38a.12.12,0,0,1,0,.1.1.1,0,0,1-.09,0,.13.13,0,0,1-.1,0,.12.12,0,0,1,0-.1v-3a.13.13,0,0,1,0-.1.18.18,0,0,1,.1,0,.13.13,0,0,1,.09,0,.14.14,0,0,1,0,.1Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M37,14.23a.11.11,0,0,1,.08.07.14.14,0,0,1,0,.11l-1.07,3h0l0,0h-.19v0h0a0,0,0,0,0,0,0h0l-1.08-3a.14.14,0,0,1,0-.11.14.14,0,0,1,.08-.07.11.11,0,0,1,.1,0,.1.1,0,0,1,.07.08l1,2.65.94-2.65a.1.1,0,0,1,.07-.08l0,0,.05,0"
      style={{ fill: '#fff' }}
    />
    <path
      d="M39.18,17.52a.14.14,0,0,1-.14-.14v-3a.14.14,0,0,1,.14-.14H41a.18.18,0,0,1,.1,0,.14.14,0,0,1-.1.24h-1.7v1.24h1.27a.18.18,0,0,1,.1,0,.14.14,0,0,1-.1.24H39.32v1.24H41a.13.13,0,0,1,.1,0,.11.11,0,0,1,0,.09.14.14,0,0,1-.14.14Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M45,15.12a.59.59,0,0,0-.2-.44.73.73,0,0,0-.5-.18h-.91v1.23h.93a.68.68,0,0,0,.48-.18.58.58,0,0,0,.2-.43m-.7-.89a1,1,0,0,1,.69.26.81.81,0,0,1,.28.63.78.78,0,0,1-.23.57,1,1,0,0,1-.57.3l.66,1.34a.12.12,0,0,1,0,.1.14.14,0,0,1-.07.08h-.06a.14.14,0,0,1-.13-.07L44.21,16h-.78v1.36a.14.14,0,0,1-.24.1.18.18,0,0,1,0-.1v-3a.15.15,0,0,1,0-.09.13.13,0,0,1,.1,0Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M47.66,17.38a.12.12,0,0,1,0,.1.1.1,0,0,1-.09,0,.13.13,0,0,1-.1,0,.12.12,0,0,1,0-.1v-3a.13.13,0,0,1,0-.1.18.18,0,0,1,.1,0,.13.13,0,0,1,.09,0,.14.14,0,0,1,0,.1Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M52.3,17.47h0l-2.13-2.71v2.62a.14.14,0,0,1-.14.14.11.11,0,0,1-.09,0,.12.12,0,0,1,0-.1v-3a.13.13,0,0,1,.24-.08L52.27,17V14.35a.11.11,0,0,1,0-.09.15.15,0,0,1,.2,0,.15.15,0,0,1,0,.09v3h0v0h0v0h0s0,0,0,0,0,0,0,0H52.3"
      style={{ fill: '#fff' }}
    />
    <path
      d="M57.18,17.48a.15.15,0,0,1,0-.1V15.93H56a.15.15,0,0,1-.1,0,.13.13,0,0,1,0-.2.12.12,0,0,1,.1,0h1.3a.14.14,0,0,1,.14.14v1.59a.12.12,0,0,1,0,.1.14.14,0,0,1-.2,0M55.05,17a1.64,1.64,0,0,1,0-2.34,1.6,1.6,0,0,1,1.18-.48,1.62,1.62,0,0,1,1,.34.11.11,0,0,1,0,.09.12.12,0,0,1,0,.1.15.15,0,0,1-.1.06.17.17,0,0,1-.1,0,1.38,1.38,0,0,0-1.83.12,1.38,1.38,0,0,0,0,1.94,1.36,1.36,0,0,0,1,.4,1.33,1.33,0,0,0,.93-.35.13.13,0,0,1,.1,0,.14.14,0,0,1,.1,0,.15.15,0,0,1,0,.1.11.11,0,0,1,0,.09,1.62,1.62,0,0,1-1.12.43A1.6,1.6,0,0,1,55.05,17"
      style={{ fill: '#fff' }}
    />
    <path
      d="M62.52,17.38a.12.12,0,0,1,0,.1.13.13,0,0,1-.1,0,.1.1,0,0,1-.09,0,.12.12,0,0,1,0-.1v-3a.14.14,0,0,1,0-.1.11.11,0,0,1,.09,0h1.85a.13.13,0,0,1,.09,0,.12.12,0,0,1,0,.2.1.1,0,0,1-.09,0H62.52v1.24H63.8a.13.13,0,0,1,.09,0,.12.12,0,0,1,0,.2.13.13,0,0,1-.09,0H62.52Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M66.59,17.38a.15.15,0,0,1,0,.1.14.14,0,0,1-.2,0,.12.12,0,0,1,0-.1v-3a.13.13,0,0,1,0-.1.14.14,0,0,1,.24.1Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M71.23,17.47h0L69.1,14.76v2.62a.12.12,0,0,1,0,.1.11.11,0,0,1-.09,0,.14.14,0,0,1-.14-.14v-3a.13.13,0,0,1,.24-.08L71.2,17V14.35a.15.15,0,0,1,0-.09.14.14,0,0,1,.2,0,.11.11,0,0,1,0,.09v3h0v0h0v0h0s0,0,0,0,0,0,0,0h-.19"
      style={{ fill: '#fff' }}
    />
    <path
      d="M76.12,17.47h0L74,14.76v2.62a.12.12,0,0,1,0,.1.15.15,0,0,1-.2,0,.12.12,0,0,1,0-.1v-3a.13.13,0,0,1,.09-.13.14.14,0,0,1,.16,0L76.09,17V14.35a.14.14,0,0,1,.13-.13.12.12,0,0,1,.1,0,.11.11,0,0,1,0,.09v3h0v0h0v0h0s0,0,0,0h-.19"
      style={{ fill: '#fff' }}
    />
    <path
      d="M78.88,17.38a.12.12,0,0,1,0,.1.15.15,0,0,1-.2,0,.12.12,0,0,1,0-.1v-3a.13.13,0,0,1,0-.1.15.15,0,0,1,.2,0,.13.13,0,0,1,0,.1Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M81.44,17.37a.81.81,0,0,1-.44-.52.15.15,0,0,1,0-.11.16.16,0,0,1,.08-.06.15.15,0,0,1,.11,0,.12.12,0,0,1,.06.08.57.57,0,0,0,.33.37,1.27,1.27,0,0,0,.5.1.81.81,0,0,0,.52-.19.69.69,0,0,0,.23-.48.44.44,0,0,0-.14-.35.85.85,0,0,0-.64-.22.18.18,0,0,1-.1,0,.13.13,0,0,1,0-.1.14.14,0,0,1,0-.1.13.13,0,0,1,.09,0,1.15,1.15,0,0,1,.84.31.66.66,0,0,1,.21.56.89.89,0,0,1-.31.65,1,1,0,0,1-.71.27,1.4,1.4,0,0,1-.66-.15M82.05,16a1.15,1.15,0,0,1-.84-.31.72.72,0,0,1-.21-.55.91.91,0,0,1,.32-.66,1,1,0,0,1,.71-.27,1.43,1.43,0,0,1,.66.15.82.82,0,0,1,.43.52.14.14,0,0,1,0,.11.15.15,0,0,1-.09.06.12.12,0,0,1-.1,0,.11.11,0,0,1-.06-.09.56.56,0,0,0-.33-.36,1.15,1.15,0,0,0-.5-.11.75.75,0,0,0-.52.2.63.63,0,0,0-.23.47.44.44,0,0,0,.14.35.8.8,0,0,0,.64.22.19.19,0,0,1,.1,0,.15.15,0,0,1,0,.09.19.19,0,0,1,0,.1.11.11,0,0,1-.09,0Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M87.54,17.52a.14.14,0,0,1-.14-.14V16H85.5v1.37a.14.14,0,0,1-.24.1.18.18,0,0,1,0-.1v-3a.15.15,0,0,1,0-.09.12.12,0,0,1,.2,0,.11.11,0,0,1,0,.09v1.37h1.9V14.36a.11.11,0,0,1,0-.09.13.13,0,0,1,.2,0,.15.15,0,0,1,0,.09v3a.18.18,0,0,1,0,.1.13.13,0,0,1-.1,0"
      style={{ fill: '#fff' }}
    />
    <path
      d="M92.73,17.52a.12.12,0,0,1-.1,0,.13.13,0,0,1,0-.1v-3a.14.14,0,0,1,.14-.14h1.84a.14.14,0,0,1,.1.24.13.13,0,0,1-.1,0H92.86v1.24h1.28a.18.18,0,0,1,.1,0,.14.14,0,0,1-.1.24H92.86v1.24h1.71a.13.13,0,0,1,.1,0,.15.15,0,0,1,0,.09.18.18,0,0,1,0,.1.13.13,0,0,1-.1,0Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M97,17.24h.63a1.34,1.34,0,0,0,1-.4,1.3,1.3,0,0,0,.41-1,1.38,1.38,0,0,0-1.39-1.37H97Zm-.14.28a.13.13,0,0,1-.1,0,.12.12,0,0,1,0-.1v-3a.14.14,0,0,1,.14-.14h.77a1.63,1.63,0,0,1,1.18.48,1.63,1.63,0,0,1,0,2.33,1.63,1.63,0,0,1-1.18.48Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M103.53,14.23a.11.11,0,0,1,.09,0,.13.13,0,0,1,0,.1v2a1.14,1.14,0,0,1-1.94.8,1.07,1.07,0,0,1-.34-.8v-2a.18.18,0,0,1,0-.1.13.13,0,0,1,.1,0,.1.1,0,0,1,.09,0,.13.13,0,0,1,0,.1v2a.83.83,0,0,0,.26.61.82.82,0,0,0,.61.25.84.84,0,0,0,.62-.25.82.82,0,0,0,.25-.61v-2a.14.14,0,0,1,.14-.14"
      style={{ fill: '#fff' }}
    />
    <path
      d="M107.28,17.52a1.59,1.59,0,0,1-1.17-.48,1.64,1.64,0,0,1,0-2.34,1.59,1.59,0,0,1,1.17-.48,1.63,1.63,0,0,1,1,.34.11.11,0,0,1,0,.09.12.12,0,0,1,0,.1.21.21,0,0,1-.1.06.19.19,0,0,1-.1,0,1.37,1.37,0,0,0-.85-.29,1.34,1.34,0,0,0-1,.41,1.38,1.38,0,0,0,0,1.94,1.42,1.42,0,0,0,1.92.05.13.13,0,0,1,.1,0,.14.14,0,0,1,.1,0,.15.15,0,0,1,0,.1.16.16,0,0,1,0,.09,1.61,1.61,0,0,1-1.13.43"
      style={{ fill: '#fff' }}
    />
    <path
      d="M112.4,16.48l-.74-1.76-.73,1.76Zm-2.05,1a.14.14,0,0,1-.08-.07.21.21,0,0,1,0-.11l.33-.77h0l.94-2.25s0,0,0,0h0v0h0l0,0h0l0,0h0v0h0v0l.94,2.25h0l.33.77a.21.21,0,0,1,0,.11.14.14,0,0,1-.08.07h0a.13.13,0,0,1-.13-.08l-.29-.69h-1.69l-.29.69a.13.13,0,0,1-.13.08h-.05"
      style={{ fill: '#fff' }}
    />
    <path
      d="M115.78,14.5h-.92a.21.21,0,0,1-.1,0,.18.18,0,0,1,0-.1.13.13,0,0,1,0-.09.16.16,0,0,1,.1,0H117a.12.12,0,0,1,.1,0,.1.1,0,0,1,0,.09.13.13,0,0,1,0,.1.15.15,0,0,1-.1,0h-.91v2.88a.15.15,0,0,1,0,.1.15.15,0,0,1-.2,0,.12.12,0,0,1,0-.1Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M119.3,17.38a.12.12,0,0,1,0,.1.11.11,0,0,1-.09,0,.14.14,0,0,1-.14-.14v-3a.13.13,0,0,1,0-.1.15.15,0,0,1,.1,0,.15.15,0,0,1,.09,0,.14.14,0,0,1,0,.1Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M123,14.5a1.32,1.32,0,0,0-1,.4,1.29,1.29,0,0,0-.41,1,1.27,1.27,0,0,0,.41,1,1.33,1.33,0,0,0,1,.41,1.34,1.34,0,0,0,1-.41,1.31,1.31,0,0,0,.41-1A1.38,1.38,0,0,0,123,14.5m0,3a1.61,1.61,0,0,1-1.18-.48,1.63,1.63,0,0,1,0-2.33,1.59,1.59,0,0,1,1.18-.49A1.65,1.65,0,0,1,124.13,17a1.59,1.59,0,0,1-1.17.48"
      style={{ fill: '#fff' }}
    />
    <path
      d="M129.19,17.47h0l-2.13-2.71v2.62a.12.12,0,0,1,0,.1.15.15,0,0,1-.2,0,.15.15,0,0,1,0-.1v-3a.13.13,0,0,1,.09-.13.14.14,0,0,1,.16,0L129.16,17V14.35a.14.14,0,0,1,.13-.13.12.12,0,0,1,.1,0,.11.11,0,0,1,0,.09v3h0v0h0v0h0s0,0,0,0h-.19"
      style={{ fill: '#fff' }}
    />
  </svg>
);

const DrawerTopArea = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.palette.secondary.main};
  color: white;
`;

export const NavLink = styled(RouterNavLink).attrs({
  activeClassName: 'list-item-link-active',
})`
  &.${(p) => p.activeClassName} .MuiTypography-root {
    font-weight: 700;
    color: ${(p) => p.theme.palette.primary.main.dark};
  }

  &.${(p) => p.activeClassName} svg {
    fill: ${(p) => p.theme.palette.primary.main};
  }

  &:link {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
`;

type Props = {
  open: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

export const Drawer = ({ open, setDrawerOpen }: Props) => {
  const { user } = useAuth();

  return (
    <MuiDrawer
      anchor="left"
      open={open}
      onClose={() => setDrawerOpen(false)}
      PaperProps={{ style: { width: '280px' } }}
    >
      <DrawerTopArea>
        <Logo style={{ width: '200px' }} />
      </DrawerTopArea>
      <List>
        <ListItem button component={NavLink} to="/curriculum">
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText>Curriculum</ListItemText>
        </ListItem>
        {user?.admin && (
          <ListItem button component={NavLink} to="/admin">
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText>Admin</ListItemText>
          </ListItem>
        )}
      </List>
    </MuiDrawer>
  );
};
