import { doGet, doPost, doDelete, doPut, doPatch } from '../utils/http';
import { Log } from '../utils/debug';

export const apiGet = async <T>(baseUrl: string, id?: number): Promise<T> => {
  const response = await doGet({
    url: id ? `${baseUrl}/id/${id}` : baseUrl,
  });

  const result = response.json();
  Log.trace(result, 'genericapi.ts:getResponse');
  return result;
};

export const apiPost = async <T, K>(
  baseUrl: string,
  payload: T
): Promise<K> => {
  const response = await doPost({
    url: baseUrl,
    payload,
  });
  let result;
  try {
    result = await response.json();
  } catch (e) {
    result = {};
  }
  Log.trace(result, 'genericapi.ts:postResponse');
  return result;
};

export const apiPut = async <T, K>(
  baseUrl: string,
  payload: T,
  id?: number
): Promise<K> => {
  const response = await doPut({
    url: id ? `${baseUrl}/id/${id}` : baseUrl,
    payload,
  });
  let result;
  try {
    result = await response.json();
  } catch (e) {
    result = {};
  }
  Log.trace(result, 'genericapi.ts:putResponse');
  return result;
};

export const apiPatch = async <T, K>(
  baseUrl: string,
  payload: T,
  id?: number
): Promise<K> => {
  const response = await doPatch({
    url: id ? `${baseUrl}/id/${id}` : baseUrl,
    payload,
  });
  let result;
  try {
    result = await response.json();
  } catch (e) {
    result = {};
  }
  Log.trace(result, 'genericapi.ts:patchResponse');
  return result;
};

export const apiDelete = async (
  baseUrl: string,
  id: number
): Promise<Number> => {
  const response = await doDelete({
    url: `${baseUrl}/id/${id}`,
  });

  const result = response.status;
  Log.trace('Response', 'genericapi.ts:deleteResponse');
  Log.trace(result, 'genericapi.ts');
  return result;
};

export const apiDeleteWithUrl = async (baseUrl: string): Promise<Number> => {
  const response = await doDelete({
    url: `${baseUrl}`,
  });

  const result = response.status;
  Log.trace('Response', 'genericapi.ts:deleteResponse');
  Log.trace(result, 'genericapi.ts');
  return result;
};
