import { useQuery } from 'react-query';
import { getGroups } from '../api/groupapi';
import { Group } from '../features/app-base/Group';
import { QUERY_CONFIG } from '../utils/queryconfig';

export const useSelectedGroup = () => {
  const res = useQuery(
    ['groupapi'],
    async () => {
      const response = await getGroups();
      return response;
    },
    QUERY_CONFIG
  ) as {
    data: Group[];
  };
  return res?.data?.filter((g) => g.selected)[0];
};
