import { NAME } from './constants';

export const TOGGLE_DIALOG = `${NAME}/TOGGLE_DIALOG`;
export const SELECT_ITEM = `${NAME}/SELECT_ITEM`;
export const RETRIEVE_START = `${NAME}/RETRIEVE_START`;
export const UPDATE_START = `${NAME}/UPDATE_START`;
export const UPDATE_SUCCESS = `${NAME}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${NAME}/UPDATE_FAILURE`;
export const DELETE_START = `${NAME}/DELETE_START`;
export const DELETE_SUCCESS = `${NAME}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${NAME}/DELETE_FAILURE`;
export const SORT = `${NAME}/SORT`;
export const RETRIEVE_SUCCESS = `${NAME}/RETRIEVE_SUCCESS`;
export const RETRIEVE_FAILURE = `${NAME}/RETRIEVE_FAILURE`;
