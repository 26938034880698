import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ConfirmProvider } from 'material-ui-confirm';
import * as serviceWorker from './serviceWorker';
import store from './redux/index';
import { getBrowserLocale, getMessages } from './utils/locale';
import { AppContainer } from './App/AppContainer';
import './resources/fonts/Roboto-Regular.ttf';
import { theme } from './theme';
import { AuthContextProvider } from './utils/auth/AuthContext';

if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'curriculum:*');
}

const localeType = getBrowserLocale();

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={localeType} messages={getMessages(localeType)}>
      <BrowserRouter>
        <CssBaseline />
        <RecoilRoot>
          <AuthContextProvider>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <ConfirmProvider>
                  <AppContainer />
                </ConfirmProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </AuthContextProvider>
        </RecoilRoot>
      </BrowserRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
