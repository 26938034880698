import { GROUP_API_URL, STUDENT_API_URL } from '../routes/constants';
import { apiGet, apiPost, apiPut } from './genericapi';

export const getGroups = <T>(): Promise<T> => apiGet(`${GROUP_API_URL}/`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateGroupSelection = (groupId: string): Promise<any> =>
  apiPut(`${GROUP_API_URL}/${groupId}/select`, {});

// refactor student api url to groups...
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inviteStudentsToGroup = (payload: any): Promise<any> =>
  apiPost(`${STUDENT_API_URL}?groupId=${payload.groupId}`, payload.emails);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addGroup = (payload: any): Promise<any> =>
  apiPut(`${GROUP_API_URL}`, payload);
