import * as React from 'react';
import {
  Grid,
  Typography,
  Card,
  Box,
  ButtonBase,
  useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SimpleSubject } from '../../../routes/my-plan/helpers';

/**
 * These components are used to render selectable subjects
 */

type Props = {
  favorite?: boolean;
  onClick?: () => void;
  title: React.ReactNode;
  href?: string;
  disabledSubjects?: SimpleSubject[];
  subject: string;
  smallMode?: boolean;
  grade: number;
};

const selectedStyle = {
  backgroundColor: 'rgb(36, 95, 115)',
  color: 'white',
};

const resolveStyle = (
  subject: string,
  grade: number,
  disabledSubjects?: SimpleSubject[]
) => {
  if (!disabledSubjects) {
    return {};
  }
  return disabledSubjects?.find(
    (subj) =>
      subj.subjectId.toLowerCase() === subject.toLowerCase() &&
      subj.gradeId === grade
  )
    ? {}
    : selectedStyle;
};

export const SubjectGridItem = ({
  favorite,
  onClick,
  title,
  href,
  disabledSubjects,
  subject,
  smallMode,
  grade,
}: Props) => {
  const theme = useTheme();
  const card = (
    <Card
      variant="outlined"
      onClick={onClick}
      style={{ width: '100%', overflow: 'hidden' }}
    >
      <ButtonBase style={{ width: '100%' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={{ xs: '60px', sm: '100px' }}
          p={2}
          bgcolor={favorite ? theme.palette.primary.main : 'inherit'}
          color={favorite ? theme.palette.primary.contrastText : 'inherit'}
          style={resolveStyle(subject, grade, disabledSubjects)}
          width="100%"
        >
          <Typography
            style={{ whiteSpace: 'nowrap' }}
            variant="caption"
            align="center"
          >
            {title}
          </Typography>
        </Box>
      </ButtonBase>
    </Card>
  );

  return smallMode ? (
    <Grid item xs={2} sm={2} md={1} lg={1}>
      {href ? (
        <Link style={{ width: '100%' }} to={href}>
          {card}
        </Link>
      ) : (
        card
      )}
    </Grid>
  ) : (
    <Grid item xs={4} sm={3} md={2} lg={1}>
      {href ? (
        <Link style={{ width: '100%' }} to={href}>
          {card}
        </Link>
      ) : (
        card
      )}
    </Grid>
  );
};
