import React from 'react';
import { Grid } from '@material-ui/core';
import { SubjectItem } from '../../../ops/components/types/types';
import { SubjectGridItem } from '../../../../features/curriculum/components/SubjectGridItem';

type Props = {
  grade: number;
  toggleFavoriteSubject: (key: string) => void;
  subjects: SubjectItem[];
  favoriteSubjects: string[] | undefined;
};

export const SelectSubjects = ({
  grade,
  subjects,
  toggleFavoriteSubject,
  favoriteSubjects,
}: Props) => (
  <Grid container spacing={2}>
    {subjects.map((subject: SubjectItem) => (
      <SubjectGridItem
        key={subject.code}
        onClick={() => {
          toggleFavoriteSubject(`${subject.code}-${grade}`);
        }}
        favorite={favoriteSubjects?.includes(`${subject.code}-${grade}`)}
        title={subject.abbreviation}
        subject={subject.title}
        grade={grade}
      />
    ))}
  </Grid>
);
