import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { LinkItem } from '../../../routes/ops/components/types/types';

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 24px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 16px;
      padding: 16px;
    }
  }
`;

export const InfoLinkDialog = ({
  open,
  onClose,
  title,
  infoItem,
  descriptions,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  infoItem: LinkItem | undefined;
  descriptions: { code: string; description: string }[];
}) => (
  <StyledDialog
    open={open}
    // @ts-ignore
    onClose={onClose}
    onClick={(e) => {
      e.stopPropagation();
    }}
    maxWidth="md"
  >
    <DialogTitle color="primary">Link Info</DialogTitle>
    <DialogContent>
      <Typography variant="body2">{title}</Typography>
      {infoItem?.bulletpointId ? (
        <ul style={{ padding: '0 0 0 16px', margin: '0px' }}>
          <li>
            <Typography variant="body2">
              {/* eslint-disable-next-line max-len */}
              {
                descriptions.find((d) => d.code === infoItem?.bulletpointId)
                  ?.description
              }
            </Typography>
          </li>
        </ul>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch checked={!infoItem?.private} name="publicLink" />
              }
              label={
                <Typography variant="caption">
                  Show link to other users
                </Typography>
              }
            />
          </Grid>
          <TextField
            disabled={true}
            label={<Typography variant="caption">Title</Typography>}
            variant="outlined"
            defaultValue={infoItem?.title}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            label={<Typography variant="caption">Web address</Typography>}
            placeholder="http://www.google.com"
            variant="outlined"
            defaultValue={infoItem?.link}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            label={
              <Typography variant="caption">{infoItem?.description}</Typography>
            }
            variant="outlined"
            defaultValue={infoItem?.description}
            fullWidth
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </StyledDialog>
);
