import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#245f73',
      light: '#307f9a',
    },
    secondary: {
      main: '#1F1F38',
    },
  },
});
