import { queryCache, useMutation, useQuery } from 'react-query';
import * as usersApi from '../../../api/usersapi';
import { QUERY_CONFIG } from '../../../utils/queryconfig';

const getQueryKey = (groupId: string | undefined) =>
  `favoriteSubjects:${groupId}`;

const addOrRemove = (array: string[], val: string) =>
  array.includes(val) ? array.filter((it) => it !== val) : [...array, val];

export const useFavoriteSubjects = (groupId: string | undefined) => {
  const [toggleFavoriteSubject] = useMutation(
    (subjectCode: string) => usersApi.putFavorites(favoriteSubjects, groupId),
    {
      onMutate: (subjectCode: string) => {
        queryCache.cancelQueries(getQueryKey(groupId));
        queryCache.setQueryData(
          getQueryKey(groupId),
          addOrRemove(favoriteSubjects || [], subjectCode)
        );
        return () =>
          queryCache.setQueryData(getQueryKey(groupId), favoriteSubjects);
      },
      onError: (err, _, rollback) => {
        rollback();
      },
      onSettled: () => {
        queryCache.invalidateQueries(getQueryKey(groupId));
      },
    }
  );

  const { data: favoriteSubjects } = useQuery<string[]>(
    getQueryKey(groupId),
    async () => {
      const response = await usersApi.getFavorites(groupId);
      return response;
    },
    {
      ...QUERY_CONFIG,
      enabled: !!groupId,
    }
  );

  return {
    favoriteSubjects,
    toggleFavoriteSubject,
  };
};
