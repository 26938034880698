import React from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';

const StyledTextField = styled(TextField)`
  font-weight: bold;
`;

type Props = {
  fieldName: string;
  fieldTitle: string;
  fieldValue: string | number | undefined;
  fieldType?: string;
  fieldRequired?: boolean;
  autoFocus?: boolean;
  fieldDisabled?: boolean;
  style?: object;
  helperText?: string;
};

export const GenericTextField = ({
  fieldName,
  fieldTitle,
  fieldValue,
  fieldType = 'text',
  fieldRequired = false,
  fieldDisabled = false,
  autoFocus = false,
  helperText,
  ...rest
}: Props) => (
  <Field
    id={`standard-textfield-${fieldName}`}
    name={fieldName}
    label={fieldTitle}
    placeholder={fieldTitle}
    color="primary"
    margin="dense"
    value={fieldValue}
    initialValue={fieldValue}
    required={fieldRequired}
    autoFocus={autoFocus}
    type={fieldType}
    disabled={fieldDisabled}
    helperText={helperText}
    {...rest}
  >
    {({
      input: { name, onChange, value, ...restInput },
      meta,
      ...restSubItem
    }) => (
      <StyledTextField
        variant="outlined"
        name={name}
        error={meta.error && meta.touched}
        inputProps={restInput}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(event: any) => onChange(event.target.value)}
        value={value}
        {...restSubItem}
      />
    )}
  </Field>
);
