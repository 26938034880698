import * as actionTypes from './actionTypes';
import { UserLoginType } from './types';
import { Action } from '../../common/types/types';

export const loginAction = (data: UserLoginType): Action => ({
  type: actionTypes.LOGIN,
  payload: data,
});

export const logoutAction = (): Action => ({
  type: actionTypes.LOGOUT,
});

export const setLoginResultCode = (httpCode: number): Action => ({
  type: actionTypes.LOGIN_FINISH,
  payload: httpCode,
});
