import { NAME } from './constants';

export const SELECT_ITEM = `${NAME}/SELECT_ITEM`;
export const RETRIEVE_START = `${NAME}/RETRIEVE_START`;
export const RETRIEVE_SUCCESS = `${NAME}/RETRIEVE_SUCCESS`;
export const RETRIEVE_FAILURE = `${NAME}/RETRIEVE_FAILURE`;

export const SELECT_SUBJECT = `${NAME}/SELECT_SUBJECT`;
export const TOGGLE_FAVORITE_SUBJECT = `${NAME}/TOGGLE_FAVORITE_SUBJECT`;
export const RETRIEVE_FAVORITE_SUBJECTS_START = `${NAME}/RETRIEVE_FAVORITE_SUBJECTS_START`;
export const RETRIEVE_FAVORITE_SUBJECTS_SUCCESS = `${NAME}/RETRIEVE_FAVORITE_SUBJECTS_SUCCESS`;
export const RETRIEVE_FAVORITE_SUBJECTS_FAILURE = `${NAME}/RETRIEVE_FAVORITE_SUBJECTS_FAILURE`;
export const SUBMIT_FAVORITE_SUBJECTS_START = `${NAME}/SUBMIT_FAVORITE_SUBJECTS_START`;
export const SUBMIT_FAVORITE_SUBJECTS_SUCCESS = `${NAME}/SUBMIT_FAVORITE_SUBJECTS_SUCCESS`;
export const SUBMIT_FAVORITE_SUBJECTS_FAILURE = `${NAME}/SUBMIT_FAVORITE_SUBJECTS_FAILURE`;

export const UPDATE_OPS_FILTER = `${NAME}/UPDATE_OPS_FILTER`;

export const RETRIEVE_LINKS_START = `${NAME}/RETRIEVE_LINKS_START`;
export const RETRIEVE_LINKS_SUCCESS = `${NAME}/RETRIEVE_LINKS_SUCCESS`;
export const RETRIEVE_LINKS_FAILURE = `${NAME}/RETRIEVE_LINKS_FAILURE`;

export const RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_START = `${NAME}/RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_START`;
export const RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_SUCCESS = `${NAME}/RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_SUCCESS`;
export const RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_FAILURE = `${NAME}/RETRIEVE_SELECTED_TRANSVERSALS_AND_CONTENTS_FAILURE`;
export const TOGGLE_TRANSVERSAL_OR_CONTENT_SELECTED = `${NAME}/TOGGLE_TRANSVERSAL_OR_CONTENT_SELECTED`;

export const SUBMIT_LINK = `${NAME}/SUBMIT_LINK`;
