import * as actionTypes from './actionTypes';
import { AlertType } from './types';
import { AlertSeverity } from './enums';
import { Action } from '../common/types/types';

export const setFetchAlert = (severity: AlertSeverity): Action =>
  setAlert({
    severity,
    titleKey: `alert.title.${severity}`,
    messageKey: 'alert.message.fetch',
  });

export const setSearchAlert = (severity: AlertSeverity): Action =>
  setAlert({
    severity,
    titleKey: `alert.title.${severity}`,
    messageKey: 'alert.message.search',
  });

export const setCreateAlert = (severity: AlertSeverity): Action =>
  setAlert({
    severity,
    titleKey: `alert.title.${severity}`,
    messageKey: 'alert.message.create',
  });

export const setUpdateAlert = (severity: AlertSeverity): Action =>
  setAlert({
    severity,
    titleKey: `alert.title.${severity}`,
    messageKey: 'alert.message.update',
  });

export const setDeleteAlert = (severity: AlertSeverity): Action =>
  setAlert({
    severity,
    titleKey: `alert.title.${severity}`,
    messageKey: 'alert.message.delete',
  });

export const setAlert = (alert: AlertType): Action => ({
  type: actionTypes.SET_ALERT,
  payload: alert,
});

export const resetAlert = (): Action => ({
  type: actionTypes.RESET_ALERT,
});

export const toggleAlert = (): Action => ({
  type: actionTypes.TOGGLE_ALERT,
});

export const hideAlert = (): Action => ({
  type: actionTypes.HIDE_ALERT,
});

export const navigateTo = (history: History, path: string): Action => ({
  type: actionTypes.NAVIGATE_TO,
  payload: {
    history,
    path,
  },
});
