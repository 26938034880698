import { Divider, Grid, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useQuery } from 'react-query';
import {
  Route,
  RouteComponentProps,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { getCachedOps } from '../../api/opsCacher';
import { getSubjectsByGrade } from '../ops/utils/getSubjectsByGrade';
import { parseSubjectList } from '../ops/utils/utils';
import { StickyHeader } from '../my-plan/MyPlanPage';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { FrontpageToday } from './FrontpageToday';
import { FrontpageThisWeek } from './FrontpageThisWeek';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { useSelectedGroup } from '../../hooks/useSelectedGroup';

const HomeTabs = ({ currentTab }: { currentTab: string }) => {
  const history = useHistory();
  return (
    <Tabs
      style={{ flex: 1 }}
      value={currentTab}
      onChange={(_, _tab) => history.replace(`/home/${_tab}`)}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab value="today" label="Day" style={{ flex: 1 }} />
      <Tab value="week" label="Week" style={{ flex: 1 }} />
    </Tabs>
  );
};

export const Frontpage = ({ match }: RouteComponentProps<{ tab: string }>) => {
  const location = useLocation();
  const groupDialogOpen = location.search === '?groupOpen';
  const { data: ops } = useQuery('ops', () => getCachedOps(), QUERY_CONFIG);
  const group = useSelectedGroup();

  if (!ops) {
    return <div>Loading ops</div>;
  }

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: false })
  );

  const { tab } = match.params;

  return (
    <>
      <StickyHeader container>
        <Route path="/home/:tab" exact>
          <Breadcrumbs
            currentPage={tab === 'today' ? 'Today' : 'Week'}
            previousPages={[]}
          />
        </Route>
        <Grid
          container
          style={{ marginBottom: '16px', position: 'sticky', top: '40px' }}
        >
          <HomeTabs currentTab={tab} />
          <Divider />
        </Grid>
      </StickyHeader>
      <Route
        path="/home/today"
        exact
        component={() => (
          <FrontpageToday
            groupId={group?.groupId}
            subjects={subjects}
            groupDialogOpen={groupDialogOpen}
          />
        )}
      />
      <Route
        path="/home/week"
        exact
        component={() => (
          <FrontpageThisWeek groupId={group?.groupId} subjects={subjects} />
        )}
      />
    </>
  );
};
