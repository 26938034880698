/* eslint-disable react/jsx-key */
import { Card, Divider, Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import { useQuery } from 'react-query';
import * as contentProgressApi from '../../api/contentprogressapi';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import { SubjectItem } from '../ops/components/types/types';
import {
  parseTransversalNumber,
  parseTransversalsMap,
} from './TransversalHelpers';
import { TransversalProgress } from './TransversalProgress';

type Props = {
  groupId: string | undefined;
  subjects: SubjectItem[];
};

// @ts-ignore
const queryFn = (obj: unknown) => contentProgressApi.getAllStats(obj);

export const TransversalProgressContainer = ({ groupId, subjects }: Props) => {
  const { data: bpdata } = useQuery({
    queryKey: ['contentprogressapiallstats', { groupId }],
    queryFn,
    config: { ...QUERY_CONFIG, enabled: !!groupId },
  });

  if (!bpdata) {
    return <div>Loading</div>;
  }

  const bpStats: ContentProgressBulletpointStats[] = bpdata.length
    ? bpdata
    : [];
  const transversals = parseTransversalsMap(subjects);
  return (
    <>
      <Card variant="outlined" style={{ padding: '25px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Transversal Skills</Typography>
            <Divider />
          </Grid>
          {Array.from(transversals.keys())
            .sort(
              (a, b) =>
                parseTransversalNumber(transversals.get(a)!![0].code) -
                parseTransversalNumber(transversals.get(b)!![0].code)
            )
            .map((key) => (
              <TransversalProgress
                key={key}
                transversals={transversals.get(key)!!}
                bulletpointStats={bpStats}
              />
            ))}
        </Grid>
      </Card>
    </>
  );
};
