import * as actionTypes from './actionTypes';
import { UserType as DtoType, UserFormType as FormType } from './types';
import { Action } from '../../common/types/types';
import { TableHeaderType } from '../../common/components/table/types';

export const retrieveItems = (id?: string): Action => ({
  type: actionTypes.RETRIEVE_START,
  payload: id,
});

export const storeItems = (items: Array<DtoType>): Action => ({
  type: actionTypes.RETRIEVE_SUCCESS,
  payload: items,
});

export const selectItem = (item?: DtoType): Action => ({
  type: actionTypes.SELECT_ITEM,
  payload: item,
});

export const toggleDialog = (isDialogOpen: boolean): Action => ({
  type: actionTypes.TOGGLE_DIALOG,
  payload: isDialogOpen,
});

export const sort = (property: TableHeaderType, fieldType: string): Action => ({
  type: actionTypes.SORT,
  payload: { property, fieldType },
});

export const updateItem = (item: DtoType, history?: History): Action => ({
  type: actionTypes.UPDATE_START,
  payload: {
    history,
    item: {
      id: item.id,
      username: item.username,
      firstname: item.firstname,
      lastname: item.lastname,
      description: item.description,
      password: item.password,
      active: item.active,
      details: item.details,
    },
  },
});

export const deleteItem = (item: FormType, history?: History): Action => ({
  type: actionTypes.DELETE_START,
  payload: {
    id: item.id,
    history,
  },
});
