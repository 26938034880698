/* eslint-disable import/no-duplicates */
import { Box, Card, FormControl } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { queryCache } from 'react-query';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { addDays, subDays } from 'date-fns';
import { enGB } from 'date-fns/locale';

import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { getWeekByDay } from '../../utils/weekhelpers';
import {
  selectedStateDate,
  selectedStateWeek,
} from '../../routes/my-plan/state';

export const WeekSelection = () => {
  const [week, setWeek] = useRecoilState(selectedStateWeek);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedStateDate);

  const updateDate = (date: Date) => {
    setSelectedDate(date);
    setWeek(getWeekByDay(date));
    queryCache.invalidateQueries('contentprogressapi');
  };

  return (
    <Card variant="outlined">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ArrowBack
          style={{ marginTop: '40px', marginRight: '4px' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            updateDate(subDays(selectedDate, 7));
          }}
          fontSize="small"
        />
        <FormControl
          style={{ minWidth: '240px', maxWidth: '350px' }}
          variant="outlined"
        >
          <MuiPickersUtilsProvider locale={enGB} utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Select week"
              value={selectedDate}
              labelFunc={(date: MaterialUiPickersDate) =>
                getWeekByDay(date as Date).label
              }
              onChange={(date) => {
                updateDate(date as Date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
        <ArrowForward
          style={{ marginTop: '40px', marginLeft: '4px' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            updateDate(addDays(selectedDate, 7));
          }}
          fontSize="small"
        />
      </div>
    </Card>
  );
};
