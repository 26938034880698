import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import Switch from '@material-ui/core/Switch';

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 24px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 16px;
      padding: 16px;
    }
  }
`;

export const AddLinkDialog = ({
  open,
  onClose,
  description,
  onAddLink,
}: {
  open: boolean;
  onClose: () => void;
  description: { code: string; description: string } | undefined;
  onAddLink: (
    title: string,
    url: string,
    description: string,
    bulletpointId: string | null,
    publicLink: boolean
  ) => void;
}) => {
  const [bulletpointId, setBulletpointId] = React.useState(
    description ? description.code : ''
  );
  const [linkTitle, setLinkTitle] = React.useState('');
  const [link, setLink] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [publicLink, setPublicLink] = React.useState(false);
  if (!description) {
    return <div />;
  }
  return (
    <StyledDialog
      open={open}
      // @ts-ignore
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      maxWidth="md"
    >
      <DialogTitle color="primary">Add link</DialogTitle>
      <Divider />
      <DialogContent>
        <RadioGroup
          aria-label="bulletpointId"
          name="bulletpointId"
          value={bulletpointId}
          onChange={
            (evt) => setBulletpointId(evt.target.value) // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <ul
            style={{
              padding: '0 0 0 15px',
              margin: '0px',
            }}
          >
            <li>
              <Typography variant="body2">{description.description}</Typography>
            </li>
          </ul>
        </RadioGroup>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: '16px' }}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={publicLink}
                    onChange={() => setPublicLink(!publicLink)}
                    name="publicLink"
                  />
                }
                label={
                  <Typography variant="caption">
                    Show link to other users
                  </Typography>
                }
              />
            </Grid>
            <TextField
              label={<Typography variant="caption">Title</Typography>}
              variant="outlined"
              onChange={(evt) => setLinkTitle(evt.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={<Typography variant="caption">Web address</Typography>}
              placeholder="http://www.google.com"
              variant="outlined"
              onChange={(evt) => setLink(evt.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={<Typography variant="caption">Description</Typography>}
              variant="outlined"
              onChange={(evt) => setDesc(evt.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onClose();
            onAddLink(
              linkTitle,
              link.startsWith('http://') || link.startsWith('https://')
                ? link
                : `https://${link}`,
              desc,
              description.code!!,
              publicLink
            );
          }}
          variant="contained"
          color="primary"
          autoFocus
          startIcon={<AddIcon />}
          disabled={
            !description.code || link === '' || linkTitle === '' || desc === ''
          }
        >
          Add
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
