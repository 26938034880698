import { messagesEN } from '../resources/en_EN';
import { messagesFI } from '../resources/fi_FI';
import { messagesSE } from '../resources/se_SE';

export enum LocaleEnum {
  FI = 'fi',
  SE = 'se',
  EN = 'fi',
}

export const getBrowserLocale = (): LocaleEnum => {
  const locale = window.navigator.language;
  switch (locale) {
    case 'fi':
      return LocaleEnum.FI;
    case 'se':
      return LocaleEnum.SE;
    case 'en':
    default:
      return LocaleEnum.EN;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMessages = (locale: LocaleEnum): any => {
  switch (locale) {
    case LocaleEnum.FI:
      return messagesFI;
    case LocaleEnum.SE:
      return messagesSE;
    case LocaleEnum.EN:
    default:
      return messagesEN;
  }
};
