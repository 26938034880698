import { Grid } from '@material-ui/core';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { SubjectGridItem } from '../../features/curriculum/components/SubjectGridItem';
import { subjectShortForm } from '../../utils/subjectShortForm';
import { SubjectItem } from '../ops/components/types/types';
import { MyPlanSubjectPage } from './MyPlanSubjectPage';
import { selectedSubject } from './state';

type Props = {
  subjects: SubjectItem[];
  groupId: string | undefined;
};

export const MyPlanContentPage = ({ subjects, groupId }: Props) => {
  const [selectedSubj, setSelectedSubj] = useRecoilState(selectedSubject);
  return (
    <>
      <Grid
        container
        spacing={1}
        justify="center"
        style={{ padding: '0 0 15px 0' }}
      >
        {subjects.map((subject) =>
          subject.grades?.map((grade) => (
            <SubjectGridItem
              key={subject.code}
              onClick={() => {
                setSelectedSubj({
                  subjectId: subject.code,
                  gradeId: grade.grade,
                });
              }}
              favorite={
                selectedSubj?.subjectId.toLowerCase() ===
                  subject.code.toLowerCase() &&
                selectedSubj?.gradeId === grade.grade
              }
              title={subjectShortForm(subject.abbreviation, grade.grade)}
              subject={subject.title}
              smallMode={true}
              grade={grade.grade}
            />
          ))
        )}
      </Grid>
      {selectedSubj && (
        <Grid container spacing={1}>
          <MyPlanSubjectPage
            groupId={groupId}
            subjectCode={selectedSubj.subjectId}
            grade={selectedSubj.gradeId}
            subjects={subjects}
          />
        </Grid>
      )}
    </>
  );
};
