import { takeEvery, put, delay, takeLatest } from 'redux-saga/effects';
import * as t from './actionTypes';
import * as a from './actions';
import { Action } from '../common/types/types';
import { Log } from '../utils/debug';

export function* orchestrateSetAlert(action: Action) {
  yield action.payload;
  yield delay(6000);
  yield put(a.hideAlert());
  yield delay(1000);
  yield put(a.resetAlert());
}

export function* orchestrateNavigateToPath(action: Action) {
  const path = yield action.payload.path;
  const history = yield action.payload.history;
  Log.info(`Navigate to [${path}]`, 'orchestrations.ts');
  yield history.push(path);
}

export const spawnOrchestrations = [
  takeEvery(t.SET_ALERT, orchestrateSetAlert),
  takeLatest(t.NAVIGATE_TO, orchestrateNavigateToPath),
];
