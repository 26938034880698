import { Card, Divider, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import * as React from 'react';
import { Objective } from '../../../routes/ops/components/types/types';
import { ContentOrTransversalView } from './ContentOrTransversalView';
import { SkillCode } from '../../../routes/my-plan/state';
import { ContentProgressObjectiveStats } from '../../../routes/my-plan/types';
import { ContentBall, ObjectiveBall } from './Balls';
import { resolveContentStyle, resolveObjectiveStyle } from './StatStyles';

type Props = {
  gradeId: string;
  objective: Objective;
  subjectCode: string;
  onSkillClick?: (
    code: string,
    gradeId: string,
    subjectId: string,
    contentId: string,
    objectiveId: string,
    desc: string
  ) => void;
  addedSkillCodes?: SkillCode[];
  hideLinkButtons?: boolean;
  contentProgressObjectiveStats?: ContentProgressObjectiveStats;
  oneSize?: boolean;
  onSkillClickIconVisible?: boolean;
  expandedCard?: boolean;
};

export const ObjectiveCard = ({
  gradeId,
  objective,
  subjectCode,
  onSkillClick,
  addedSkillCodes,
  hideLinkButtons,
  contentProgressObjectiveStats,
  oneSize = false,
  onSkillClickIconVisible = true,
  expandedCard = false,
}: Props) => {
  const [expanded, setExpanded] = React.useState(expandedCard);
  // Remove me when feature done.
  // eslint-disable-next-line no-console
  return (
    <Grid item xs={12} sm={oneSize ? 12 : 6} md={oneSize ? 12 : 4}>
      <Card variant="outlined" style={{ height: '100%', padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={3}
            style={{ cursor: 'pointer' }}
            onClick={() => setExpanded((it) => !it)}
          >
            <ObjectiveBall
              style={resolveObjectiveStyle(
                objective,
                contentProgressObjectiveStats
              )}
            >
              <Typography variant="h6" color="primary">
                {objective.code}
              </Typography>
            </ObjectiveBall>
          </Grid>
          <Grid
            item
            xs={9}
            style={{ cursor: 'pointer' }}
            onClick={() => setExpanded((it) => !it)}
          >
            <Typography variant="body2">{objective.name}</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{ cursor: 'pointer' }}
            onClick={() => setExpanded((it) => !it)}
          />
          <Grid item xs={9}>
            <Divider
              style={{ cursor: 'pointer' }}
              onClick={() => setExpanded((it) => !it)}
            />
          </Grid>
          {!expanded ? (
            <>
              <Grid item xs={3} />
              <Grid
                item
                container
                spacing={1}
                xs={9}
                style={{ marginTop: '-8px' }}
              >
                {objective.contents?.map((it) => (
                  <ContentBall
                    key={it.code}
                    style={{
                      ...resolveContentStyle(
                        Object.keys(it.bulletpoints).length,
                        contentProgressObjectiveStats?.contents.find(
                          (c) => c.contentId === it.code
                        )
                      ),
                      marginTop: '8px',
                    }}
                  >
                    <Typography variant="body1" color="primary">
                      {it.code.substring(0, 3)}
                    </Typography>
                  </ContentBall>
                ))}
                {objective.transversals?.map((it) => (
                  <ContentBall
                    key={it.code}
                    style={{
                      ...resolveContentStyle(
                        Object.keys(it.bulletpoints).length,
                        contentProgressObjectiveStats?.contents.find(
                          (c) => c.contentId === it.code
                        )
                      ),
                      marginTop: '8px',
                    }}
                  >
                    <Typography variant="body1" color="primary">
                      {it.code.substring(0, 3)}
                    </Typography>
                  </ContentBall>
                ))}
              </Grid>
            </>
          ) : null}
          {expanded ? (
            <>
              {objective.contents?.map((it) => (
                // eslint-disable-next-line
                <ContentOrTransversalView
                  gradeId={gradeId}
                  key={it.code}
                  item={it}
                  subjectCode={subjectCode}
                  objectiveCode={objective.code}
                  onSkillClick={onSkillClick}
                  addedSkillCodes={addedSkillCodes}
                  hideLinkButtons={hideLinkButtons}
                  onSkillClickIconVisible={onSkillClickIconVisible}
                  contentProgressContentStats={contentProgressObjectiveStats?.contents.find(
                    (c) => c.contentId === it.code
                  )}
                />
              ))}
              {objective.transversals?.map((it) => (
                // eslint-disable-next-line
                <ContentOrTransversalView
                  gradeId={gradeId}
                  key={it.code}
                  item={it}
                  subjectCode={subjectCode}
                  objectiveCode={objective.code}
                  onSkillClick={onSkillClick}
                  addedSkillCodes={addedSkillCodes}
                  hideLinkButtons={hideLinkButtons}
                  onSkillClickIconVisible={onSkillClickIconVisible}
                  contentProgressContentStats={contentProgressObjectiveStats?.contents.find(
                    (c) => c.contentId === it.code
                  )}
                />
              ))}
            </>
          ) : null}
        </Grid>
      </Card>
    </Grid>
  );
};
