import { Card, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { StickyHeader } from '../my-plan/MyPlanPage';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import { TransversalItem } from '../ops/components/types/types';
import { TransversalProgress } from './TransversalProgress';

interface HeaderProps {
  transversalCode: string;
  transversal: string;
  transversalItems: TransversalItem[];
  bulletpointStats: ContentProgressBulletpointStats[];
}

export const TransversalProgressHeader = ({
  transversalCode,
  transversal,
  transversalItems,
  bulletpointStats,
}: HeaderProps) => (
  <>
    <StickyHeader container>
      <Breadcrumbs
        currentPage="Transversal competences"
        previousPages={[{ title: 'Progress', path: '/progress' }]}
      />
    </StickyHeader>
    <Card variant="outlined" style={{ padding: '25px' }}>
      <Grid
        container
        spacing={1}
        justify="center"
        style={{ padding: '0 0 5px 0' }}
      >
        <Typography variant="h4" style={{ textAlign: 'center', width: '100%' }}>
          {transversalCode} {transversal}
        </Typography>
        <TransversalProgress
          transversals={transversalItems}
          bulletpointStats={bulletpointStats}
          simpleMode={true}
        />
      </Grid>
    </Card>
  </>
);
