import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import { LinkItem } from '../../../routes/ops/components/types/types';
import * as contentProgressApi from '../../../api/contentprogressapi';
import { StyledDialog } from '../../my-plan/StyledDialog';

export const CalendarLinkDialog = ({
  open,
  onAddLink,
  onClose,
  availableLinks,
  selectedLinks,
  contentProgressId,
  bulletpointDesc,
}: {
  open: boolean;
  onAddLink: () => void;
  onClose: () => void;
  availableLinks: LinkItem[];
  selectedLinks: LinkItem[];
  contentProgressId: string;
  bulletpointDesc: string;
}) => {
  const [sLinks, setSLinks] = useState(selectedLinks);
  const [saveSelectedLinks] = useMutation(
    contentProgressApi.saveSelectedLinks,
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries('contentprogressapi');
      },
    }
  );
  return (
    <StyledDialog
      open={open}
      // @ts-ignore
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
      maxWidth="md"
    >
      <DialogTitle color="primary">Links</DialogTitle>
      <Divider />
      <DialogContent>
        <ul style={{ padding: '0 0 0 4px' }}>
          <li>
            <Typography variant="body2">{bulletpointDesc}</Typography>
          </li>
        </ul>
        <ul style={{ padding: '0 0 0 4px' }}>
          {availableLinks.map((availableLink) => (
            <div
              key={availableLink.linkId}
              style={{ display: 'flex', alignItems: 'flex-start' }}
            >
              <IconButton
                style={{ marginTop: '-4px' }}
                size="small"
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  if (
                    sLinks.filter((s) => s.linkId === availableLink.linkId)
                      .length > 0
                  ) {
                    setSLinks(
                      sLinks.filter((s) => s.linkId !== availableLink.linkId)
                    );
                  } else {
                    setSLinks([availableLink, ...sLinks]);
                  }
                }}
              >
                {sLinks.filter((s) => s.linkId === availableLink.linkId)
                  .length > 0 ? (
                  <RemoveIcon />
                ) : (
                  <AddIcon />
                )}
              </IconButton>
              <li style={{ listStyle: 'none' }}>
                <Link href={availableLink.link} target="_blank">
                  <Typography variant="caption">
                    {availableLink.title}
                  </Typography>
                </Link>
              </li>
            </div>
          ))}
        </ul>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onAddLink} color="primary">
          Add link
        </Button>
        <Button
          onClick={() => {
            saveSelectedLinks({
              contentProgressId,
              linkIds: sLinks.map((s) => s.linkId),
            }).then(onClose);
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
