import { Grid, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { parseISO, format } from 'date-fns';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getUsers } from '../../api/adminapi';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { DAYFORMAT } from '../../utils/weekhelpers';
import { UserDialog } from './UserDialog';
import { User } from './User';

const columns = [
  { field: 'id', headerName: 'ID', type: 'number', width: 70 },
  { field: 'admin', headherName: 'Admin', type: 'boolean', width: 100 },
  { field: 'firstname', headerName: 'First name', type: 'string', width: 130 },
  { field: 'lastname', headerName: 'Last name', type: 'string', width: 130 },
  { field: 'username', headerName: 'Username', type: 'string', width: 250 },
  { field: 'ops', headerName: 'Selected OPS', type: 'string', width: 150 },
  { field: 'createdAt', headerName: 'Created at', type: 'string', width: 150 },
  {
    field: 'lastloginAt',
    headerName: 'Last login at',
    type: 'string',
    width: 150,
  },
];

export const UserTable = () => {
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const users = useQuery('users', () => getUsers(), {
    ...QUERY_CONFIG,
  });

  if (!users.data) {
    return <div>Loading</div>;
  }
  const usersArr: User[] = users?.data.length ? users.data : [];
  return (
    <Grid item xs={12}>
      <Typography variant="h6">Users</Typography>
      <div style={{ height: 1600, width: '100%' }}>
        <DataGrid
          sortModel={[
            {
              field: 'id',
              sort: 'asc',
            },
          ]}
          rows={usersArr.map((u) => ({
            ...u,
            createdAt: format(parseISO(u.createdAt), DAYFORMAT),
            lastloginAt: u.lastloginAt
              ? format(parseISO(u.lastloginAt), DAYFORMAT)
              : null,
          }))}
          columns={columns}
          pageSize={50}
          onRowClick={(evt) => {
            setSelectedUser(evt.row as User);
            setUserDialogOpen(true);
          }}
        />
        <UserDialog
          open={userDialogOpen}
          onClose={() => setUserDialogOpen(false)}
          user={selectedUser}
        />
      </div>
    </Grid>
  );
};
