import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import { LinkItem } from '../../../routes/ops/components/types/types';

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 24px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 16px;
      padding: 16px;
    }
  }
`;

export const EditLinkDialog = ({
  open,
  onClose,
  title,
  setEditItem,
  editItem,
  descriptions,
  onUpdateLink,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  setEditItem: Function;
  editItem: LinkItem | undefined;
  descriptions: { code: string; description: string }[];
  onUpdateLink: (
    title: string,
    url: string,
    description: string,
    bulletpointId: string
  ) => void;
}) => (
  <StyledDialog
    open={open}
    // @ts-ignore
    onClose={onClose}
    onClick={(e) => {
      e.stopPropagation();
    }}
    maxWidth="md"
  >
    <DialogTitle color="primary">Edit link</DialogTitle>
    <DialogContent>
      <Typography variant="body2">{title}</Typography>
      {editItem?.bulletpointId ? (
        <ul style={{ padding: '0 0 0 16px', margin: '0px' }}>
          <li>
            <Typography variant="caption">
              {/* eslint-disable-next-line max-len */}
              {
                descriptions.find((d) => d.code === editItem?.bulletpointId)
                  ?.description
              }
            </Typography>
          </li>
        </ul>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={!editItem?.private}
                onChange={() =>
                  setEditItem({
                    ...editItem,
                    private: !editItem?.private,
                  })
                }
                name="publicLink"
              />
            }
            label={
              <Typography variant="caption">
                Show link to other users
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <TextField
            label="Title"
            variant="outlined"
            defaultValue={editItem?.title}
            onChange={(e) => {
              setEditItem({
                ...editItem,
                title: e.target.value,
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Web address"
            placeholder="http://www.google.com"
            variant="outlined"
            defaultValue={editItem?.link}
            onChange={(e) => {
              setEditItem({
                ...editItem,
                link: e.target.value,
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            variant="outlined"
            defaultValue={editItem?.description}
            onChange={(e) => {
              setEditItem({
                ...editItem,
                description: e.target.value,
              });
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button
        onClick={() => {
          onClose();
          editItem &&
            onUpdateLink(
              editItem.title,
              editItem.link.startsWith('http://') ||
                editItem.link.startsWith('https://')
                ? editItem.link
                : `https://${editItem.link}`,
              editItem.description,
              editItem.bulletpointId
            );
        }}
        variant="contained"
        color="primary"
        autoFocus
        startIcon={<EditIcon />}
      >
        Edit
      </Button>
    </DialogActions>
  </StyledDialog>
);
