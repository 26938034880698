/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { Grid, Tabs, Tab, Divider, Box } from '@material-ui/core';
import { useQuery } from 'react-query';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { selectedSkillCodes } from './state';
import { useFavoriteSubjects } from '../../features/favorites/hooks/useFavoriteSubjects';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { getSubjectsByGrade } from '../ops/utils/getSubjectsByGrade';
import { parseSubjectList } from '../ops/utils/utils';
import { getCachedOps } from '../../api/opsCacher';
import { MyPlanContentPage } from './MyPlanContentPage';
import { MyPlanSubjectPage } from './MyPlanSubjectPage';
import { MyPlanCalendarPage } from './MyPlanCalendarPage';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { useSelectedGroup } from '../../hooks/useSelectedGroup';

export const StickyHeader = styled(Grid)`
  top: 56px;
  position: sticky;
  background-color: ${(p) => p.theme.palette.background.default};
  z-index: 100;
`;

/**
 * Tabs for the page.
 *
 * These are separated from the main page so that we don't have to add recoil state to the main page,
 * which would cause MyPlanPage to re-render
 *
 * For some reason sub pages, including MyPlanSubjectPage initialize every time MyPlanPage re-renders.
 * This causes MyPlanSubjectPage card expanded state to reset, and thus every time user would add a skill code,
 * every card would collapse.
 *
 * When useRecoilState is declared here, only tabs component will re-render
 */
const TabBall = styled.div`
  background-color: #f5d93f;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: -4px;
  margin-left: 4px;
`;

const MyPlanTabs = ({ currentTab }: { currentTab: string }) => {
  const [addedSkillCodes] = useRecoilState(selectedSkillCodes);
  const history = useHistory();
  return (
    <Tabs
      style={{ flex: 1 }}
      value={currentTab}
      onChange={
        (_, _tab) => history.replace(`/my-plan/${_tab}`)
        // eslint-disable-next-line
      }
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab value="content" label="Content" style={{ flex: 1 }} />
      <Tab
        value="calendar"
        label={
          <Box display="flex" alignItems="center">
            Calendar{' '}
            {addedSkillCodes.length > 0 ? (
              <TabBall>{addedSkillCodes.length}</TabBall>
            ) : null}
          </Box>
        }
        style={{ flex: 1 }}
      />
    </Tabs>
  );
};

export const MyPlanPage = ({
  location,
  match,
}: RouteComponentProps<{ tab: string }>) => {
  const group = useSelectedGroup();
  const { favoriteSubjects } = useFavoriteSubjects(group?.groupId);
  const { data: ops } = useQuery('ops', () => getCachedOps(), QUERY_CONFIG);

  const { tab } = match.params;
  if (!ops) {
    return <div>Loading ops</div>;
  }

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: true }, favoriteSubjects)
  );

  const allSubjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: false })
  );

  return (
    <>
      <StickyHeader container>
        <Route path="/my-plan/:tab" exact>
          <Breadcrumbs
            currentPage={tab === 'content' ? 'Content' : 'Calendar'}
            previousPages={[]}
          />
        </Route>
        <Route
          path="/my-plan/content/:subjectCode/grade/:grade"
          component={(
            props: RouteComponentProps<{
              subjectCode: string;
              grade: string;
            }>
          ) => {
            const { subjectCode, grade } = props.match.params;
            return (
              <Breadcrumbs
                currentPage={`${
                  subjects.find((subject) => subject.code === subjectCode)
                    ?.title
                } ${grade}`}
                previousPages={[
                  {
                    title: 'Content',
                    path: '/my-plan/content',
                  },
                ]}
              />
            );
          }}
        />
        <Grid
          container
          style={{ marginBottom: '16px', position: 'sticky', top: '40px' }}
        >
          <MyPlanTabs currentTab={tab} />
          <Divider />
        </Grid>
      </StickyHeader>
      <Route
        path="/my-plan/content"
        exact
        component={() => (
          <MyPlanContentPage groupId={group?.groupId} subjects={subjects} />
        )}
      />
      <Route
        path="/my-plan/calendar"
        component={() => (
          <MyPlanCalendarPage
            groupId={group?.groupId}
            allSubjects={allSubjects}
            favoriteSubjects={subjects}
          />
        )}
        exact
      />
    </>
  );
};
