/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubjectItem } from '../components/types/types';

export const getFavoriteCountForGrades = (
  subjectList: Map<String, SubjectItem>,
  favoriteSubjects: string[] = []
) => {
  const subjects = Array.from(subjectList.values());
  return [1, 2, 3, 4, 5, 6].map((grade) => {
    const count = subjects.filter((_subject) =>
      (_subject.grades || []).some(
        (_grade) =>
          _grade.grade === grade &&
          (favoriteSubjects || []).includes(`${_subject.code}-${grade}`)
      )
    ).length;

    return count;
  });
};
