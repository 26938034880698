import { Button, Card, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { getGroups } from '../../api/groupapi';
import { Group } from '../../features/app-base/Group';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { StickyHeader } from '../my-plan/MyPlanPage';
import { GroupItem } from './GroupItem';
import { AddGroupDialog } from './AddGroupDialog';

export const GroupsPage = ({ match }: RouteComponentProps<{ tab: string }>) => {
  const [addGroupOpen, setAddGroupOpen] = React.useState(false);
  const groupsResponse = useQuery({
    queryKey: ['groupapi'],
    queryFn: getGroups,
    config: QUERY_CONFIG,
  });
  if (!groupsResponse.data) {
    return <div>Loading</div>;
  }
  // @ts-ignore
  const groups: Group[] = groupsResponse?.data?.length
    ? groupsResponse.data
    : [];

  return (
    <>
      <AddGroupDialog
        open={addGroupOpen}
        onClose={() => setAddGroupOpen(false)}
      />
      <StickyHeader container>
        <Breadcrumbs currentPage="Groups" previousPages={[]} />
      </StickyHeader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant="outlined" style={{ padding: '25px' }}>
            <Typography variant="h4">My groups</Typography>
            <Divider />
            <Button
              style={{ marginTop: '25px' }}
              variant="contained"
              color="primary"
              onClick={() => setAddGroupOpen(true)}
            >
              Add new group
            </Button>
          </Card>
        </Grid>
        <Grid container spacing={2}>
          {groups.map((group) => (
            <GroupItem group={group} key={group.groupId} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};
