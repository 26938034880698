import * as t from './actionTypes';
import { Action } from '../../common/types/types';

type State = {
  loginRunning: boolean;
  loginResultCode?: number | null;
};

export const initialState = {
  loginRunning: false,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case t.LOGIN: {
      return {
        ...state,
        loginRunning: true,
        loginResultCode: null,
      };
    }
    case t.LOGIN_FINISH: {
      return {
        ...state,
        loginRunning: false,
        loginResultCode: action.payload,
      };
    }
    default:
      return state;
  }
};
