import styled from 'styled-components';

export const Ball = (height: string, width: string) => styled.div`
  border: 1px solid ${(p) => p.theme.palette.text.secondary};
  color: ${(p) => p.theme.palette.text.secondary};
  border-radius: 50%;
  min-height: ${height};
  min-width: ${width};
  max-height: ${height};
  max-width: ${width};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const SmallLinkBall = Ball('20px', '20px');

export const MediumLinkBall = Ball('34px', '34px');

export const ContentBall = Ball('34px', '34px');

export const ObjectiveBall = Ball('44px', '44px');

export const BulletpointBall = Ball('20px', '20px');
