import {
  USERS_API_URL as baseUrl,
  FAVORITES_API_URL as favoritesUrl,
} from '../routes/constants';
import { UserDetailType } from '../routes/users/enums';
import { apiGet, apiPost, apiPut, apiDelete } from './genericapi';

export const get = <T>(id?: number): Promise<T> => apiGet(baseUrl, id);

export const create = <T, R>(payload: T): Promise<R> =>
  apiPost(baseUrl, payload);

export const update = <T, R>(id: number, payload: T): Promise<R> =>
  apiPut(baseUrl, payload, id);

export const remove = (id: number): Promise<Number> => apiDelete(baseUrl, id);

type UserDetailResponse = {
  type: UserDetailType;
  value: string;
};

export const getFavorites = (groupId: string | undefined): Promise<string[]> =>
  apiGet(`${favoritesUrl}?groupId=${groupId}`);

export const putFavorites = <R>(
  payload: string[] | undefined,
  groupId: string | undefined
): Promise<R> => apiPut(`${favoritesUrl}?groupId=${groupId}`, payload);

export const getDetail = (detailId: string): Promise<UserDetailResponse> =>
  apiGet(`${baseUrl}/details/${detailId}`);

export const updateDetail = <T, R>(detailId: string, payload: T): Promise<R> =>
  apiPut(`${baseUrl}/details/${detailId}`, payload);
