import { Grid } from '@material-ui/core';
import { addDays, format, getDay, getISODay, parse } from 'date-fns';
import * as React from 'react';
import { useQuery } from 'react-query';
import * as contentProgressApi from '../../api/contentprogressapi';
import { WeekdayPlanCard } from '../../features/my-plan/WeekdayPlanCard';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { Week } from '../../utils/weekhelpers';
import { SubjectItem } from '../ops/components/types/types';
import { SimpleSubject } from './helpers';

type Props = {
  groupId: string | undefined;
  subjects: SubjectItem[];
  weekdays: string[];
  week: Week;
  onlyDate?: Date;
  showLinks: boolean;
  showDelete: boolean;
  disabledSubjects?: SimpleSubject[];
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const fetchContentProgress = (key: string, obj: any) =>
  contentProgressApi.get(obj.groupId, obj.startDate, obj.endDate);

const weekDayToDate = (
  weekday: string,
  week: Week,
  weekdays: string[]
): string => {
  let date = '';
  for (let i = 0; i < 7; i++) {
    if (
      getISODay(addDays(week.startDate, i)) ===
      weekdays.indexOf(weekday) + 1
    ) {
      const d = addDays(week.startDate, i);
      date = format(d, 'dd');
    }
  }
  return date;
};

export const WeekdayView = ({
  groupId,
  subjects,
  weekdays,
  week,
  onlyDate,
  showLinks,
  showDelete,
  disabledSubjects,
}: Props) => {
  const contentProgress = useQuery({
    queryKey: [
      'contentprogressapi',
      {
        groupId,
        startDate: format(week.startDate, 'yyyy-MM-dd'),
        endDate: format(week.endDate, 'yyyy-MM-dd'),
      },
    ],
    queryFn: fetchContentProgress,
    config: { ...QUERY_CONFIG, enabled: !!groupId },
  });

  if (!contentProgress.data) {
    return <div>Loading</div>;
  }

  // @ts-ignore
  const contentProgressData: ContentProgress[] = contentProgress?.data?.length
    ? contentProgress.data
    : [];

  return (
    <Grid container spacing={2}>
      {weekdays
        .filter(
          (w) => !onlyDate || getISODay(onlyDate) === weekdays.indexOf(w) + 1
        )
        .map((weekday: string) => (
          <WeekdayPlanCard
            showLinks={showLinks}
            subjects={subjects}
            key={weekday}
            weekday={weekday}
            contentProgress={contentProgressData
              .filter((cp) => {
                if (!disabledSubjects) {
                  return true;
                }
                return !disabledSubjects.find(
                  (sc) =>
                    sc.subjectId === cp.subjectId &&
                    `${sc.gradeId}` === cp.gradeId
                );
              })
              .filter(
                (c) =>
                  getISODay(parse(c.plannedAt, 'yyyy-MM-dd', new Date())) ===
                  weekdays.indexOf(weekday) + 1
              )}
            showDelete={showDelete}
            date={weekDayToDate(weekday, week, weekdays)}
          />
        ))}
    </Grid>
  );
};
