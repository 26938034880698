import React from 'react';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getCachedOps } from '../../../../api/opsCacher';
import { parseSubjectList } from '../../../ops/utils/utils';
import { getSubjectsByGrade } from '../../../ops/utils/getSubjectsByGrade';
import { ObjectiveCard } from '../../../../features/curriculum/components/ObjectiveCard';
import { parseNumber } from '../../../../utils/string';
import { QUERY_CONFIG } from '../../../../utils/queryconfig';

export const ReadObjective = ({ location, match }: RouteComponentProps<{}>) => {
  // @ts-ignore
  const { subjectCode, grade: gradeStr } = match.params;
  const grade = Number(gradeStr);
  const { data: ops } = useQuery('ops', () => getCachedOps(), QUERY_CONFIG);

  if (!ops) {
    return <div>Loading ops</div>;
  }

  if (!grade) {
    return <div> No grade selected </div>;
  }

  const rawSubjects = parseSubjectList(ops, { onlyFavorites: false }, []);
  const subject = getSubjectsByGrade(rawSubjects, grade).find(
    (it) => it.code === subjectCode
  );

  if (!subject) {
    return (
      <div>
        No objectives found for grade: {grade} and subject code: {subjectCode}
      </div>
    );
  }

  const objectives = (subject.grades || []).find(
    (it) => it.grade === grade
  )?.objectives;

  if (!objectives) {
    return null;
  }

  const parseObjectiveNumber = (code: string) => parseNumber(code) ?? 0;

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {objectives
          .sort(
            (a, b) =>
              parseObjectiveNumber(a.code) - parseObjectiveNumber(b.code)
          )
          .map((it) => (
            <ObjectiveCard
              gradeId={gradeStr}
              objective={it}
              key={it.code}
              subjectCode={subject.code}
              hideLinkButtons={true}
            />
          ))}
      </Grid>
    </Grid>
  );
};
