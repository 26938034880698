/* eslint-disable react/no-array-index-key */
import {
  Box,
  Card,
  Collapse,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { SubjectItem } from '../../routes/ops/components/types/types';
import { ContentProgress } from '../../routes/my-plan/types';
import { CalendarBulletpointRow } from './CalendarBulletpointRow';
import { parseNumber } from '../../utils/string';
import { sortOnlyByNumber } from '../../routes/ops/utils/utils';
import { SubjectCardHeaderBox } from '../../components/SubjectCardHeaderBox';
import { findSubjectAbbreviation } from '../../utils/findSubjectAbbreviation';

type Props = {
  subjects: SubjectItem[];
  weekday: string;
  contentProgress: ContentProgress[];
  showLinks: boolean;
  showDelete: boolean;
  date: string;
};

type SubjectBoxProps = {
  subjects: SubjectItem[];
  contentProgress: ContentProgress[];
  showLinks: boolean;
  showDelete: boolean;
};

const findObjectiveDesc = (
  cp: ContentProgress,
  subjects: SubjectItem[]
): string => {
  if (!subjects) return '';
  // @ts-ignore
  return subjects
    .find((s) => s.code === cp.subjectId)!!
    .grades.find((g) => g.grade === parseNumber(cp.gradeId))!!
    .objectives.find((o) => o.code === cp.objectiveId).name;
};

const SubjectBox = ({
  subjects,
  contentProgress,
  showLinks,
  showDelete,
}: SubjectBoxProps) => {
  const [expanded, setExpanded] = React.useState(true);
  const objectiveMap = new Map<string, ContentProgress[]>();
  contentProgress.forEach((c) => {
    if (objectiveMap.get(c.objectiveId)) {
      const existing: ContentProgress[] = objectiveMap.get(c.objectiveId)!!;
      objectiveMap.set(c.objectiveId, [...existing, c]);
    } else {
      objectiveMap.set(c.objectiveId, [c]);
    }
  });
  const objectiveKeys = Array.from(new Set(objectiveMap.keys()));
  return (
    <Card style={{ marginTop: '16px' }} variant="outlined">
      <Box
        display="flex"
        style={{ cursor: 'pointer' }}
        onClick={() => setExpanded(!expanded)}
      >
        <SubjectCardHeaderBox
          subjectAbbreviation={findSubjectAbbreviation(
            contentProgress[0].subjectId,
            subjects
          )}
          grade={contentProgress[0].gradeId}
        />
        <Box flex={1} p={1}>
          {!expanded &&
            objectiveKeys
              .sort((a, b) => parseNumber(a)!! - parseNumber(b)!!)
              .map((it, index) => (
                <Box
                  style={{ display: 'inline-block', marginTop: '-4px' }}
                  key={index}
                >
                  {/* eslint-disable-next-line no-shadow */}
                  <div style={{ padding: '5px' }}>
                    <Typography variant="h6" color="primary">
                      {it}
                    </Typography>
                  </div>
                </Box>
              ))}
        </Box>
      </Box>
      <Collapse in={expanded}>
        <Grid container spacing={2} style={{ height: '100%', padding: '2px' }}>
          <Grid item xs={12}>
            {objectiveKeys
              .sort((a, b) => sortOnlyByNumber(a, b))
              .map((objectiveKey) => (
                <div key={objectiveKey}>
                  <div style={{ padding: '0 0 0 8px' }}>
                    <Typography
                      variant="h6"
                      color="primary"
                      style={{ width: '5%' }}
                    >
                      {objectiveKey}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        marginTop: '-28px',
                        marginLeft: '25px',
                        padding: '0 0 0 16px',
                      }}
                    >
                      {findObjectiveDesc(
                        objectiveMap.get(objectiveKey)!![0],
                        subjects
                      )}
                    </Typography>
                  </div>
                  <ul style={{ padding: '0 0 0 20px' }}>
                    {objectiveMap.get(objectiveKey)?.map((it, index) => (
                      <CalendarBulletpointRow
                        key={index}
                        contentProgress={it}
                        subjects={subjects}
                        showLinks={showLinks}
                        showDelete={showDelete}
                      />
                    ))}
                  </ul>
                </div>
              ))}
          </Grid>
        </Grid>
      </Collapse>
    </Card>
  );
};

export const WeekdayPlanCard = ({
  subjects,
  weekday,
  contentProgress,
  showLinks,
  showDelete,
  date,
}: Props) => {
  const contentProgressMap = new Map<string, ContentProgress[]>();
  contentProgress.forEach((c) => {
    if (contentProgressMap.get(c.subjectId)) {
      const existing: ContentProgress[] = contentProgressMap.get(c.subjectId)!!;
      contentProgressMap.set(c.subjectId, [...existing, c]);
    } else {
      contentProgressMap.set(c.subjectId, [c]);
    }
  });
  return (
    <Grid item xs={12} md={6} lg={4} key={weekday}>
      <Card variant="outlined">
        <Box display="flex" flexDirection="column" p={3}>
          <Typography variant="h6">
            {weekday} {date}.
          </Typography>
          <Divider />
          {Array.from(contentProgressMap.keys()).map((val) => (
            <SubjectBox
              key={val}
              subjects={subjects}
              contentProgress={contentProgressMap.get(val)!!}
              showLinks={showLinks}
              showDelete={showDelete}
            />
          ))}
        </Box>
      </Card>
    </Grid>
  );
};
