/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import * as React from 'react';
import { queryCache, useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { getGroups, updateGroupSelection } from '../../api/groupapi';
import { QUERY_CONFIG } from '../../utils/queryconfig';

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 24px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 16px;
      padding: 16px;
    }
  }
`;

export const GroupSelectionDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const groupsResponse = useQuery({
    queryKey: ['groupapi'],
    queryFn: getGroups,
    config: QUERY_CONFIG,
  });
  const [updateSelectedGroup] = useMutation(updateGroupSelection, {
    onSuccess: () => {
      queryCache.invalidateQueries();
      window.location.href = `${process.env.REACT_APP_BASE_URL}/home/today`;
    },
  });
  if (!groupsResponse.data) {
    return <div>Loading</div>;
  }
  // @ts-ignore
  const groups: Group[] = groupsResponse?.data?.length
    ? groupsResponse.data
    : [];

  const selectedGroup = groups.filter((f) => f.selected === true)[0];
  return (
    <StyledDialog
      open={open}
      // @ts-ignore
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      maxWidth="md"
    >
      <DialogTitle color="primary">Select teaching group</DialogTitle>
      <Divider />
      <DialogContent>
        {groups.map((g) => (
          <div
            style={{
              padding: '8px',
              borderRadius: '8px',
              border: '1px solid',
              marginTop: '8px',
              marginBottom: '8px',
              cursor: 'pointer',
            }}
            onClick={() => updateSelectedGroup(g.groupId)}
            key={g.groupId}
          >
            {g.name}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          style={{ backgroundColor: '#245f73', color: 'white' }}
          onClick={onClose}
        >
          Continue with {selectedGroup.name}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
