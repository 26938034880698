import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import { LinkItem } from '../../../routes/ops/components/types/types';
import { LinkRow } from './LinkRow';
import { SmallButton } from './SmallButton';

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    min-width: 400px;
    padding: 24px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 16px;
    }
  }
`;

export const ViewLinksDialog = ({
  open,
  onClose,
  title,
  descriptions,
  links,
  onEditLink,
  onRemoveLink,
  onInfoLink,
  onAddLinkOpen,
  onLinksDialogOpen,
  selectedBulletpoint,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  descriptions: { code: string; description: string }[];
  links: LinkItem[];
  onEditLink: (link: LinkItem) => void;
  onRemoveLink: (linkId: number, bulletpointId: string) => void;
  onInfoLink: (link: LinkItem) => void;
  onAddLinkOpen: (open: boolean) => void;
  onLinksDialogOpen: (open: boolean) => void;
  selectedBulletpoint: string | undefined;
}) => (
  <StyledDialog
    open={open}
    // @ts-ignore
    onClose={onClose}
    onClick={(e) => e.stopPropagation()}
    maxWidth="md"
  >
    <DialogTitle color="primary">Links</DialogTitle>
    <Divider />
    <DialogContent style={{ paddingBottom: '12px' }}>
      <ul style={{ padding: '0 0 0 0px', margin: '0px' }}>
        {descriptions
          .filter((desc) => desc.code === selectedBulletpoint)
          .filter(
            (d) => links.filter((l) => l.bulletpointId === d.code).length > 0
          )
          .map((description) => (
            <li key={description.code}>
              <Typography variant="caption">
                {' '}
                {description.description}
              </Typography>
              <ul style={{ padding: '0 0 0 16px', margin: '0px' }}>
                {links
                  .filter((l) => l.bulletpointId === description.code)
                  .map((it) => (
                    <LinkRow
                      key={it.bulletpointId + Math.random()}
                      link={it}
                      onRemoveLink={onRemoveLink}
                      onEditLink={onEditLink}
                      onInfoLink={onInfoLink}
                    />
                  ))}
              </ul>
            </li>
          ))}
      </ul>
    </DialogContent>
    <Divider />
    <DialogActions>
      <SmallButton
        style={{ padding: '4px', marginRight: '4px' }}
        variant="outlined"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onAddLinkOpen(true);
        }}
      >
        Add link
      </SmallButton>
      <SmallButton
        style={{ padding: '4px', marginRight: '4px' }}
        variant="outlined"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onLinksDialogOpen(false);
        }}
      >
        Close
      </SmallButton>
    </DialogActions>
  </StyledDialog>
);
