import React from 'react';
import { useIntl } from 'react-intl';

type PageTitleProps = {
  title: string;
};

export const PageTitle = ({ title }: PageTitleProps) => {
  const intl = useIntl();

  return <h1>{intl.formatMessage({ id: title })}</h1>;
};
