import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as a from './actions';
import * as s from './selectors';
import { OPSPage } from './components/OPSPage';

const mapStateToProps = createStructuredSelector({
  item: s.getLSItem,
  selectedSubject: s.getSelectedSubject,
  selectedGrade: s.getSelectedGrade,
  favoriteSubjects: s.getFavoriteSubjects,
  opsFilter: s.getOPSFilter,
  links: s.getLinks,
  selectedTransversalsAndContents: s.getSelectedTransversalsAndContents,
});

const actionCreators = {
  retrieveItem: a.retrieveItem,
  selectSubject: a.selectSubject,
  toggleFavoriteSubject: a.toggleFavoriteSubject,
  retrieveFavorites: a.retrieveFavorites,
  updateOPSFilter: a.updateOPSFilter,
  searchLink: a.searchLink,
  toggleTransversalOrContentSelected: a.toggleTransversalOrContentSelected,
  retrieveSelectedTransversalsAndContents:
    a.retrieveSelectedTransversalsAndContents,
  submitEditLink: a.submitEditLink,
};

export const OPSPageContainer = compose(
  connect(mapStateToProps, actionCreators),
  withRouter
)(OPSPage);
