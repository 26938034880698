import { combineReducers } from 'redux';
import appReducer from '../App/reducer';
import usersReducer from '../routes/users/reducer';
import registerReducer from '../routes/register/reducer';
import loginReducer from '../routes/login/reducer';
import opsReducer from '../routes/ops/reducer';

export default combineReducers({
  appReducer,
  usersReducer,
  registerReducer,
  loginReducer,
  opsReducer,
});
