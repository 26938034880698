import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { subjectShortForm } from '../utils/subjectShortForm';

interface Props {
  subjectAbbreviation: string;
  grade: string | number;
}

export const SubjectCardHeaderBox = ({ subjectAbbreviation, grade }: Props) => (
  <Box
    bgcolor="primary.main"
    color="white"
    p={2}
    style={{
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '5%',
      alignItems: 'center',
    }}
  >
    <Typography style={{ whiteSpace: 'nowrap' }} variant="body2">
      {subjectShortForm(subjectAbbreviation, grade)}
    </Typography>
  </Box>
);
