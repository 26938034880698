/* eslint-disable no-console */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import { useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import { Group } from '../../features/app-base/Group';
import * as groupsApi from '../../api/groupapi';

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 24px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 16px;
      padding: 16px;
    }
  }
`;

export const InviteStudentsDialog = ({
  open,
  onClose,
  group,
}: {
  open: boolean;
  onClose: () => void;
  group: Group;
}) => {
  const [inviteStudents] = useMutation(groupsApi.inviteStudentsToGroup, {
    onSuccess: () => {
      queryCache.invalidateQueries();
      onClose();
    },
  });
  const [emails, setEmails] = useState('');
  return (
    <StyledDialog
      open={open}
      // @ts-ignore
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle color="primary">Invite students to {group.name}</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: '8px' }}>
            <Typography style={{ marginBottom: '8px' }} variant="caption">
              Invite one or many students to group. Separate emails with ; when
              inviting more than one user.
            </Typography>
            <TextField
              label={<Typography variant="caption">Emails</Typography>}
              variant="outlined"
              onChange={(evt) => setEmails(evt.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          autoFocus
          startIcon={<AddIcon />}
          onClick={() =>
            inviteStudents({
              groupId: group.groupId,
              emails: emails.split(';'),
            })
          }
        >
          Invite
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
