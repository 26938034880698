/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { Grid, Tabs, Tab, Divider } from '@material-ui/core';
import { useQuery } from 'react-query';
import { Route, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { SelectSubjects } from './select-tab/SelectSubjects';
import { useFavoriteSubjects } from '../../../features/favorites/hooks/useFavoriteSubjects';
import { GradeSelectChip } from '../../../features/curriculum/components/GradeSelectChip';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { getSubjectsByGrade } from '../../ops/utils/getSubjectsByGrade';
import { parseSubjectList } from '../../ops/utils/utils';
import { getFavoriteCountForGrades } from '../../ops/utils/getFavoriteCountForGrades';
import { getCachedOps } from '../../../api/opsCacher';
import { SelectObjective } from './view-tab/SelectObjective';
import { ReadObjective } from './view-tab/ReadObjective';
import { QUERY_CONFIG } from '../../../utils/queryconfig';
import { useSelectedGroup } from '../../../hooks/useSelectedGroup';

const StickyHeader = styled(Grid)`
  top: 56px;
  position: sticky;
  background-color: ${(p) => p.theme.palette.background.default};
  z-index: 100;L
`;

export const CurriculumSubjectsPage = ({
  location,
  match,
  history,
}: RouteComponentProps<{ tab: string; grade: string }>) => {
  const group = useSelectedGroup();
  const { favoriteSubjects, toggleFavoriteSubject } = useFavoriteSubjects(
    group?.groupId
  );
  const { data: ops } = useQuery('ops', () => getCachedOps(), QUERY_CONFIG);
  const { tab, grade: gradeStr } = match.params;
  const grade = Number(gradeStr);

  if (!ops) {
    return <div>Loading ops</div>;
  }

  const rawSubjects = parseSubjectList(
    ops,
    { onlyFavorites: false },
    favoriteSubjects
  );

  const subjects = getSubjectsByGrade(rawSubjects, grade);

  const favoriteCountForGrades = getFavoriteCountForGrades(
    rawSubjects,
    favoriteSubjects
  );

  return (
    <>
      <StickyHeader container>
        <Route path="/curriculum/subjects/:tab/:grade" exact>
          <Breadcrumbs
            currentPage="Subjects"
            previousPages={[{ title: 'Curriculum', path: '/curriculum' }]}
          />
        </Route>
        <Route
          path="/curriculum/subjects/:tab/:grade/objectives/:subjectId"
          // @ts-ignore
          component={(
            props: RouteComponentProps<{
              tab: string;
              grade: string;
              subjectId: string;
            }>
          ) => (
            <Breadcrumbs
              currentPage={
                subjects.find(
                  (it) => it.code === props.match?.params?.subjectId
                )?.title || ''
              }
              previousPages={[
                { title: 'Curriculum', path: '/curriculum' },
                {
                  title: 'Subjects',
                  path: `/curriculum/subjects/${tab}/${grade}`,
                },
              ]}
            />
          )}
        />
        <Grid
          container
          style={{ marginBottom: '16px', position: 'sticky', top: '40px' }}
        >
          <Tabs
            style={{ flex: 1 }}
            value={tab}
            onChange={
              (_, _tab) =>
                history.replace(`/curriculum/subjects/${_tab}/${grade}`)
              // eslint-disable-next-line
            }
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab value="select" label="Select" style={{ flex: 1 }} />
            <Tab value="read" label="Read" style={{ flex: 1 }} />
          </Tabs>
          <Divider />
        </Grid>
        <Grid
          container
          justify="center"
          spacing={2}
          style={{ paddingBottom: '16px' }}
        >
          {[1, 2, 3, 4, 5, 6].map((it, index) => (
            <GradeSelectChip
              key={it}
              grade={it}
              onClick={() => {
                history.replace(
                  location.pathname.match(`/${grade}/`)
                    ? location.pathname.replace(`/${grade}/`, `/${it}/`)
                    : location.pathname.replace(
                        new RegExp(`/${grade}$`),
                        `/${it}`
                      )
                );
              }}
              selected={grade === it}
              subjectCount={favoriteCountForGrades[index]}
            />
          ))}
        </Grid>
      </StickyHeader>
      <Route
        path="/curriculum/subjects/select/:grade"
        exact
        component={() => (
          <SelectSubjects
            grade={grade}
            subjects={subjects}
            toggleFavoriteSubject={toggleFavoriteSubject}
            favoriteSubjects={favoriteSubjects}
          />
        )}
      />
      <Route
        path="/curriculum/subjects/read/:grade"
        exact
        component={() => (
          <SelectObjective
            grade={grade}
            subjects={subjects}
            favoriteSubjects={favoriteSubjects}
          />
        )}
      />
      <Route
        path="/curriculum/subjects/read/:grade/objectives/:subjectCode"
        component={ReadObjective}
        exact
      />
    </>
  );
};
