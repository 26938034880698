import { NAME, LS_KEY_OPS } from './constants';
import { State } from '../../common/types/types';

export const getItem = <T>(state: State): T => state[NAME].item;
export const getLSItem = () => {
  const item = localStorage.getItem(LS_KEY_OPS);
  return item ? JSON.parse(item) : item;
};
export const getSelectedSubject = <T>(state: State): T =>
  state[NAME].selectedSubject;
export const getSelectedGrade = <T>(state: State): T =>
  state[NAME].selectedGrade;
export const getFavoriteSubjects = <T>(state: State): T =>
  state[NAME].favoriteSubjects;
export const getOPSFilter = <T>(state: State): T => state[NAME].opsFilter;
export const getLinks = <T>(state: State): T => state[NAME].links;
export const getSelectedTransversalsAndContents = <T>(state: State): T =>
  state[NAME].selectedTransversalsAndContents;
