import React from 'react';
import { Button, Card, Paper, Snackbar, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import MuiAlert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet';
import { GenericTextField } from '../../../common/components/form/input/GenericTextField';
import { ProgressIndicator } from '../../../common/components/ProgressIndicator';
import { PageTitle } from '../../../common/components/page/PageTitle';
import { PageContainer } from '../../../common/components/PageContainer';
import { validateFormValues } from './validator';
import { UserLoginType } from '../types';

export const StyledPaper = styled(Paper)`
  margin: 0 auto;
  height: 75vh;
`;

const StyledForm = styled.form`
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  margin-top: 2em;
`;

const AccountsContainer = styled.div`
  margin-top: 2em;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Alert = (props: any) => (
  <MuiAlert elevation={6} variant="filled" {...props} />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LoginPage = ({ login, loginResultCode }: any) => {
  const intl = useIntl();
  const history = useHistory();
  if (loginResultCode === 200) {
    history.push('/ops');
    window.location.reload(false);
  }

  const onSubmit = (values: UserLoginType) => {
    login({
      username: values.username,
      password: values.password,
    });
  };

  return (
    <>
      <Helmet>
        <meta name="description" content="" />
        <title>
          {intl.formatMessage({ id: 'application.title' })} -{' '}
          {intl.formatMessage({ id: 'loginPage.table.title' })}
        </title>
      </Helmet>
      <PageContainer>
        <h1 style={{ textAlign: 'center' }}>Log in</h1>
        <Snackbar open={loginResultCode && loginResultCode !== 200}>
          <Alert severity="error">
            {intl.formatMessage({ id: 'loginPage.snackbar.message.failed' })}
          </Alert>
        </Snackbar>
        {/*          <Form
            onSubmit={onSubmit}
            validate={validateFormValues}
            render={({ handleSubmit, submitting }) => (
              <StyledForm onSubmit={handleSubmit} noValidate>
                {submitting && <ProgressIndicator />}
                <GenericTextField
                  fieldName="username"
                  fieldValue=""
                  fieldTitle={intl.formatMessage({
                    id: 'loginPage.table.username',
                  })}
                  fieldRequired={true}
                  autoFocus={true}
                />
                <br />
                <GenericTextField
                  fieldName="password"
                  fieldType="password"
                  fieldValue=""
                  fieldTitle={intl.formatMessage({
                    id: 'loginPage.table.password',
                  })}
                  fieldRequired={true}
                />
                <br />

                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {intl.formatMessage({ id: 'form.login' })}
                </StyledButton>
              </StyledForm>
            )}
          />
          <Link to="/register">
            {intl.formatMessage({ id: 'loginPage.link.signup' })}
          </Link> */}
        <AccountsContainer>
          {/*            <Link to="/auth/azure">Azure login</Link> */}
          <Link to="/auth/google">
            <Button fullWidth color="primary" variant="contained">
              Log in
            </Button>
          </Link>
        </AccountsContainer>
      </PageContainer>
    </>
  );
};
