import {
  Box,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@material-ui/core';
import { addDays, format, getISODay } from 'date-fns';
import * as React from 'react';
import { useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { createContentProgress } from '../../api/contentprogressapi';
import { SubjectCardHeaderBox } from '../../components/SubjectCardHeaderBox';
import { SmallButton } from '../../features/curriculum/components/SmallButton';
// eslint-disable-next-line import/named
import { SubjectGridItem } from '../../features/curriculum/components/SubjectGridItem';
import { WeekSelection } from '../../features/my-plan/WeekSelection';
import { findSubjectAbbreviation } from '../../utils/findSubjectAbbreviation';
import { Week } from '../../utils/weekhelpers';
import { SubjectItem } from '../ops/components/types/types';
import { skillCodeToLabel, WEEKDAYS } from './helpers';
import {
  calendarPageShowAllFilter,
  disabledCalendarSubjects,
  selectedSkillCodes,
  selectedStateWeek,
  SkillCode,
} from './state';
/* eslint-disable import/no-cycle */
import { WeekdayView } from './WeekdayView';

type Props = {
  groupId: string | undefined;
  allSubjects: SubjectItem[];
  favoriteSubjects: SubjectItem[];
};

const WeekdaySelect = ({
  onWeekdaySelect,
}: {
  onWeekdaySelect: (weekday: unknown) => void;
}) => {
  const [weekdays, setWeekdays] = useState([]);
  return (
    <FormControl style={{ minWidth: '200px' }}>
      <InputLabel id="demo-simple-select-outlined-label">Weekday</InputLabel>
      <Select
        labelId="Weekday-select"
        id="weekdays"
        multiple
        defaultValue={[]}
        onChange={(evt) => {
          // @ts-ignore
          setWeekdays(evt.target.value);
        }}
        label="Weekday"
        onClose={(evt) => {
          onWeekdaySelect(weekdays);
        }}
      >
        {WEEKDAYS.map((weekday) => (
          <MenuItem key={weekday} value={weekday}>
            {weekday}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const weekDayToString = (weekDay: string, week: Week): string => {
  const weekDayNumber = WEEKDAYS.indexOf(weekDay) + 1;
  let date = week.startDate;
  while (true) {
    if (getISODay(date) === weekDayNumber) {
      break;
    }
    date = addDays(date, 1);
  }
  return format(date, 'yyyy-MM-dd');
};

const createContentProgressApiCallPayload = (
  groupId: string | undefined,
  skillCode: SkillCode,
  weekDay: string,
  week: Week
) => ({
  groupId,
  subjectId: skillCode.subjectId,
  gradeId: skillCode.gradeId,
  objectiveId: skillCode.objectiveId,
  contentId: skillCode.contentId,
  status: 'PLANNED',
  plannedAt: weekDayToString(weekDay, week),
  ...(skillCode.skillCode !== skillCode.contentId
    ? { bulletpointId: skillCode.skillCode }
    : {}),
});

export const MyPlanCalendarPage = ({
  groupId,
  allSubjects,
  favoriteSubjects,
}: Props) => {
  const [addedSkillCodes, setAddedSkillCodes] =
    useRecoilState(selectedSkillCodes);
  const [week, setWeek] = useRecoilState(selectedStateWeek);
  const [disabledSubjects, setDisabledSubjects] = useRecoilState(
    disabledCalendarSubjects
  );

  const [showAll, setShowAll] = useRecoilState(calendarPageShowAllFilter);

  const [createContentProgressMutation] = useMutation(createContentProgress, {
    onSuccess: (response) => {
      queryCache.invalidateQueries('contentprogressapi');
    },
  });

  return (
    <>
      <Grid
        container
        spacing={1}
        justify="center"
        style={{ padding: '0 0 5px 0' }}
      >
        <Grid item xs={3} sm={2} md={1} lg={1}>
          <div
            style={{
              height: '100%',
              width: '100%',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <FormControlLabel
              control={<Switch size="small" checked={showAll} name="showAll" />}
              onChange={() => {
                if (showAll) {
                  setShowAll(false);
                  setDisabledSubjects([
                    ...favoriteSubjects
                      .map(
                        (fs) =>
                          fs.grades?.map((g) => ({
                            gradeId: g.grade,
                            subjectId: fs.code,
                          })) ?? []
                      )
                      .flat(),
                  ]);
                } else {
                  setShowAll(true);
                  setDisabledSubjects([]);
                }
              }}
              label={
                <Typography style={{ whiteSpace: 'nowrap' }} variant="caption">
                  Show all
                </Typography>
              }
            />
          </div>
        </Grid>
        {favoriteSubjects.map((subject) =>
          subject.grades?.map((grade) => (
            <SubjectGridItem
              key={subject.code + grade.grade}
              onClick={() => {
                const filtered = disabledSubjects.filter(
                  (f) =>
                    !(f.subjectId === subject.code && f.gradeId === grade.grade)
                );
                if (filtered.length < disabledSubjects.length) {
                  setDisabledSubjects(filtered);
                  if (filtered.length === 0) {
                    setShowAll(true);
                  }
                } else {
                  setShowAll(false);
                  setDisabledSubjects([
                    ...disabledSubjects,
                    { gradeId: grade.grade, subjectId: subject.code },
                  ]);
                }
              }}
              favorite={false}
              title={`${subject.abbreviation} ${grade.grade}`}
              disabledSubjects={disabledSubjects}
              subject={subject.title}
              smallMode={true}
              grade={grade.grade}
            />
          ))
        )}
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <WeekSelection />
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <Box display="flex" flexDirection="column" p={3}>
              <Typography variant="h6">
                Skills to assign for this week
              </Typography>
              <Divider style={{ marginBottom: '16px' }} />
              {addedSkillCodes.length > 0 ? (
                <Box py={1}>
                  {addedSkillCodes.map((code) => (
                    <Grid container key={code.skillCode} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box display="flex">
                          <SubjectCardHeaderBox
                            subjectAbbreviation={findSubjectAbbreviation(
                              code.subjectId,
                              favoriteSubjects
                            )}
                            grade={code.gradeId}
                          />
                          <Box>
                            <Typography
                              variant="body2"
                              style={{ marginTop: '17px', marginLeft: '2px' }}
                            >
                              {skillCodeToLabel(code.skillCode)}: {code.desc}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <WeekdaySelect
                          onWeekdaySelect={(values: unknown) => {
                            // @ts-ignore
                            values.map((v) => {
                              createContentProgressMutation(
                                createContentProgressApiCallPayload(
                                  groupId,
                                  code,
                                  v,
                                  week
                                )
                              );
                            });
                            // @ts-ignore
                            if (values.length > 0) {
                              setAddedSkillCodes((codes: SkillCode[]) =>
                                codes.filter(
                                  (c) => c.skillCode !== code.skillCode
                                )
                              );
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <SmallButton
                    style={{ marginTop: '25px' }}
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAddedSkillCodes(() => []);
                    }}
                  >
                    Clear selections
                  </SmallButton>
                </Box>
              ) : (
                <Typography variant="body1">
                  No skills selected. Select skills from Content-tab
                </Typography>
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <WeekdayView
            groupId={groupId}
            subjects={allSubjects}
            weekdays={WEEKDAYS}
            week={week}
            showLinks={false}
            showDelete={true}
            disabledSubjects={disabledSubjects}
          />
        </Grid>
      </Grid>
    </>
  );
};
