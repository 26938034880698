// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateRequiredValues = (values: any): Object => {
  const errors = Object();

  Object.keys(values).forEach((field: string) => {
    const value = values[field];
    if (!value) {
      errors[field] = 'Required';
    }
  });

  return errors;
};
