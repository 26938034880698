export const messagesEN = {
  'application.name': 'Curriculum',
  'application.title': 'Curriculum',

  'navigation.register.title': 'Sign up',
  'navigation.login.title': 'Login',
  'navigation.users.title': 'Users',

  'dialog.submit.title.save': 'Save',
  'dialog.confirm.title': 'Confirm',
  'dialog.confirm.description': 'Are you sure?',
  'dialog.button.ok': 'Ok',
  'dialog.button.cancel': 'Cancel',

  'search.startTime': 'From',
  'search.endTime': 'To',

  'footer.itemCount': 'Results',

  'dashboardPage.table.title': "What's new",

  'alert.title.error': 'Error',
  'alert.title.success': 'Success',
  'alert.message.fetch': 'Listing',
  'alert.message.search': 'Search',
  'alert.message.create': 'Save',
  'alert.message.update': 'Update',
  'alert.message.delete': 'Delete',

  'form.add': 'Add',
  'form.delete': 'Delete',
  'form.submit': 'Submit',
  'form.save': 'Save',
  'form.cancel': 'Cancel',
  'form.login': 'Login',
  'form.logout': 'Logout',

  'value.unknown': 'Unknown',
  'value.active.true': 'Active',
  'value.active.false': 'Inactive',
  'value.private.true': 'Private',
  'value.private.false': 'Public',

  'registerPage.table.title': 'Sign up',
  'registerPage.table.username': 'Username',
  'registerPage.table.password': 'Password',
  'registerPage.table.firstname': 'Firstname',
  'registerPage.table.lastname': 'Lastname',
  'registerPage.link.signin': 'Signin',

  'registerPage.form.username': 'Email address',

  'loginPage.table.title': 'Login',
  'loginPage.table.username': 'Username',
  'loginPage.table.password': 'Password',
  'loginPage.link.signup': 'Sign up',
  'loginPage.snackbar.message.failed': 'Login failed',

  'opsPage.dialog.title': 'OPS',
  'opsPage.table.title': 'OPS',
  'opsPage.ops.subjects': 'Subjects',
  'opsPage.ops.grades': 'Grades',
  'opsPage.ops.objectives': 'Objectives',
  'opsPage.ops.transversals': 'Transversals',
  'opsPage.ops.contents': 'Contents',
  'opsPage.filter.onlyFavorites': 'Only favorites',
  'opsPage.filter.onlyGrades': 'Only grades',

  'opsPage.dialog.form.field.title': 'Title',
  'opsPage.dialog.form.field.type': 'Type',
  'opsPage.dialog.form.field.link': 'Link',
  'opsPage.dialog.form.field.private': 'Private',
  'opsPage.dialog.form.field.description': 'Description',

  'opsPage.form.date': 'Date',

  'usersPage.dialog.title': 'User details',
  'usersPage.table.title': 'Users',
  'usersPage.table.header.username': 'Username',
  'usersPage.table.header.description': 'Description',
  'usersPage.table.header.password': 'Password',
  'usersPage.table.header.password_confirmation': 'Confirm password',
  'usersPage.table.header.firstname': 'Firstname',
  'usersPage.table.header.lastname': 'Lastname',
  'usersPage.table.header.status': 'Status',
  'usersPage.table.header.lastloginAt': 'Last login',
  'usersPage.table.header.createdAt': 'Registered',
};
