import {
  addDays,
  endOfWeek,
  format,
  getWeek,
  startOfWeek,
  subDays,
} from 'date-fns';

export type Week = {
  startDate: Date;
  endDate: Date;
  weekNumber: number;
  label: string;
};

export const DAYFORMAT = 'dd/MM/yyyy';

export const getWeekLabel = (startDate: Date, endDate: Date) =>
  `${format(startDate, DAYFORMAT)} - ${format(endDate, DAYFORMAT)}`;

export const getThisWeek = (): Week => {
  const today = new Date();
  const startDate = startOfWeek(today, { weekStartsOn: 1 });
  const endDate = endOfWeek(today, { weekStartsOn: 1 });
  return {
    startDate,
    endDate,
    weekNumber: getWeek(startDate),
    label: getWeekLabel(startDate, endDate),
  };
};

export const getWeekByDay = (day: Date) => {
  const startDate = startOfWeek(day, { weekStartsOn: 1 });
  const endDate = endOfWeek(day, { weekStartsOn: 1 });
  return {
    startDate,
    endDate,
    weekNumber: getWeek(startDate),
    label: getWeekLabel(startDate, endDate),
  };
};

export const weekPlusWeeks = (amount: number, week: Week): Week => {
  const startDate = addDays(week.startDate, amount * 7);
  const endDate = addDays(week.endDate, amount * 7);
  return {
    startDate,
    endDate,
    weekNumber: getWeek(startDate),
    label: getWeekLabel(startDate, endDate),
  };
};

export const weekMinusWeeks = (amount: number, week: Week): Week => {
  const startDate = subDays(week.startDate, amount * 7);
  const endDate = subDays(week.endDate, amount * 7);
  return {
    startDate,
    endDate,
    weekNumber: getWeek(startDate),
    label: getWeekLabel(startDate, endDate),
  };
};

export const getMenuWeeks = (): Week[] =>
  [
    ...[...Array(25).keys()].map((i) =>
      weekMinusWeeks(i, weekMinusWeeks(1, getThisWeek()))
    ),
    ...[...Array(26).keys()].map((i) => weekPlusWeeks(i, getThisWeek())),
  ].sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
