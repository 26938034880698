import React from 'react';
import { BulletpointStatsText } from '../../routes/progress/BulletpointStatsText';
import { Stats } from '../../utils/stathelpers';
import { ProgressBar } from './ProgressBar';

interface Props {
  stats: Stats;
}

export const ProgressBarWithText = ({ stats }: Props) => (
  <>
    <BulletpointStatsText stats={stats} />
    <ProgressBar
      fill={(stats.completed / stats.all) * 100}
      secondaryFill={((stats.completed + stats.planned) / stats.all) * 100}
    />
  </>
);
