import { useRecoilState } from 'recoil';
import { Card, FormControl, Button } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { queryCache } from 'react-query';
import * as React from 'react';
import { addDays, format, subDays } from 'date-fns';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { DAYFORMAT, getWeekByDay } from '../../utils/weekhelpers';
import {
  selectedStateDate,
  selectedStateWeek,
} from '../../routes/my-plan/state';

const getDayLabel = (day: Date): string => `${format(day, DAYFORMAT)}`;

export const DaySelection = () => {
  const [week, setWeek] = useRecoilState(selectedStateWeek);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedStateDate);

  const updateDate = (date: Date) => {
    setSelectedDate(date);
    setWeek(getWeekByDay(date));
    queryCache.invalidateQueries('contentprogressapi');
  };
  return (
    <Card variant="outlined">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ArrowBack
          style={{ marginTop: '40px', marginRight: '4px' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            updateDate(subDays(selectedDate, 1));
          }}
          fontSize="small"
        />
        <FormControl
          variant="outlined"
          style={{ minWidth: '200px', maxWidth: '290px' }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Select day"
              value={selectedDate}
              labelFunc={(date: MaterialUiPickersDate) =>
                getDayLabel(date as Date)
              }
              onChange={(date) => {
                updateDate(date as Date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
        <ArrowForward
          style={{ marginTop: '40px', marginLeft: '4px' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            updateDate(addDays(selectedDate, 1));
          }}
          fontSize="small"
        />
      </div>
    </Card>
  );
};
