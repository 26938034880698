import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { parseSubjectList } from '../utils/utils';
import { PageContainer } from '../../../common/components/PageContainer';
import { SubjectList } from './ops/SubjectList';
// import { SubjectPage } from './ops/SubjectPage';
import { SubjectItem, Objective, TransversalItem } from './types/types';

const Container = styled.div`
  position: absolute;
  top: 200px;
  text-align: left;
`;

export const OPSPage = ({
  item,
  retrieveItem,
  selectSubject,
  selectedSubject,
  selectedGrade,
  toggleFavoriteSubject,
  favoriteSubjects,
  retrieveFavorites,
  opsFilter,
  updateOPSFilter,
  links,
  searchLink,
  selectedTransversalsAndContents,
  toggleTransversalOrContentSelected,
  retrieveSelectedTransversalsAndContents,
  submitEditLink,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  const intl = useIntl();

  useEffect(() => {
    if (!item) {
      retrieveItem('generic');
    }

    if (!favoriteSubjects) {
      retrieveFavorites();
    }

    if (!selectedTransversalsAndContents) {
      retrieveSelectedTransversalsAndContents();
    }
  }, [
    item,
    retrieveItem,
    favoriteSubjects,
    retrieveFavorites,
    selectedTransversalsAndContents,
    retrieveSelectedTransversalsAndContents,
  ]);

  const [objective, setObjective] = useState<Objective | null>(null);
  const [transversal, setTransversal] = useState<TransversalItem | null>(null);

  const subjects = item && parseSubjectList(item, opsFilter, favoriteSubjects);

  const reset = () => {
    setObjective(null);
    setTransversal(null);
  };

  const onClickSubject = (subject: SubjectItem, grade: number = 0) => {
    reset();
    selectSubject({
      subject,
      grade,
    });
  };

  const onClickFavoriteSubject = (subject: SubjectItem, grade: number = 0) => {
    toggleFavoriteSubject({
      subject,
      grade,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleShowOnlyFavorites = (event: any) => {
    updateOPSFilter({
      onlyFavorites: !opsFilter.onlyFavorites,
      grades: opsFilter.grades,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showOnlyGrades = (event: any) => {
    updateOPSFilter({
      onlyFavorites: opsFilter.onlyFavorites,
      grades: event.target.value,
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const grades = [1, 2, 3, 4, 5, 6];

  return (
    <>
      <Helmet>
        <meta name="description" content="" />
        <title>
          {intl.formatMessage({ id: 'application.title' })} -{' '}
          {intl.formatMessage({ id: 'opsPage.table.title' })}
        </title>
      </Helmet>
      <PageContainer>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                inputProps={{ 'aria-label': 'checkbox with small size' }}
                onClick={toggleShowOnlyFavorites}
                defaultValue={opsFilter.onlyFavorites}
              />
            }
            label={intl.formatMessage({ id: 'opsPage.filter.onlyFavorites' })}
          />
          <FormControl>
            <InputLabel id="grades-filter">
              {intl.formatMessage({ id: 'opsPage.filter.onlyGrades' })}
            </InputLabel>
            <Select
              labelId="grades-filter"
              id="grades"
              multiple
              defaultValue={opsFilter.grades || grades}
              onChange={showOnlyGrades}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {grades.map((grade) => (
                <MenuItem key={grade} value={grade}>
                  {grade}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={2}>
            {subjects && (
              <SubjectList
                subjects={Array.from(subjects.values())}
                favoriteSubjects={favoriteSubjects}
                onClickSubject={onClickSubject}
                onClickFavoriteSubject={onClickFavoriteSubject}
              />
            )}
          </Grid>
          <Grid item xs={10}>
            <Container>
              {/* <SubjectPage
                subject={selectedSubject}
                grade={selectedGrade}
                onClickSubject={onClickSubject}
                transversal={transversal}
                objective={objective}
                setObjective={setObjective}
                setTransversal={setTransversal}
                searchLink={searchLink}
                links={links}
                selectedTransversalsAndContents={
                  selectedTransversalsAndContents
                }
                toggleTransversalOrContentSelected={
                  toggleTransversalOrContentSelected
                }
                submitEditLink={submitEditLink}
              /> */}
            </Container>
          </Grid>
        </Grid>
      </PageContainer>
    </>
  );
};
