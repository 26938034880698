import { all } from 'redux-saga/effects';
import { spawnOrchestrations as AppSaga } from '../App/orchestrations';
import { spawnOrchestrations as UsersSaga } from '../routes/users/orchestrations';
import { spawnOrchestrations as LoginSaga } from '../routes/login/orchestrations';
import { spawnOrchestrations as RegisterSaga } from '../routes/register/orchestrations';
import { spawnOrchestrations as OPSSaga } from '../routes/ops/orchestrations';

export default function* rootSaga() {
  yield all([
    ...AppSaga,
    ...UsersSaga,
    ...LoginSaga,
    ...RegisterSaga,
    ...OPSSaga,
  ]);
}
