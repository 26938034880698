import * as React from 'react';
import { useQuery } from 'react-query';
import { getGroups } from '../../api/groupapi';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { Group } from './Group';
import { GroupSelection } from './GroupSelection';

interface Props {
  open: boolean;
}

export const GroupSelectionWrapper = ({ open }: Props) => {
  const groupsResponse = useQuery({
    queryKey: ['groupapi'],
    queryFn: getGroups,
    config: QUERY_CONFIG,
  });
  if (!groupsResponse.data) {
    return <div>Loading</div>;
  }
  // @ts-ignore
  const groups: Group[] = groupsResponse?.data?.length
    ? groupsResponse.data
    : [];
  return <GroupSelection groups={groups} open={open} />;
};
