import { Box, Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as contentProgressApi from '../../api/contentprogressapi';
import { ProgressBarWithText } from '../../features/progress/ProgressBarWithText';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { getSubjectStats } from '../../utils/stathelpers';
import { parseNumber } from '../../utils/string';
import { selectedSubject } from '../my-plan/state';
import { ContentProgressObjectiveStats } from '../my-plan/types';
import { SubjectItem } from '../ops/components/types/types';

type Props = {
  groupId: string | undefined;
  subject: SubjectItem;
  grade: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchContentProgressStats = (key: string, obj: any) =>
  contentProgressApi.getStats(obj.groupId, obj.gradeId, obj.subjectId);

export const SubjectProgress = ({ groupId, subject, grade }: Props) => {
  const history = useHistory();
  const [selectedSubj, setSelectedSubj] = useRecoilState(selectedSubject);
  const contentProgressStats = useQuery({
    queryKey: [
      'contentprogressapistats',
      { gradeId: grade, subjectId: subject.code, groupId },
    ],
    queryFn: fetchContentProgressStats,
    config: { ...QUERY_CONFIG, enabled: !!groupId },
  });

  if (!contentProgressStats.data) {
    return <div>Loading</div>;
  }

  // eslint-disable-next-line max-len
  const contentProgressObjectiveStats: ContentProgressObjectiveStats[] =
    contentProgressStats?.data?.length ? contentProgressStats.data : [];

  const gradeItem = subject.grades?.find(
    (_grade) => _grade.grade.toString() === grade
  );

  if (!gradeItem) {
    return <div />;
  }

  const stats = getSubjectStats(
    gradeItem.objectives!!,
    contentProgressObjectiveStats
  );

  const clickBox = () => {
    setSelectedSubj({
      subjectId: subject.code,
      gradeId: parseNumber(grade)!!,
    });
    history.push('/my-plan/content');
  };

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Box onClick={clickBox}>
        <Typography variant="body1">
          {subject.code} {grade}
        </Typography>
        <ProgressBarWithText stats={stats} />
      </Box>
    </Grid>
  );
};
